import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
// import { WSSAppConstant, ContentData } from '../../config/wss-app-constants';
//import { ContentService } from "../../service/content.service";
// import { ContentService as OldContentService } from "../../service/content.service";
import { Observable } from "rxjs";
import { NavigationWidgetItem } from '../../model/navigationhome-widget/navigationhome';
import { ContentService } from '@backbase/universal-ang/content';
import { RouterService } from "@backbase/foundation-ang/core";
import { MessageService } from 'libs/service/message.service';
import { DatastoreService } from '../../service/datastore.service';
import { DOCUMENT } from '@angular/common';
import { getPortalName } from '../../common/util/util';
import { appConfig } from '@wss/config/wss-app-constants';
import { EndOfContractService } from '@wss/end-of-contract-widget/src/services/end-of-contract.service';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { DataService } from '@wss/complaints-widget/src/services/data.service';

@Component({
  selector: 'bb-navigation-widget',
  templateUrl: 'navigation-widget.component.html',
  providers: [ContentService]

})
export class NavigationWidgetComponent implements OnInit {
  portalName: any;

  navItemList: any = [
    {
      name: 'My account',
      isActive: false
    },
    {
      name: 'Settlement',
      isActive: false
    },
    {
      name: 'Payments',
      isActive: false
    },
    {
      name: 'Transactions',
      isActive: false
    },
    {
      name: 'Help centre',
      isActive: false
    },
    {
      name: 'End of contract',
      isActive: false
    }
  ];

  isMoreActive: boolean = false;
  accountDetails: any = {};
  showEoc: boolean = false;
  contractEndingWithinYear: boolean = false;
  eocNavigationLink: string = 'eoc-options';
  isPcpBalloonRefinanceCustomer: boolean = false;
  isAccountArchived: boolean = false;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.scrollY > 0) {
      this.renderer.addClass(document.body, 'nav-bar-top');
    } else {
      this.renderer.removeClass(document.body, 'nav-bar-top');
    }
  }

  navigationWidgetItem$: Observable<NavigationWidgetItem | undefined> = this.bbContentService.getContent<NavigationWidgetItem>('navigationWidgetItem');

  constructor(
    private router: RouterService,
    private readonly bbContentService: ContentService,
    private messageService: MessageService,
    private datastoreService: DatastoreService,
    private renderer: Renderer2,
    private eocService: EndOfContractService,
    public changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private complaintsFormDataService: DataService
  ) { }

  ngOnInit(): void {
    this.datastoreService.sessionInvalid();
    this.handleActiveNav();
    this.portalName = getPortalName();
    this.isAccountArchived = this.datastoreService.getIsAccountArchived();

    this.datastoreService.accountSummaryData$.subscribe((res: any) => {
      this.accountDetails = res;
      this.checkEoc();
    });
  }

  selectedNav(navItem: any, url: string, reload: boolean) {
    const complaintsFormData = this.complaintsFormDataService.getComplaintsFormData();
    if (Object.keys(complaintsFormData).length === 0) {
      this.navItemList.forEach((item: any) => {
        if (item.name === navItem.name) {
          item.isActive = true;
        }
        else {
          item.isActive = false;
        }
      });
      this.isMoreActive = false;
    }
    if (reload) {
      this.router.navigate([url]);
    }
  }

  showMore(navItem?: any) {
    this.navItemList.forEach((item: any) => {
      item.isActive = false;
      if (item.name === navItem?.name) {
        item.isActive = true;
      }
      else {
        item.isActive = false;
      }
    });
    this.isMoreActive = !this.isMoreActive;
  }

  handleActiveNav() {
    this.messageService.getActiveNav().subscribe(activeNav => {
      if (activeNav === "My account") {
        this.selectedNav({ name: activeNav }, "myaccount", true);
      }
      else if (activeNav === "Transactions") {
        this.selectedNav({ name: activeNav }, "transactions", true);
      }
      else if (activeNav === "Transactions Nav Selection") {
        this.selectedNav({ name: "Transactions" }, "transactions", true);
      }
      else if (activeNav === "Payments") {
        this.selectedNav({ name: activeNav }, "payments", true);
      }
      else if (activeNav === "Settlement") {
        this.selectedNav({ name: activeNav }, "payments/payment-settlement", true);
      }
      else if (activeNav === "Payments Nav Selection") {
        this.selectedNav({ name: "Payments" }, "payments", false);
      }
      else if (activeNav === "Settlement Nav Selection") {
        this.selectedNav({ name: "Settlement" }, "payments/payment-settlement", false);
      }
      else if (activeNav === "Help centre") {
        this.selectedNav({ name: activeNav }, "help-centre", true);
      }
      else if (activeNav === "Help centre internal") {
        this.selectedNav({ name: 'Help centre' }, "help-centre", false);
      }
      else if (activeNav === "End of contract") {
        this.selectedNav({ name: activeNav }, "eoc-options/" + this.datastoreService.getEocRoute(), true);
        this.datastoreService.setEocRoute("");
      }
      else {
        this.selectedNav({ name: activeNav }, "", false);
      }
      this.checkAccountDetails(activeNav);
    });
  }

  helpCentreClick() {
    this.router.navigate(['help-centre']);
  }

  checkEoc() {
    this.contractEndingWithinYear = this.accountDetails.final_payment_date ? this.eocService.isContractEndingWithinYear(this.accountDetails.final_payment_date) : false;
    this.isPcpBalloonRefinanceCustomer = this.eocService.isPCPBalloonRefinanceCustomer();
    if (this.contractEndingWithinYear && !appConfig.fileTags.includes(this.accountDetails?.file_tag) && appConfig.goodsCode.includes(this.accountDetails?.goods_code)) {
      this.showEoc = true;

      if ((this.accountDetails.scheme_code === appConfig.pcpCustomer || this.accountDetails.scheme_code === appConfig.pcpCustomer.toString())
        && !this.isPcpBalloonRefinanceCustomer && this.accountDetails?.scheme_code != '01' && this.accountDetails?.scheme_code != appConfig.hpCustomer) {
        this.eocNavigationLink = 'eoc-options'
      } else if (this.accountDetails?.scheme_code === '01' || this.accountDetails?.scheme_code === appConfig.hpCustomer || this.isPcpBalloonRefinanceCustomer) {
        this.eocNavigationLink = 'eoc-options/eoc-hp'
      }
    } else {
      this.showEoc = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  checkAccountDetails(activeNav: string) {
    if (activeNav != "My account" && !this.accountDetails?.account_number) {
      this.eocService.refreshAccountsData();
    }
  }
}
