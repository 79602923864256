<div class="container-column confirm-billing-address" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="directDebitPageName">
                {{paymentsWidgetItem.confirmBillingAddress.pageTitle}}
            </span>
        </div>
    </div>
    <div class="reg-row plr-10pt" role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="para-inline" test_id="headerText">
        <bb-content-html [html]="paymentsWidgetItem.confirmBillingAddress.headerText">
        </bb-content-html>
    </div>
    <div class="para-inline mt-15px mb-5px" test_id="billingAddress">
        <div class="caps-title" *ngIf="personalDetails?.fullname" test_id="commonheaderProfileContent">
            <strong>{{ personalDetails?.fullname }}</strong>
        </div>
        <div class="caps-title">
            <span
                *ngIf="(customerAddress?.flat_number != null && customerAddress?.flat_number &&  customerAddress?.flat_number?.length > 0 && customerAddress?.flat_number != ' ')">
                {{customerAddress.flat_number}}</span>
            <span
                *ngIf="(customerAddress?.house_name != null && customerAddress.house_name &&  customerAddress?.house_name.length > 0 && customerAddress?.house_name != ' ')">
                {{customerAddress.house_name}},</span>
        </div>
        <div class="caps-title"
            *ngIf="(customerAddress?.street_name != null && customerAddress?.street_name &&  customerAddress?.street_name?.length > 0 && customerAddress?.street_name != '  ')">
            {{customerAddress.street_name}},</div>
        <div>
            <span class="caps-title"
                *ngIf="(customerAddress?.post_town != null && customerAddress?.post_town &&  customerAddress?.post_town?.length > 0 && customerAddress?.post_town != ' ')">
                {{customerAddress.post_town}},
            </span>
            <span class="caps-title" *ngIf="customerAddress?.county && !addressChanged">{{customerAddress.county}}, </span>
            <span *ngIf="customerAddress?.post_code?.length > 0">{{customerAddress.post_code}}</span>
        </div>
    </div>
    <div class="container-row mt-15px" *ngIf="addressChanged">
        <span class="wss-additonal-info-icon additonal-info-icon-style"></span>
        <span class="pull-item-right note mb-5px para-inline">
            <bb-content-html [html]="paymentsWidgetItem.confirmBillingAddress.addressChangeNotification"></bb-content-html>
        </span>
    </div>
    <div class="container-row back-btn">
        <button role="button" tabindex="0" class="wss-button" (click)="proceedNextClick()" id="yes-and-next-btn"
            title="{{paymentsWidgetItem.confirmBillingAddress.yesAndNextButton}}">
            {{paymentsWidgetItem.confirmBillingAddress.yesAndNextButton}}
        </button>
    </div>
    <div class="container-row back-btn" *ngIf="!addressChanged">
        <button role="button" tabindex="0" class="wss-button-secondary no-margin" (click)="changeAddressClick()"
            id="change-customerAddress-btn" title="{{paymentsWidgetItem.confirmBillingAddress.changeAddressButton}}">
            {{paymentsWidgetItem.confirmBillingAddress.changeAddressButton}}
        </button>
    </div>
    <div class="mobile-center mt-12px mb-20px" *ngIf="!addressChanged">
        <bb-content-html [html]="paymentsWidgetItem.confirmBillingAddress.backLink"></bb-content-html>
    </div>
    <div class="mobile-center mb-20px" *ngIf="addressChanged">
        <bb-content-html [html]="paymentsWidgetItem.confirmBillingAddress.backLinkForChangedAddress"></bb-content-html>
    </div>
</div>