import { ServiceException, ErrorImageType } from '@wss/model/service-exception';
import { SendSMS } from '@wss/model/send-sms';
import { PortalContentMock } from '@backbase/foundation-ang/web-sdk';
import { BrandContactDetailsItem } from '@wss/model/content/common/brand-contant-details';

export class PaymentsWidgetItem {
    paymentMenu = new PaymentMenuItem();
    paymentDebitCard = new paymentDebitCardItem();
    paymentResult = new paymentResultItem();
    overpayment = new overpaymentItem();
    paymentSettlement = new paymentSettlementItem();
    overpaymentOptions = new overpaymentOptionsItem();
    changePayment = new changePaymentItem();
    confirmChangePayment = new confirmChangePaymentItem();
    changePaymentResult = new ChangePaymentResultItem();
    directDebit = new directDebitItem();
    addDirectDebit = new addDirectDebitItem();
    directDebitResult = new directDebitResultItem();
    brandContactDetails = new BrandContactDetailsItem();
    confirmBillingAddress = new ConfirmBillingAddress();
    enterBillingAddress = new EnterBillingAddress();
    enterCardDetails = new EnterCardDetails();
}

export class PaymentMenuItem {
    pageName: string = "";
    pageText: string = "";
    option1: string = "";
    option2: string = "";
    option3: string = "";
    option4: string = "";
    option5: string = "";
    buttonText: string = "";
    needHelpLink: string = "";
    outOfWorkHoursException: ServiceException = new ServiceException();
    pageSelectionName: string = "";
    regularPaymentRadioText: string = "";
    overpaymentRadioText: string = "";
    pageSelectionHeaderText: string = "";
    proceedButtonText: string = "";
    explainThisText: string = "";
    regularPaymentInfo: string = "";
    overPaymentInfo: string = "";
}
export class paymentDebitCardItem {
    paymentPageName: string = "";
    settlementPageName: string = "";
    overpaymentPageName: string = "";
    paymentPayableAmountText: string = "";
    settlementPayableAmountText: string = "";
    overpaymentPayableAmountText: string = "";
    paymentErrorMessage: string = "";
    tryAgainText: string = "";
    clearButtonText: string = "";
    amountFormatValidationMessage: string = "";
    amountExceedValidationMessage: string = "";
    noAmountValidationMessage: string = "";
    settlementOptionalMessage: string = "";
    settlementChooseNewAmountLink: string = "";
    paymentMaxTransactionLimitMessage: string = "";
    payWithDebitCardButtonText: string = "";
    needHelpLinkText: string = "";
    otherWayToPaybuttonText: string = "";
    paymentProcessModalContentInfo1Text: string = "";
    paymentProcessModalContentInfo2Text: string = "";
    settlementPaymenetCardModalHeaderText: string = "";
    settlementPaymentCardModalContentText: string = "";
    settlementAgreementLinkText: string = "";
    settlementPaymentCardYesFooterBttonText: string = "";
    lowerArrearsAmountWarningMessage: string = "";
    higherArrearsAmountWarningMessage: string = "";
    acceptVisaAndMastercardDebitCardText: string = "";
    stepperLabelOne: string = "";
    stepperLabelTwo: string = "";
    stepperLabelThree: string = "";
}

export class paymentResultItem {
    paymentResultSuccessMessageText: string = "";
    clearButtonText: string = "";
    paymentSuccessConfirmationText: string = "";
    congratulationText: string = "";
    settlementPaymentInfoText: string = "";
    settlementPaymentDurationInfoText: string = "";
    payableAmountLableText: string = "";
    paymentReferenceLabelText: string = "";
    paymentMailConfirmationInfoText: string = "";
    settlementPaymentConfirmationMailInfoText: string = "";
    backMyAccountButtonText: string = "";
    smsLinkText: string = "";
    smsLinkTextForSettlement: string = "";
    makePaymentLableText: string = "";
    makePaymentHeaderText: string = "";
    paymentTransactionLimitWarningInfoText: string = "";
    nextPaymentDurationInfoText: string = "";
    goMyAccountButtonInfoText: string = "";
    paymentUnsuccessLableText: string = "";
    paymentOnlineInfoText: string = "";
    paymentBankDeclinedInfoText: string = "";
    paymentFailureInfoText: string = "";
    paymentOptionLableText: string = "";
    paymentOptionInfoText: string = "";
    otherWaytoPayLinkText: string = "";
    needHelpLinkText: string = "";
    nextStepLableText: string = "";
    helpSupportTextInfoText: string = "";
    callUsLinkText: string = "";
    paymentWaysInfoText: string = "";
    paymentCancelInfoText: string = "";
    paymentCancelledInfoText: string = "";
    invalidCardPaymentInfoText: string = "";
    invalidCardPaymentButtonText: string = "";
    invalidCardPaymentNeedHelpText: string = "";
    makeAPaymentButtonText: string = "";
    overpaymentPaymentInfoText: string = "";
    onePaymentLimitationInfoText: string = "";
    pleaseComeBackInfoText: string = "";
    backToHomeButtonText: string = "";
    paymentSendSms: SendSMS = new SendSMS();
    paymentLockoutSuspendedEarlierLabelText: string = "";
    paymentLockoutSuspendedEarlierInfoText: string = "";
    paymentLockoutSuspendedEarlierPaymentFromText: string = "";
    paymentLockoutSuspendedEarlierHelpSupportText: string = "";
    paymentLockoutBlockedEarlierLabelText: string = "";
    paymentLockoutBlockedEarlierInfoText: string = "";
    paymentLockoutSuspendedNowLabelText: string = "";
    paymentLockoutSuspendedNowInfoText: string = "";
    paymentLockoutBlockedNowLabelText: string = "";
    paymentLockoutBlockedNowInfoText: string = "";
    paymentGenericErrorLabelText: string = "";
    paymentGenericErrorInfoText: string = "";
    browserBackHeaderText: string = "";
    browserBackStayButton: string = "";
    browserBackProceedButton: string = "";
}
export class paymentSettlementItem {
    settleAgreementPageName: string = "";;
    paybleAmountInfoText: string = "";
    settleAgreementButtonText: string = "";
    settleIncludeFeeInfoText: string = "";
    settleIncludeFeePCPInfoText: string = "";
    callUsLabelText: string = "";
    settlementAmountInfoText: string = "";
    importantSettlementInfoLabelText: string = "";
    settlementAmountExpireInfoText: string = "";
    settlementAmountDueDateInfoText: string = "";
    newSettlementAmountInfoText: string = "";
    newSettlementbuttonText: string = "";
    nextPaymentLabelText: string = "";
    settlementAmountLabelText: string = "";
    settlementDateDueLabelText: string = "";
    settlementfurtherHelpInfoText: string = "";
    otherWaytoPayLinkText: string = "";
    settlementWarningLabelText: string = "";
    settlementWarningInfoText: string = "";
    getInTouchText: string = "";
    backToAccountButtonText: string = "";
    settlementUntimeWarningLabelText: string = "";
    settlementUntimeWarningInfoText: string = "";
    settlementHelpAndSupportText: string = "";
    settlementBackToHomeButtonText: string = "";
    twoSettlementsMadeWithin48hoursWarningLabelText: string = "";
    twoSettlementsMadeWithin48hoursWarningInfoText: string = "";
    settlementEmailTitle: string = "";
    settlementEmailInfo: string = "";
    sendSettlementEmailLink: string = "";
    sendSettlementEmailConfirmationButton: string = "";
    settlementGoBackButtonText: string = "";
    postSettlementEmailMessage: string = "";
    postSettlement3rdPartyEmailMessage: string = "";
    closeThisWindowButton: string = "";
    messageToSendEmailAgain: string = "";
    radioLabelYes: string = "";
    radioLabelNo: string = "";
    emailAddressLabel: string = "";
    emailAddressPlaceholder: string = "";
    emailRequiredErrorMsg: string = "";
    emailInvalidErrorMsg: string = "";
    cEmailAddressLabel: string = "";
    cEmailAddressPlaceholder: string = "";
    cEmailRequiredErrorMsg: string = "";
    cEmailMustMatchErrorMsg: string = "";
    withdrawalPeriodWithIn20Days: string = "";
}

export class overpaymentItem {
    overPaymentPageName: string = "";
    overpaymentTimeInfoText: string = "";
    overpaymentHPSelectionInfoText: string = "";
    overpaymentPCPSelectionInfoText: string = "";
    overpaymentSelectionOption1Text: string = "";
    overpaymentSelectionOption2Text: string = "";
    overpaymentFurthertInfoText: string = "";
    overpaymentPaybleAmountInfoText: string = "";
    overpaymentOptionViewButtonText: string = "";
    newMonthlyPaymentButtonText: string = "";
    settleRequestLinkText: string = "";
    needHelpLinkText: string = "";
    settlementAccountInfo: string = "";
}

export class overpaymentOptionsItem {
    overPaymentOptionPageName: string = "";
    overpaymentOptionSelectionText: string = "";
    overpaymentOption1LabelInfoText: string = "";
    newMonthlyPaymentInfoText: string = "";
    overpaymentOptionPartDayInfoText: string = "";
    currentPaymentAmountLabelAndValueText: string = "";
    newPaymentAmountLabelAndValueText: string = "";
    overpaymentHPOption1PaymentBeforeDataInfoText: string = "";
    overpaymentHPOption1PaymentBeforeDataInfoTextPartTwo: string = "";
    overpaymentHPOption1PaymentAfterDataInfoText: string = "";
    overpayLearnInfoLinkText: string = "";
    permanentAmountChangeInfoText: string = "";
    lowerPaymentButtonText: string = "";
    newAmountSelectionLinkText: string = "";
    needHelpLinkText: string = "";
    overpaymentOption2LabelInfoText: string = "";
    agreementNewEndDateInfoText: string = "";
    agreementLengthInfoText: string = "";
    endDateWithoutOverpaymentLabelAndValueText: string = "";
    endDateWithOverpaymentLabelAndValueText: string = "";
    nextEndDateWithoutOverpaymentLabelAndValueText: string = "";
    overpaymentHPOption2PaymentInfoText: string = "";
    reduceAgreementButtonText: string = "";
    lowerMonthlyLabelText: string = "";
    newMonthyPaymentInfoText: string = "";
    overpaymentPCPOption1PaymentBeforeInfoText: string = "";
    overpaymentPCPOption1PaymentBeforeInfoTextPartTwo: string = "";
    overpaymentPCPOption1PaymentAfterInfoText: string = "";
    overpaymentLPOptionTxt: string = "";
    overPaymentExceedWarningInfoText: string = "";
    overpaymentSupportInfoText: string = "";
    morePaymentInfoText: string = "";
    getInTouchLinkText: string = "";
    overpaymentBackToHomeButtonText: string = "";
    accountIneligibleFailureLabelText: string = "";
    accountIneligibleFailureInfoText: string = "";
    overPaymentDueDayWarningLabelText: string = "";
    overPaymentDueDayWarningInfoText: string = "";
    eligibleSettleAgreementLabelText: string = "";
    eligibleSettleAgreementInfoText: string = "";
    settleMyAgreementButtonText: string = "";
    overPaymentUntimeWarningLabelText: string = "";
    overPaymentUntimeWarningInfoText: string = "";
    overPaymentHelpAndSupportText: string = "";
    overPaymentGenericErrorLabelText: string = "";
    overPaymentGenericErrorInfoText: string = "";
    backToPaymentsButtonText: string = "";
    overPaymentAccountAmendedErrorLabelText: string = "";
    overPaymentAccountAmendedErrorInfoText: string = "";
    overPaymentPesQuoteHelpAndSupportText: string = "";
}
export class changePaymentItem {
    changePaymentPageName: string = "";
    changePaymentHPNoteText: string = "";
    changePaymentPCPNoteText: string = "";
    newPaymentDateSelectionText: string = "";
    selectDateButtonText: string = "";
    paymentDateChangeInfoText: string = "";
    selectDateLabelText: string = "";
    dateSelectionInfoText: string = "";
    cancelButtonText: string = "";
    selectButtonText: string = "";
    selectADayPlaceHolderText: string = "";
    nextPayment: nextPaymentItem = new nextPaymentItem();
    hpLimitException: ServiceException = new ServiceException();
    pcpLimitException: ServiceException = new ServiceException();
    ddCallingException: ServiceException = new ServiceException();
    sameDayModificationException: ServiceException = new ServiceException();
}
export class nextPaymentItem {
    title: string = "";
    amount: string = "";
    ddue: string = "";
    cpd: string = "";
    arrearException: ServiceException = new ServiceException();
    ddCallingException: ServiceException = new ServiceException();
    outOfWorkHoursException: ServiceException = new ServiceException();
}

export class confirmChangePaymentItem {
    changePaymentDatePageName: string = "";
    nextPaymentDateInfoText: string = "";
    nextPaymentLabelText: string = "";
    dueDateLabelText: string = "";
    extraAmountLabelText: string = "";
    paybleAmountInfoText: string = "";
    confirmNewPayDayButtonText: string = "";
    selectNewDayLinkText: string = "";
}

export class ChangePaymentResultItem {
    changePaymentDatePageName: string = "";
    paymentSuccessUpdateInfoText: string = "";
    smsConfirmLinkText: string = "";
    backToHomeButtonText: string = "";
    changePaymentSendSms: SendSMS = new SendSMS();
    smsSuccessBanner: BannerMessage = new BannerMessage();
    smsFailureBanner: BannerMessage = new BannerMessage();
}
export class directDebitItem {
    directDebitPageName: string = "";
    activeDirectDebitAgreementText: string = "";
    changeBankDetailsButtonText: string = "";
    inactiveDirectDebitAgreementText: string = "";
    addNewBankDetailsButtonText: string = "";
    activeDirectDebitFAQText: string = "";
    inactiveDirectDebitFAQText: string = "";
    nextPaymentContent: nextPaymentItem = new nextPaymentItem();
    errorBannerWithIn3Days: BannerMessage = new BannerMessage();
    errorBannerAlreadyModified: BannerMessage = new BannerMessage();
}

export class addDirectDebitItem {
    addDirectDebitPageName: string = "";
    directDebitFAQInfoText: string = "";
    directDebitFAQInfoLinkText: string = "";

    activeDirectDebitLabelText: string = "";
    inactiveDirectDebitLabelText: string = "";

    accountNameLabelText: string = "";
    accountNamePlaceholderText: string = "";
    accountNameErrorMessageText: string = "";

    accountNumberLabelText: string = "";
    accountNumberPlaceholderText: string = "";
    accountNumberErrorMessageText: string = "";

    sortcodeLabelText: string = "";

    bankDetailsFoundInfoText: string = "";
    bankDetailsInvalidWarningInfoText: string = "";
    bankDetailsInvalidAdditionalInfoText: string = ""

    directDebitQuestionsFAQText: string = "";
    directDebitQuestionsFAQLinkText: string = "";

    confirmFollowingText: string = "";
    confirmAccountHolderText: string = "";
    confirmSignatoryText: string = "";
    confirmAuthorisedText: string = "";

    directDebitConfirmButtonText: string = "";
    backToPaymentButtonText: string = "";

    sameDetailsHeadingText: string = "";
    sameDetailsBodyText: string = "";
    sameDetailsChangeDirectDebitButtonText: string = "";
    sameDetailsBackToDirectDebitText: string = "";
}

export class directDebitResultItem {
    directDebitResultPageName: string = "";
    directDebitResultUpdatedText: string = "";
    confirmEmailShortlyMessageText: string = "";
    directDebitFAQText: string = "";
    directDebitGuaranteeHeadingText: string = "";
    directDebitGuaranteeBodyText: string = "";
    messageBeforeNewPaymentDueDayText: string = "";
    messageBetweenNewAndNextPaymentDueText: string = "";
    requestSMSConfirmationMessageText: string = "";
    directDebitSendSms: SendSMS = new SendSMS();
    nextPaymentContent: nextPaymentItem = new nextPaymentItem();
    successBannerWithIn8Days: BannerMessage = new BannerMessage();
    successBannerOutside8Days: BannerMessage = new BannerMessage();
    smsSuccessBanner: BannerMessage = new BannerMessage();
    smsFailureBanner: BannerMessage = new BannerMessage();
}
export class BannerMessage {
    titleTextBeforeData: string = '';
    titleTextAfterData: string = ``;
    subtitleTextBeforeData: string = '';
    subtitleTextAfterData: string = ``;
}

export class ConfirmBillingAddress {
    pageTitle: string = "";
    headerText: string = "";
    yesAndNextButton: string = "";
    changeAddressButton: string = "";
    backLink: string = "";
    backLinkForChangedAddress: string = "";
    addressChangeNotification: string = "";
    invalidPostCodeErrorMessage: string = "";
    specialCharacterErrorMessage: string = "";
}

export class EnterBillingAddress {
    pageTitle: string = "";
    headerText: string = "";
    firstName: string = "";
    lastName: string = "";
    firstLineOfAddress: string = "";
    secondLineOfAddress: string = "";
    townOrCity: string = "";
    postCode: string = "";
    nextButtonText: string = "";
    backLink: string = "";
    requiredFieldError: string = "";
}

export class EnterCardDetails {
    pageTitle: string = "";
    todayYouArePaying: string = "";
    debitCardNumber: string = "";
    enterCardNumberHere: string = "";
    expiryDate: string = "";
    securityCode: string = "";
    securityCodeInfo: string = "";
    payButtonText: string = "";
    backLink: string = "";
}

export const payemntWidgetContentRef = PortalContentMock.addStructuredContent<PaymentsWidgetItem>('paymentsWidgetItem', {
    paymentMenu: {
        pageName: "Make a Payment",
        pageText: "I would like to:",
        option1: "Make a payment",
        option2: "Setup or change my Direct Debit",
        option3: "Settle my agreement",
        option4: "Make an overpayment",
        option5: "Help to manage your payments",
        buttonText: "Other ways to pay",
        needHelpLink: "Need help?",
        outOfWorkHoursException: {
            imageClass: "service-timeout",
            errorInfoText: `We're sorry, we can't currently add or change your bank details.

            Please try again between the hours of 8am and 10pm.
            
            For further help and support, please see our Direct Debit FAQs.`,
            buttonText: 'Back to Make a payment',
            componentUri: '#/payments/payment-menu',
            cancelLink: 'Cancel',
            featurePage: '',
            errorImageType: ErrorImageType["Timeout Error"],
            brandContactDetails: {} as any
        },
        pageSelectionName: "",
        regularPaymentRadioText: "",
        overpaymentRadioText: "",
        pageSelectionHeaderText: "",
        proceedButtonText: "Proceed",
        explainThisText: "Explain this",
        regularPaymentInfo: "",
        overPaymentInfo: ""
    },
    paymentDebitCard: {} as any,
    paymentResult: {} as any,
    overpayment: {} as any,
    paymentSettlement: {} as any,
    overpaymentOptions: {} as any,
    changePayment: {
        changePaymentPageName: "Change payment day",
        changePaymentHPNoteText: "You can change your payment day once in a 12 month period.",
        changePaymentPCPNoteText: "You can change your payment day once during the length of your agreement.",
        newPaymentDateSelectionText: "Select a new payment day:",
        selectDateButtonText: "Select this day",
        paymentDateChangeInfoText: "Changing your payment day may mean you pay an extra amount. We will show you these details on the next screen. Find out more in our Change payment day FAQs.",
        selectDateLabelText: "Select a day",
        dateSelectionInfoText: `Some months your selected day will not be available (for example, there are only 30 days in April). When this happens we will take your payment on the nearest working day of that month.`,
        cancelButtonText: "Cancel",
        selectButtonText: "Select",
        selectADayPlaceHolderText: "Select a day",
        nextPayment: new nextPaymentItem(),
        hpLimitException: new ServiceException(),
        pcpLimitException: new ServiceException(),
        ddCallingException: new ServiceException(),
        sameDayModificationException: new ServiceException()
    },
    confirmChangePayment: {} as any,
    changePaymentResult: {} as any,
    directDebit: {
        directDebitPageName: "Set up or change my Direct Debit",
        activeDirectDebitAgreementText: `You have a Direct Debit set up on this agreement`,
        changeBankDetailsButtonText: "Change my bank details",
        inactiveDirectDebitAgreementText: "You don’t have a Direct Debit set up on this agreement",
        addNewBankDetailsButtonText: "Set up a Direct Debit",
        activeDirectDebitFAQText: "For further help and support, please see our Direct Debit FAQs.",
        inactiveDirectDebitFAQText: `Have a question? Please read our ​
        Direct Debit FAQs.`,
        nextPaymentContent: {
            title: 'Your next payment:',
            amount: 'Amount',
            ddue: 'Date due',
            cpd: 'Change payment date',
            arrearException: new ServiceException(),
            ddCallingException: new ServiceException(),
            outOfWorkHoursException: new ServiceException()
        },
        errorBannerWithIn3Days: {
            titleTextBeforeData: `We're sorry, we can't currently change your bank details today.`,
            titleTextAfterData: ``,
            subtitleTextBeforeData: `This is because your next payment is due in the next 3 working days. Please try again after your next payment has been made.`,
            subtitleTextAfterData: ''
        },
        errorBannerAlreadyModified: {
            titleTextBeforeData: `We're sorry, we can't currently change your bank details today.`,
            titleTextAfterData: '',
            subtitleTextBeforeData: `You have already added or changed your bank details today. Please try again after one working day.`,
            subtitleTextAfterData: ''
        }
    },
    addDirectDebit: {} as any,
    directDebitResult: {
        directDebitResultPageName: 'Set up or change my Direct Debit',
        directDebitResultUpdatedText: '',
        confirmEmailShortlyMessageText: '',
        directDebitFAQText: '',
        directDebitGuaranteeHeadingText: '',
        directDebitGuaranteeBodyText: '',
        messageBeforeNewPaymentDueDayText: '',
        messageBetweenNewAndNextPaymentDueText: '',
        requestSMSConfirmationMessageText: 'Send free text to confirm change',
        directDebitSendSms: new SendSMS(),
        nextPaymentContent: {
            title: 'Your next payment:',
            amount: 'Amount',
            ddue: 'Date due',
            cpd: 'Change payment date',
            arrearException: new ServiceException(),
            ddCallingException: new ServiceException(),
            outOfWorkHoursException: new ServiceException()
        },
        successBannerWithIn8Days: {
            titleTextBeforeData: `Your next payment will be taken on`,
            titleTextAfterData: ``,
            subtitleTextBeforeData: `Direct Debit payments after this will be taken on the`,
            subtitleTextAfterData: 'of each month.'
        },
        successBannerOutside8Days: {
            titleTextBeforeData: `Direct Debit details updated`,
            titleTextAfterData: ``,
            subtitleTextBeforeData: `Updated details effective from next working day`,
            subtitleTextAfterData: ''
        },
        smsSuccessBanner: {
            titleTextBeforeData: `Thank you. We've sent you a text confirm that your Direct Debit details have been updated.`,
            titleTextAfterData: ``,
            subtitleTextBeforeData: ``,
            subtitleTextAfterData: ''
        },
        smsFailureBanner: {
            titleTextBeforeData: `Sorry. We couldn't send you a text confirm that your Direct Debit details have been updated.`,
            titleTextAfterData: ``,
            subtitleTextBeforeData: ``,
            subtitleTextAfterData: ''
        }
    },
    brandContactDetails: {
        brandWiseAddress: "",
        brandWiseContactNumber: "",
        brandWiseContactEmail: "",
        brandWiseBankDetails: "",
        brandWiseCfaNumber: "",
        brandWiseFreeTRSNumber: "",
        portalName: "",
        mainCollectionsLineNumber: "",
        legalNumber: "",
        budgetToolLink: "",
        privacyPolicyLink: "",
        brandWiseAccessibilityNumber: ""
    },
    confirmBillingAddress: {
        pageTitle: "",
        headerText: "",
        yesAndNextButton: "",
        changeAddressButton: "",
        backLink: "",
        backLinkForChangedAddress: "",
        addressChangeNotification: "",
        invalidPostCodeErrorMessage: "",
        specialCharacterErrorMessage: ""
    },
    enterBillingAddress: {
        pageTitle: "",
        headerText: "",
        firstName: "",
        lastName: "",
        firstLineOfAddress: "",
        secondLineOfAddress: "",
        townOrCity: "",
        postCode: "",
        nextButtonText: "",
        backLink: "",
        requiredFieldError: ""
    },
    enterCardDetails: {
        pageTitle: "",
        todayYouArePaying: "",
        debitCardNumber: "",
        enterCardNumberHere: "",
        expiryDate: "",
        securityCode: "",
        securityCodeInfo: "",
        payButtonText: "",
        backLink: "",
    }
});


// export class BrandContactDetailsItem {
//     brandWiseAddress: string = "";
//     brandWiseContactNumber: string = "";
//     brandWiseContactEmail: string = "";
//     brandWiseCfaNumber: string = "";
//     portalName: string = "";
//     mainCollectionsLineNumber: string = "";
//     legalNumber: string = "";
// }