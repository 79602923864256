import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ContentData } from 'libs/config/wss-app-constants';
import { DatastoreService } from 'libs/service/datastore.service';
import { Observable } from 'rxjs';
import { OpenbudgetPlannerService } from '@wss/service/openbudgetplanner.service';
import { injectBrandDetailIntoPage } from '@wss/common/util/util';

@Component({
    selector: 'bb-help-centre-landing',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './help-centre-landing.component.html',
    styles: [],
    providers: [ ContentService ]
})
export class HelpCentreLandingComponent implements OnInit {
    contentTxt: string = ContentData.HelpCentreLanding;
    content: Array<any> = [];
    contentFolder: string = ContentData.HelpCentre;
    helpCentreContent: string = 'help-centre-landing';
    accountDetails: any = {
        next_payment_date: 'XX/XX/XXXX'
    };
    param: any;

    helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');
    helpCentreWidgetItem = new HelpCentreItem();
    portalName: string = '';
    
    constructor(
        private router: Router,
        private datastoreService: DatastoreService,
        private openbudgetPlannerService: OpenbudgetPlannerService,
        private route: ActivatedRoute,
        private readonly bbContentService: ContentService,
        @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
        private tealiumService: TealiumUtagService,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.portalName = this.pageConfig.portalName || 'blackhorse';
        this.accountDetails = this.datastoreService.getAccountDetails();
        this.tealiumService.view(
            {
                JourneyName: 'Help Centre',
                JourneyStepName: 'Landing Page',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.getContentData();
    }

    getContentData() {
        this.helpCentreWidgetItem$.subscribe((pageItem: any) => {
            this.helpCentreWidgetItem = pageItem;
            this.helpCentreWidgetItem.helpCentreLanding = injectBrandDetailIntoPage(this.helpCentreWidgetItem.helpCentreLanding, this.helpCentreWidgetItem.brandContactDetails);
            this.changeDetectorRef.detectChanges();
        })    
    }

    getInTouchCall() {
        this.router.navigate(['help-centre/get-in-touch']);
        window.scroll(0, 0);
    }

    getHelpSupportCall() {
        this.router.navigate(['help-centre/get-help-support']);
        window.scroll(0, 0);
    }

    launchBudgetingTool(){
        this.openbudgetPlannerService.openBudgetPlanner();
    }

    onMakePaymentClick() {
        this.router.navigate(['help-centre/make-payment']);
        window.scroll(0, 0);
    }

    onMyAgreementClick() {
        this.router.navigate(['help-centre/my-agreement']);
        window.scroll(0, 0);
    }

    onManageAccountClick() {
        this.router.navigate(['help-centre/manage-account']);
        window.scroll(0, 0);
    }

    onMoneyWorriesClick() {
        this.router.navigate(['help-centre/money-worries']);
        window.scroll(0, 0);
    }

    viewGuidePDF() {
        window.open(this.helpCentreWidgetItem.helpCentreLanding.viewTheGuideLink, '_blank');
    }
}
