import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ComplaintWidgetItem } from '@wss/model/complaint';
import { WSSAppConstant, patternMap } from '@wss/config/wss-app-constants';
import { RouterService } from '@backbase/foundation-ang/core';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { MessageService } from '@wss/service/message.service';
import { getComplaintsCanDeactivate, injectBrandDetailIntoPage, injectComplaintsEmailAddressIntoPage, injectComplaintsMailSubjectIntoPage } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '@wss/complaints-widget/guard/deactivation.guard';

@Component({
  selector: 'bb-complaint-tell-us',
  templateUrl: 'complaint-tell-us.component.html',
  providers: [ContentService],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class ComplaintTellUsComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  formId: string = "complaintTellUsSelectionForm";
  submitted: boolean = false;
  complaintTellUsSelectionForm: any;
  textAreaOneRemainingCharacter: any = 0;
  textAreaSecondRemainingCharacter: any = 0;
  textAreaThirdRemainingCharacter: any = 0;
  textAreaFourRemainingCharacter: any = 0;
  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
  complaintConcernType: string = '';
  invalidDealerInfo: boolean = false;
  invalidUserExperienceText: boolean = false;
  invalidSummaryImpactText: boolean = false;
  invalidResolveComplaintText: boolean = false;
  accountDetail: any = {};

  complaintWidgetItem$: Observable<ComplaintWidgetItem | undefined> = this.bbContentService.getContent<ComplaintWidgetItem>('complaintWidgetItem');
  complaintWidgetItem = new ComplaintWidgetItem();
  complaintsTellUsEmailAddressText: any;
  complaintsTellUsEmailAddressTextForFinanceAgreement: any;
  timeInterval: any;

  constructor(
    private fb: FormBuilder,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private router: RouterService,
    private formDataService: DataService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private datastoreService: DatastoreService,
    public modalService: NgbModal
  ) {
    this.complaintConcernType = sessionStorage.getItem('ConcernType') as string;
    this.initConcernSelectionForm(this.complaintConcernType);
  }

  get dealerOfferedYouResolutionFormControl() {
    return this.complaintTellUsSelectionForm.get('dealerOfferedYouResolution');
  }

  get complaintTellUsSelectionFormControl() {
    return this.complaintTellUsSelectionForm.controls;
  }
  
  @HostListener("window:beforeunload")
  canDeactivate() {
    return getComplaintsCanDeactivate(this.formDataService.formData, this.modalService);
  }

  ngOnInit(): void {
    this.messageService.setCurrentStep(2);
    this.accountDetail = this.datastoreService.getAccountDetails();
    this.tealiumService.view({
      JourneyName: 'Complaints Digitisation',
      JourneyStepName: 'Tell us about your complaint - ' + this.complaintConcernType,
      CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    });
    const savedData = this.formDataService.getFormData(this.formId);
    if (Object.keys(savedData).length !== 0) {
      this.complaintTellUsSelectionForm.setValue(savedData);
    }
    this.changegroup('dealerOfferedYouResolution');
    this.complaintWidgetItem$.subscribe((pageItem: any) => {
      this.complaintWidgetItem = pageItem;
      this.complaintWidgetItem.complaintTellUs = injectBrandDetailIntoPage(this.complaintWidgetItem.complaintTellUs, this.complaintWidgetItem.brandContactDetails);
      this.complaintsTellUsEmailAddressText = injectComplaintsMailSubjectIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressText, this.complaintConcernType, this.accountDetail?.account_number);
      this.complaintsTellUsEmailAddressTextForFinanceAgreement = injectComplaintsEmailAddressIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressTextForFinanceAgreement, this.complaintWidgetItem.complaintTellUs.complaintsEmailAddressForFinanceAgreement, this.complaintConcernType, this.accountDetail?.account_number);
      this.timeInterval = setInterval(() => {
        this.complaintsTellUsEmailAddressText = injectComplaintsMailSubjectIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressText, this.complaintConcernType, this.accountDetail?.account_number);
        this.complaintsTellUsEmailAddressTextForFinanceAgreement = injectComplaintsEmailAddressIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressTextForFinanceAgreement, this.complaintWidgetItem.complaintTellUs.complaintsEmailAddressForFinanceAgreement, this.complaintConcernType, this.accountDetail?.account_number);
        this.changeDetectorRef.detectChanges();
      }, 1000);
      this.changeDetectorRef.detectChanges();
    });
  }

  initConcernSelectionForm(concernType?: any) {
    this.complaintTellUsSelectionForm = this.fb.group({
      dealerAskedYouToContactOption: concernType == 'Vehicle' ? ["", [Validators.required]] : [""],
      dealerOfferedYouResolution: concernType == 'Vehicle' ? ["", [Validators.required]] : [""],
      moreInfoAboutDealersResolution: [""],
      issueYouHaveExperiencedTextArea: ["", [Validators.required, Validators.pattern(patternMap.textboxPattern)]],
      summaryOfImpactTextarea: ["", [Validators.required, Validators.pattern(patternMap.textboxPattern)]],
      howWouldYouLikeToResolveComplaintTextarea: ["", [Validators.required, Validators.pattern(patternMap.textboxPattern)]]
    })
    this.complaintTellUsSelectionForm.valueChanges.subscribe((value: any) => {
      this.textAreaOneRemainingCharacter = value.moreInfoAboutDealersResolution.length;
      this.textAreaSecondRemainingCharacter = value.issueYouHaveExperiencedTextArea.length;
      this.textAreaThirdRemainingCharacter = value.summaryOfImpactTextarea.length;
      this.textAreaFourRemainingCharacter = value.howWouldYouLikeToResolveComplaintTextarea.length;
    });

    this.complaintTellUsSelectionForm.controls['moreInfoAboutDealersResolution'].valueChanges.forEach(
      (value: string) => {
        if (value.length > 0 && !patternMap.textboxPattern.test(value)) {
          this.invalidDealerInfo = true;
        } else {
          this.invalidDealerInfo = false;
        }
      }
    );

    this.complaintTellUsSelectionForm.controls['issueYouHaveExperiencedTextArea'].valueChanges.forEach(
      (item: string) => {
        if (item.length > 0 && !patternMap.textboxPattern.test(item)) {
          this.invalidUserExperienceText = true;
        } else {
          this.invalidUserExperienceText = false;
        }
      }
    );

    this.complaintTellUsSelectionForm.controls['summaryOfImpactTextarea'].valueChanges.forEach(
      (response: string) => {
        if (response.length > 0 && !patternMap.textboxPattern.test(response)) {
          this.invalidSummaryImpactText = true;
        } else {
          this.invalidSummaryImpactText = false;
        }
      }
    );

    this.complaintTellUsSelectionForm.controls['howWouldYouLikeToResolveComplaintTextarea'].valueChanges.forEach(
      (data: string) => {
        if (data.length > 0 && !patternMap.textboxPattern.test(data)) {
          this.invalidResolveComplaintText = true;
        } else {
          this.invalidResolveComplaintText = false;
        }
      }
    );
  }

  changegroup(control: string) {
    this.complaintTellUsSelectionForm.get(control).valueChanges.subscribe((value: string) => {
      if (value) {
        this.complaintTellUsSelectionForm.get('moreInfoAboutDealersResolution').setValue("");
        this.complaintTellUsSelectionForm.get('moreInfoAboutDealersResolution').setValidators([Validators.required, Validators.pattern(patternMap.textboxPattern)]);
      }
      this.complaintTellUsSelectionForm.get('moreInfoAboutDealersResolution').updateValueAndValidity();
    });
  }

  handlePreviousBtn() {
    this.formDataService.saveFormData(this.formId, this.complaintTellUsSelectionForm.value);
    this.router.navigate(['relate-to']);
  }

  handleNextBtn() {
    this.submitted = true;
    if (this.complaintTellUsSelectionForm.valid) {
      this.formDataService.saveFormData(this.formId, this.complaintTellUsSelectionForm.value);
      if (this.complaintConcernType == 'Vehicle') {
        this.router.navigate(['about-vehicle']);
      } else {
        this.router.navigate(['your-contact-details']);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }
}
