import { BrandContactDetailsItem } from "../content/common/brand-contant-details";

export class BalloonRefinanceItem {
    balloonRefinanceLanding = new BalloonRefinanceLandingItem();
    balloonRefinanceGetAQuote = new BalloonRefinanceGetAQuote();
    balloonRefinanceCtaArrears = new BalloonRefinanceCtaArrears();
    balloonRefinanceCtaOutOfHours = new BalloonRefinanceCtaOutOfHours();
    balloonRefinanceCtaDdCalling = new BalloonRefinanceCtaDDCalling();
    balloonRefinanceCtaEoc = new BalloonRefinanceCtaEoc();
    balloonRefinanceGetStarted = new BalloonRefinanceGetStarted();
    commonNavigation = new CommonNavigation();
    balloonRefinanceCtaExpiredEocPeriod = new BalloonRefinanceCtaExpiredEocPeriod();
    brandContactDetails = new BrandContactDetailsItem();
}

export class BalloonRefinanceLandingItem {
    pageTitle: string = "";
    bodyFirstSection: string = "";
    howItWorksSectionHeader: string = "";
    howItWorksSectionListItems: any[] = [];
    whatYouNeedToKnowText: string = "";
    whatYouNeedToKnowAccordions: WSSAccordion[] = [];
    cardContent: string = "";
    buttonText: string = "";
    backToPageLinkText: string = "";
    cardFooter: string = "";
    currentOngoingRefinanceApplicationText: string = "";
    continueApplicationLinkText: string = "";
    returnAndSignText: string = "";
    reviewYourApplicationResultText: string = "";
}

export interface WSSAccordion {
    title?: string,
    content?: string
}

export class BalloonRefinanceGetAQuote {
    pageTitle: string = "";
    quoteDetailsContentBeforeData: string = "";
    quoteDetailsContentBeforeDataUnderlined: string = "";
    quoteDetailsContentAfterDataUnderlined: string = "";
    quoteDetailsContentAfterData: string = "";
    remainingBalanceAccordionTitle: string = "";
    remainingBalanceAccordionText: string = "";
    quoteInputTitle: string = "";
    quoteInputLabel: string = "";
    quoteCalculateTextSection: string = "";
    quoteCalculatePerMonth: string = "";
    quoteNewDetailsHeader: string = "";
    quoteAgreementTitle: string = "";
    quoteAgreementPopoverText: string = "";
    quoteAgreementText: string = "";
    quoteMonthlyTitle: string = "";
    quoteTermPopoverText: string = "";
    quoteTermTitle: string = "";
    quoteTermText: string = "";
    quoteAPRTitle: string = "";
    quoteAPRPopoverText: string = "";
    quoteAPRPercentage: number = 0;
    quoteOptionalFinalPaymentTitle: string = "";
    quoteRefinanceAmountTitle: string = "";
    quoteRefinanceAmountPopoverText: string = "";
    quoteTotalInterestTitle: string = "";
    quoteTotalInterestPopoverText: string = "";
    quoteTotalAmountTitle: string = "";
    quoteTotalAmountPopoverText: string = "";
    quoteOptionFeeTextSection1: string = "";
    quoteOptionFeeTextSection2: string = "";
    quoteProceedText: string = "";
    quoteButtonAcceptText: string = "";
    quoteButtonCancelText: string = "";
    FAQHeader: string = "";
    FAQAccordions: WSSAccordion[] = [];
    FAQAccordionOptionFeeTitle: string = "";
    FAQAccordionOptionFeeText: string = "";
    termInputError: string = "";
    vehicleAgeError: string = "";
    vehicleAgeErrorEndTxt: string = "";
    vehicleAgeErrorLessThan12Months: string = "";
    minValueLimitError: string = "";
    maxValueLimitError: string = "";
    vehicleMinValueAndAgeLimitError: string = "";
    vehicleMaxValueAndAgeLimitError: string = "";
}

export interface BalloonRefinanceSavedQuote {
    balloonRefinance: number;
    balloonPayment: number;
    monthlyPayment: number;
    termLength: any;
    optionalFinalPayment: number;
    totalInterest: number;
    totalAmount: number;
}

export class BalloonRefinanceCtaArrears {
    title: string = "";
    bodyText: string = "";
    secondaryButtonText: string = "";
    linkText: string = "";
}

export class BalloonRefinanceCtaOutOfHours {
    title: string = "";
    bodyText: string = "";
    primaryButtonText: string = "";
}

export class BalloonRefinanceCtaDDCalling {
    title: string = "";
    bodyText: string = "";
    primaryButtonText: string = "";
    secondaryButtonText: string = "";
}

export class BalloonRefinanceCtaEoc {
    title: string = "";
    bodyText: string = "";
    bodyTextAfterDate: string = "";
    primaryButtonText: string = "";
}

export class BalloonRefinanceGetStarted {
    pageTitle: string = "";
    bodyFirstSection: string = "";
    whatYouWillNeedHeader: string = "";
    whatYouWillNeedContent: string = "";
    howWeUseInformationHeader: string = "";
    howWeUseInformationContent: string = "";
    beforeYouContinueHeader: string = "";
    beforeYouContinueContent: string = "";
    dpnTitle: string = "";
    dpnPersonalInfoSection: string = "";
    dpnFullPrivacyNoticeSection: string = "";
    dpnHowWeUseInfoSection: string = "";
    dpnShareInfoSection: string = "";
    dpnCollectInfoSection: string = "";
    dpnGiveUsInfoSection: string = "";
    dpnRightsOverInfoSection: string = "";
    pnFinancialLinksSection: string = "";
    dpnCreditAgenciesSection: string = "";
    dpnFraudPreventionSection: string = "";
    dpnContactFromUsAndMarketingSection: string = "";
    dpnPrivacyNoticeSection: string = "";
    dpnContactUsSection: string = "";
    dpnVersionControlSection: string = "";
}

export class CommonNavigation {
    primaryButtonText: string = "";
    secondaryButtonText: string = "";
    linkText: string = "";
    modalHeader: string = "";
    modalContentFirstPara: string = "";
    modalContentSecondParaBeforeDate: string = "";
    modalContentSecondParaAfterDate: string = "";
    modalContentConfirm: string = "";
    modalPrimaryButtonText: string = "";
    modalSecondaryButtonText: string = "";
    cancelButtonText: string = "";
    saveAndReviewButtonText: string = "";
    modalLandingHeader: string = "";
    modalLandingContent: string = "";
    modalLandingPriBtnTxt: string = "";
    modalLandingSecBtnTxt: string = "";
    returnToSignAgreementHeader: string = "";
    returnToSignAgreementContent: string = "";
    returnToSignAgreementButtonText: string = "";
    reviewYourApplicationResultHeader: string = "";
    reviewYourApplicationResultContent: string = "";
    reviewYourApplicationResultText: string = "";
}

export class BalloonRefinanceCtaExpiredEocPeriod {
    title: string = "";
    bodyText: string = "";
    secondaryButtonText: string = "";
    linkText: string = "";
    getInTouch: string = "";
}
