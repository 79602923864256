<div class="eoc-landing" *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <div class="reg-row plr-10pt" role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div *ngIf="isResumableBrApplication && !isEocLandingPageResumeBrAppBannerClosed">
        <bb-wss-banner-message [isEocLandingPageBanner]="isEocLandingPageBanner" [resumeBrApplicationLinkText]="resumeBrApplicationLinkText"
            [resumeBrApplicationLink]="resumeBrApplicationLink" [continueApplicationText]="continueApplicationText" 
            [eocBrReturnAndSignText]="eocBrReturnAndSignText" [proposalStatus]="proposalStatus" [isDecisionChanged]="isDecisionChanged"
            [reviewYourApplicationResultText]="reviewYourApplicationResultText">
        </bb-wss-banner-message>
    </div>
    <div class="container-column" *ngIf="endOfContractWidgetItem.endOfContractLanding">
        <div class="section-title-container">
            <h1 class="section-label-title" test_id="pageName">
                {{ endOfContractWidgetItem.endOfContractLanding.pageName }}</h1>
        </div>
        <div class="container-column single-column" test_id="pageContentText" *ngIf="!isOptionSelected">
            <div class="mt-10px mb-p-0">
                <span class="para-inline">
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agreementEndTextBeforeDate">
                    </bb-content-html>
                </span>
                <span>{{ eocCompletionDate | date: 'dd MMMM yyyy'}}. </span>
                <span class="flex-width additional-info-item-pull-right para-inline"
                    test_id="agreementEndContentAfterDataText">
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agreementEndTextAftereDate">
                    </bb-content-html>
                </span>
            </div>
            <div class="margin-top-20 mb-p-0">
                <span class="para-inline">
                    <ng-container *ngIf="isEligibleForBalloonRefinance && withinNinetyDays">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agrmtEndDetailsSecFourInNinety">
                        </bb-content-html>
                    </ng-container>
                    <ng-container *ngIf="isEligibleForBalloonRefinance && !withinNinetyDays">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agrmtEndDetailsSecFourOutNinety">
                        </bb-content-html>
                    </ng-container>
                    <ng-container *ngIf="!isEligibleForBalloonRefinance && withinNinetyDays">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agrmtEndDetailsSecThreeInNinety">
                        </bb-content-html>
                    </ng-container>
                    <ng-container *ngIf="!isEligibleForBalloonRefinance && !withinNinetyDays">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agrmtEndDetailsSecThreeOutNinety">
                        </bb-content-html>
                    </ng-container>
                </span>
                <strong>{{ lastDateForDecision | date: 'dd MMMM yyyy'}}.</strong>
            </div>
            <div class="margin-top-20">
                <ng-container *ngIf="isEligibleForBalloonRefinance && withinNinetyDays">
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agrmtEndDetailsThirdPtFourInNinety">
                    </bb-content-html>
                </ng-container>
                <ng-container *ngIf="isEligibleForBalloonRefinance && !withinNinetyDays">
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agrmtEndDetailsThirdPtFourOutNinety">
                    </bb-content-html>
                </ng-container>
                <ng-container *ngIf="!isEligibleForBalloonRefinance && withinNinetyDays">
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agrmtEndDetailsThirdPtThreeInNinety">
                    </bb-content-html>
                </ng-container>
                <ng-container *ngIf="!isEligibleForBalloonRefinance && !withinNinetyDays">
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agrmtEndDetailsThirdPtThreeOutNinety">
                    </bb-content-html>
                </ng-container>
            </div>
        </div>
        <div class="container-column" *ngIf="isOptionSelected" test_id="agreementIntentionBeforeDate">
            <div class="mb-20px para-inline">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.agreementIntentionBeforeDate">
                </bb-content-html>
                <span class="font-weight-bold">{{ eocCompletionDate | date: 'dd MMM yyyy' }}</span>&nbsp;
                <ng-container *ngIf="accountDetail.customer_intention === 'F'">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractLanding.agreementIntentionAfterDateKeepVehicle">
                    </bb-content-html>
                </ng-container>
                <ng-container *ngIf="accountDetail.customer_intention === 'R'">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractLanding.agreementIntentionAfterDateReturnVehicle">
                    </bb-content-html>
                </ng-container>
                <ng-container *ngIf="accountDetail.customer_intention === 'T' || accountDetail.customer_intention === 'P'">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractLanding.agreementIntentionAfterDatePartExchange">
                    </bb-content-html>
                </ng-container>
                <ng-container *ngIf="accountDetail.customer_intention === 'E'">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractLanding.agreementIntentionAfterBalloonRefinance">
                    </bb-content-html>
                </ng-container>
            </div>
            <div class="last-child-mb-10px" test_id="optionReminderText">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.optionReminderText">
                </bb-content-html>
            </div>
        </div>
        <div class="mt-10px">
            <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.optionsHeader"></bb-content-html>
        </div>
        <!-- option 1 -->
        <div class="row">
            <div class="col-md-12 col-sm-12 flex-card"
            [ngClass]="{'col-lg-6': isEligibleForBalloonRefinance, 'col-lg-4': !isEligibleForBalloonRefinance}">
                <div class="container-column card">
                    <!-- diff-card-view option1 -->
                    <div class="font-size-20 text-center eoc-options-section mb-p-0">
                        <div test_id="sectionTitle" class="mb-0">
                            <span class="option-header-break">
                                <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.firstOptionHeading"></bb-content-html>
                            </span>
                            <span>{{endOfContractWidgetItem.endOfContractLanding.keepYourVehicle}}</span>
                        </div>
                    </div>
                    <div class="card-content flex-card-content">
                        <section>
                            <div class="brand-contact-details mb-0">
                                <div test_id="firstOptionHeaderText" class="mb-20px">
                                    <bb-content-html
                                        [html]="endOfContractWidgetItem.endOfContractLanding.firstOptionHeaderText">
                                    </bb-content-html>
                                </div>
                                <div>
                                    <ul class="mb-0">
                                        <li class="mb-p-0">
                                            <span class="para-inline">
                                                <bb-content-html
                                                [html]="endOfContractWidgetItem.endOfContractLanding.firstOptionGlanceBeforeDataText">
                                                </bb-content-html>
                                                <span>{{ accountDetail?.final_payment_amount}}</span>
                                                <bb-content-html
                                                    [html]="endOfContractWidgetItem.endOfContractLanding.firstOptionGlanceAfterDateDataText">
                                                </bb-content-html>
                                            </span>
                                        </li>
                                        <li class="mb-p-0">
                                            <bb-content-html
                                                [html]="endOfContractWidgetItem.endOfContractLanding.firstOptionGlance2ndPointText">
                                            </bb-content-html>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="container-row margin-top-20">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractLanding.firstOptionSecondndHeaderText">
                                </bb-content-html>
                            </div>
                        </section>
                        <div class="d-flex justify-content-center">
                            <button class="mb-0" [ngClass]="isOptionSelected ? 'wss-button-secondary' : 'wss-button'" id="btnKeepVehicle"
                                (click)="navigateToOption('keep-your-vehicle')">{{endOfContractWidgetItem.endOfContractLanding.keepMyVehicleButtonText}}</button>
                        </div>
                    </div>
                </div>
            </div>
<!-- Option 2 -->
            <div class="col-md-12 col-lg-6 col-sm-12 flex-card" *ngIf="isEligibleForBalloonRefinance">
                <div class="container-column card">
                    <!-- diff-card-view option1 -->
                    <div class="font-size-20 text-center eoc-options-section mb-p-0">
                        <div test_id="sectionTitle2" class="mb-0">
                            <span class="option-header-break">
                                <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.secondOptionHeading"></bb-content-html>
                            </span>
                            <span>{{endOfContractWidgetItem.endOfContractLanding.balloonRefinance}}</span>
                        </div>
                    </div>
                    <div class="card-content flex-card-content">
                        <section>
                            <div class="brand-contact-details mb-0">
                                <div test_id="balloonRefinanceHeaderText" class="mb-20px">
                                    <bb-content-html
                                        [html]="endOfContractWidgetItem.endOfContractLanding.balloonRefinanceHeaderText">
                                    </bb-content-html>
                                </div>
                                <div>
                                    <ul class="mb-0">
                                        <li class="mb-p-0">
                                            <span class="para-inline">
                                                <bb-content-html
                                                [html]="endOfContractWidgetItem.endOfContractLanding.balloonRefinanceOptionGlanceFirstPointText">
                                                </bb-content-html>
                                                <span>{{ accountDetail?.final_payment_amount}}.</span>
                                            </span>
                                        </li>
                                        <li class="mb-p-0">
                                            <bb-content-html
                                                [html]="endOfContractWidgetItem.endOfContractLanding.balloonRefinanceOptionGlanceSecondPointText">
                                            </bb-content-html>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="container-row margin-top-20">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractLanding.balloonRefinanceKeepInMindText">
                                </bb-content-html>
                            </div>
                        </section>
                        <div class="d-flex justify-content-center">
                            <button class="mb-0" [ngClass]="isOptionSelected ? 'wss-button-secondary' : 'wss-button'" id="btnBalloonRefinance"
                                (click)="navigateToBrPage()">{{endOfContractWidgetItem.endOfContractLanding.balloonRefinanceButtonText}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 flex-card"
                [ngClass]="{'col-lg-6': isEligibleForBalloonRefinance, 'col-lg-4': !isEligibleForBalloonRefinance}">
                <!-- option 3-->
                <div class="container-column card">
                    <!-- diff-card-view -->
                    <div class="font-size-20 text-center eoc-options-section mb-p-0">
                        <div test_id="sectionTitle3" class="mb-0">
                            <span class="option-header-break">
                                <ng-container *ngIf="isEligibleForBalloonRefinance">
                                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.thirdOptionHeading"></bb-content-html>
                                </ng-container>
                                <ng-container *ngIf="!isEligibleForBalloonRefinance">
                                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.secondOptionHeading"></bb-content-html>
                                </ng-container>
                            </span>
                            <span>{{endOfContractWidgetItem.endOfContractLanding.partExchange}}</span>
                        </div>
                    </div>
                    <div class="card-content flex-card-content">
                        <section>
                            <div class="container-row mb-ul-0">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractLanding.secondOptionGlanceInfoText">
                                </bb-content-html>
                            </div>
                            <div class="container-row margin-top-20">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractLanding.secondOptionSecondHeaderText">
                                </bb-content-html>
                            </div>
                        </section>
                        <div class="d-flex justify-content-center">
                            <button class="mb-0" [ngClass]="isOptionSelected ? 'wss-button-secondary' : 'wss-button'"  id="btnPartExchange"
                                (click)="navigateToOption('part-exchange')">{{endOfContractWidgetItem.endOfContractLanding.partExchangeButtonText}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 flex-card"
            [ngClass]="{'col-lg-6': isEligibleForBalloonRefinance, 'col-lg-4': !isEligibleForBalloonRefinance}">
                <!-- option 4 -->
                <div class="container-column card">
                    <!-- diff-card-view -->
                    <div class="font-size-20 text-center eoc-options-section mb-p-0">
                        <div test_id="sectionTitle4" class="mb-0">
                            <span class="option-header-break">
                                <ng-container *ngIf="isEligibleForBalloonRefinance">
                                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.fourthOptionHeading"></bb-content-html>
                                </ng-container>

                              <ng-container *ngIf="!isEligibleForBalloonRefinance">
                                <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.thirdOptionHeading"></bb-content-html>
                            </ng-container>
                            </span>
                            <span>{{endOfContractWidgetItem.endOfContractLanding.returnVehicle}}</span>
                        </div>
                    </div>
                    <div class="card-content flex-card-content">
                        <section>
                            <div class="container-row mb-ul-0">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractLanding.ThirdOptionGlanceInfoText">
                                </bb-content-html>
                            </div>
                            <div class="container-row margin-top-20">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractLanding.ThirdOptionSecondHeaderText">
                                </bb-content-html>
                            </div>
                        </section>
                        <div class="d-flex justify-content-center">
                            <button class="mb-0" [ngClass]="isOptionSelected ? 'wss-button-secondary' : 'wss-button'" id="btnReturnVehicle"
                                (click)="navigateToOption('return-vehicle')">{{endOfContractWidgetItem.endOfContractLanding.retrunYourVehicleButtonText}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-column" test_id="pageContentText">
            <div class="mt-30px mb-h2-23">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractLanding.contactDetailHeaderText">
                </bb-content-html>
            </div>
        </div>
    </div>