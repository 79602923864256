import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ComplaintWidgetItem } from '@wss/model/complaint';
import { WSSAppConstant, patternMap } from '@wss/config/wss-app-constants';
import { RouterService } from '@backbase/foundation-ang/core';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { DatastoreService } from '@wss/service/datastore.service';
import { DataService } from '../../services/data.service';
import { MessageService } from '@wss/service/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '@wss/complaints-widget/guard/deactivation.guard';
import { getComplaintsCanDeactivate } from '@wss/common/util/util';

@Component({
  selector: 'bb-complaint-relate-to',
  templateUrl: 'complaint-relate-to.component.html',
  providers: [ContentService],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class ComplaintRelateToComponent implements OnInit, CanComponentDeactivate {

  formId: string = "complaintRelateToSelectionForm";
  complaintRelateToSelectionForm: any;
  accountDetail: any;
  customerAddress: any = {};
  personalDetails: any = {};
  customerDetail: any = {};
  emailAdress: string = '';
  submitted: boolean = false;
  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
  complaintConcernType: string = '';
  textAreaOneRemainingCharacter: any = 0;
  patternMatch = /[a-zA-Z0-9!@#$€£%^&*()_+\-=\[\]{};`¬ ~':"\\|,.<>\/?]/g;
  invalidContactDetails: boolean = false;
  complaintServicingCategoriesTwo: any = {
    "Changing details on my loan": [
      "Address change is wrong",
      "Address has not been changed",
      "Car details are wrong",
      "I'm not happy with the information I was given",
      "My name has not been changed",
      "Name change is wrong",
      "Payment date change is wrong",
      "Payment date has not changed",
      "Updated balance is wrong"
    ],
    "Contact you've made with me": [
      "Breathing space not given",
      "Can't send information by email",
      "Content of letters",
      "I'm being contacted too much",
      "I'm not happy with the service I received from a third party",
      "I'm still being contacted after asking not to be",
      "I've not been contacted enough"
    ],
    "COVID Payment holiday": [
      "Asked to pay an extra amount after taking a payment holiday during COVID",
      "I wasn't given enough support",
      "I'm not happy with the information I was given",
      "Payment holiday request wasn't processed"
    ],
    "Extending my Loan": [
      "Direct Debit Not Set Up",
      "I have not been able to contact you",
      "I'm not happy with the information I was given",
      "It's taking too long",
      "I've not been contacted",
      "My documents have not been processed",
      "My extension wasn't processed correctly",
      "The product isn't right for my needs"
    ],
    "Information you hold about me": [
      "Impact on my credit file",
      "Information on my credit file wrong",
      "Information you have about me is wrong",
      "It's taking too long to update my credit file",
      "No consent given",
      "Request for data not received",
      "Sharing my data with a 3rd party"
    ],
    "Insurance claim for my vehicle": [
      "Amount I need to pay is wrong",
      "I'm not happy with the information I was given",
      "I'm not happy with the process",
      "Insurance settlement is taking too long",
      "My account has not been updated"
    ],
    "Issues with the website": [
      "A change to my account has not been done",
      "I can't log in to my online account",
      "I'm having problems making a payment",
      "I'm not happy with the information I was given"
    ],
    "My Direct Debit": [
      "Has been cancelled or suspended",
      "Has been collected for the wrong amount",
      "Has been collected on the wrong date",
      "Has been set up with wrong details",
      "Has not been put back in place",
      "Has not been set up"
    ],
    "Paying off my loan": [
      "Direct Debit taken even though balance is paid in full",
      "I have not received a settlement figure letter",
      "I'm being contacted too much",
      "I'm not happy with the email I received",
      "It's taking too long",
      "I've been told I can't settle my balance and close my account",
      "My settlement amount is wrong",
      "Payments I have made are not showing on my settlement figure"
    ],
    "Payments on my account": [
      "A payment has been sent using the wrong bank details",
      "A payment is not showing on my account",
      "A refund amount is wrong",
      "A wrong amount has been added to my account",
      "An overpayment amount is wrong",
      "Can't pay by credit card",
      "I have not received my refund",
      "I'd like to request a refund",
      "I'm not happy with the information I was given",
      "I'm not happy with the information I was given about a payment plan",
      "I'm not happy with the information I was given about a refund",
      "I'm not happy with the information I was given about an overpayment",
      "It's taken too long to close my account",
      "It's taken too long to receive a refund",
      "I've had a problem trying to make a payment",
      "My overpayment has not been added to my account",
      "My payment date is wrong",
      "My payment has not be added to my account",
      "Payment plan set up for wrong amount",
      "The payment method on my account is wrong",
      "Wrong payment amount showing on account",
      "You have not cancelled my payment plan",
      "You have not made a payment to my account",
      "You have not set up my payment plan"
    ],
    "Recovering my vehicle": [
      "I'm not happy with the information I was given",
      "I'm not happy with the service I received from a third party",
      "Lack of communication",
      "Legal action against me",
      "Repossessing my vehicle",
      "Taking my vehicle"
    ],
    "Refinancing my Balloon Payment": [
      "Direct Debit not set up",
      "Documents I gave you were processed wrong",
      "I have not been able to contact you",
      "I was not contacted",
      "I'm not happy with the information I was given",
      "It's taking too long",
      "My documents have not been processed",
      "Turned down for vehicle finance"
    ],
    "Returning my vehicle": [
      "Amount I need to pay is wrong",
      "Amount of excess mileage is wrong",
      "Charges for damage",
      "Charges for damage at end of contract",
      "Excess Mileage Figure Incorrect",
      "Fair wear and tear guidance",
      "I'm not happy with the information I was given",
      "I'm not happy with the service I received from a third party",
      "Impact on my credit file",
      "It's taking too long",
      "I've been contacted too much",
      "My vehicle wasn't collected",
      "Process is confusing",
      "Taking too long to collect my vehicle",
      "Vehicle collection booking error"
    ],
    "Setting up the finance for my vehicle": [
      "Credit search details are wrong",
      "Dealer behaviour",
      "Dealer did not explain the finance to me",
      "Dealer did not explain the insurance to me",
      "Direct Debit details are wrong",
      "History of the vehicle",
      "I did not know I agreed to take out insurance",
      "I didn’t understand what I'd signed up to",
      "I don’t understand the interest calculation",
      "I felt pressured to take out vehicle finance",
      "I need additional support",
      "I'm not happy with the information I was given about insurance",
      "I'm not happy with the information I was given by my Dealer",
      "It's taking too long",
      "Mileage allowance",
      "Some of my agreement details are wrong",
      "The figures on my finance agreement are not what I expected",
      "The type of vehicle finance is not right",
      "Turned down for vehicle finance",
      "Vehicle delivery time too long"
    ],
    "Other": [
      "Dealer taking too long to collect vehicle",
      "It's taking too long",
      "My balance is wrong",
      "You've haven't actioned my request",
      "Other"
    ]
  };
  complaintCategoriesServicingLevelTwo: any;
  complaintCategoriesSQD: any = [
    "Battery",
    "Brakes",
    "Chassis and wheels",
    "Complete vehicle failure",
    "Diesel particulate filter",
    "Electrical",
    "Exterior bodywork",
    "Infotainment system",
    "Interior",
    "Leisure - Cosmetic",
    "Leisure - Water Ingress",
    "Mechanical/Engine",
    "Missing items",
    "Noise issues",
    "Tyres",
    "Windows and windscreen",
    "Other"
  ];
  mySelections: any;
  selectedCategories: any;
  
  @HostListener("window:beforeunload")
  canDeactivate() {
    return getComplaintsCanDeactivate(this.formDataService.formData, this.modalService);
  }

  constructor(
    private fb: FormBuilder,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private router: RouterService,
    private datastoreService: DatastoreService,
    private formDataService: DataService,
    private messageService: MessageService,
    public modalService: NgbModal
  ) {
    this.complaintConcernType = sessionStorage.getItem('ConcernType') as string;
    this.initConcernSelectionForm(this.complaintConcernType);
    this.accountDetail = this.datastoreService.getAccountDetails();
    this.customerDetail = this.datastoreService.getCustomerDetail();
    if (this.customerDetail) {
      this.customerAddress = this.customerDetail?.addresses?.address_detail;
      if (this.customerAddress?.post_town != null) {
        this.customerAddress.post_town = this.customerAddress?.post_town?.toLowerCase();
      }
      let firstName = this.customerDetail?.individual?.first_name;
      let surname = this.customerDetail?.individual?.surname;
      if (firstName?.length > 18) {
        firstName = firstName.substr(0, 18);
      }
      if (surname?.length > 40) {
        surname = surname.substr(0, 40);
      }
      this.personalDetails.fullname = firstName + ' ' + surname;
      this.personalDetails.fullname = this.personalDetails.fullname.toLowerCase();
    }
    this.emailAdress = this.datastoreService.getLoggedInEmail();
    this.textboxCharacterChange();
  }

  complaintWidgetItem$: Observable<ComplaintWidgetItem | undefined> = this.bbContentService.getContent<ComplaintWidgetItem>('complaintWidgetItem');


  ngOnInit(): void {
    this.messageService.setCurrentStep(1);
    this.tealiumService.view({
      JourneyName: 'Complaints Digitisation',
      JourneyStepName: 'Complaint relates to - ' + this.complaintConcernType,
      CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    });
    const savedData = this.formDataService.getFormData(this.formId);
    if (Object.keys(savedData).length !== 0) {
      this.complaintRelateToSelectionForm.setValue(savedData);
    }
  }

  initConcernSelectionForm(concernType?: any) {
    this.complaintRelateToSelectionForm = this.fb.group({
      concernsRaiseWithDealerOption: concernType == 'Vehicle' ? ["", [Validators.required]] : [""],
      speakWithSomeElseOption: ["", [Validators.required]],
      complaintRelateToSelection: ["", [Validators.required]],
      selectedCategories: [""],
      whatWentWrongComplaintSelection: concernType == 'Vehicle' ? [""] : ["", [Validators.required]],
      contactDetail: [''],
    });
    this.changegroup();
  }

  textboxCharacterChange() {
    this.complaintRelateToSelectionForm.valueChanges.subscribe((value: any) => {
      this.textAreaOneRemainingCharacter = value.contactDetail.length;
      this.formDataService.saveFormData(this.formId, value);
    });

    this.complaintRelateToSelectionForm.controls['contactDetail'].valueChanges.forEach(
      (value: string) => {
        if (value && value.length > 0 && !patternMap.textboxPattern.test(value)) {
          this.invalidContactDetails = true;
        } else {
          this.invalidContactDetails = false;
        }
      }
    );
  }

  get speak_with_someone_else_selection_value() {
    return this.complaintRelateToSelectionForm.get('speakWithSomeElseOption');
  }

  get raise_concern_selection_value() {
    return this.complaintRelateToSelectionForm.get('concernsRaiseWithDealerOption');
  }

  get complaintRelateToFormControl() {
    return this.complaintRelateToSelectionForm.controls;
  }

  changegroup() {
    this.complaintRelateToSelectionForm.get('speakWithSomeElseOption').valueChanges.subscribe((value: string) => {
      if (value == "Yes") {
        this.complaintRelateToSelectionForm.get('contactDetail').setValidators([Validators.required, Validators.pattern(patternMap.textboxPattern)]);
      } else {
        this.complaintRelateToSelectionForm.get('contactDetail').clearValidators();
      }
      this.complaintRelateToSelectionForm.get('contactDetail').updateValueAndValidity();
    });

    this.complaintRelateToSelectionForm.get('complaintRelateToSelection').valueChanges.subscribe((value: string) => {
      if (value != "") {
        this.complaintCategoriesServicingLevelTwo = this.complaintServicingCategoriesTwo[value]
      }
      if (this.complaintConcernType == 'FinanceAgreement') {
        this.complaintRelateToSelectionForm.get('whatWentWrongComplaintSelection').reset();
        this.complaintRelateToSelectionForm.get('whatWentWrongComplaintSelection').updateValueAndValidity();
      }
    });
  }

  handlePreviousBtn() {
    this.formDataService.saveFormData(this.formId, this.complaintRelateToSelectionForm.value);
    this.router.navigate(['get-started']);
  }

  handleNextBtn() {
    this.submitted = true;
    if (this.complaintRelateToSelectionForm.valid) {
      this.formDataService.saveFormData(this.formId, this.complaintRelateToSelectionForm.value);
      this.router.navigate(['tell-us']);
    }
  }

  openProfilePopup(event: any) {
    event.stopPropagation();
    this.messageService.setProfileActive(true);
  }

  returnZero() {
    return 0;
  }

  complaintCategoriesSelection() {
    if (this.complaintRelateToSelectionForm.get('complaintRelateToSelection').value.length < 6) {
      this.mySelections = this.complaintRelateToSelectionForm.get('complaintRelateToSelection').value;
      this.selectedCategories = this.mySelections?.join('>');
      this.complaintRelateToSelectionForm.get('selectedCategories').setValue(this.selectedCategories);
    } else {
      this.complaintRelateToSelectionForm.get('complaintRelateToSelection').setValue(this.mySelections);
    }
  }
}
