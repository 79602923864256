import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { injectBrandDetailIntoPage, 
  isNotEmpOrNullOrUndefOrWhiteSpace, 
  isEmpOrNullOrUndefOrWhiteSpace,
  reconcileCustomer } from '@wss/common/util/util';
import { appConfig, curmMemoText, balloonRefinance, proposalStatus } from '@wss/config/wss-app-constants';
import { BalloonRefinanceGetdetailsItem, ProposalRequest, addressSummaryList, addressSummaryClass } 
from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { DatastoreService } from '@wss/service/datastore.service';
import { DealerplanService } from '@wss/service/dealerplan.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BalloonRefinanceGetdetailsService } from '../../services/balloon-refinance-getdetails.service';

@Component({
  selector: 'bb-balloon-refinance-getdetails-summary',
  templateUrl: './balloon-refinance-getdetails-summary.component.html'
})
export class BalloonRefinanceGetdetailsSummaryComponent implements OnInit {

  currentPage: number = 6;
  balloonRefinanceData: any;
  classLiveAddress: string = 'col-md-4 p-0';
  previousAddressLabelId: string = 'previousAddressLabel'
  brGetdetailsItem: any = new BalloonRefinanceGetdetailsItem();
  agreementConfirmation: any;
  accountDetails: any;
  customerDetails: any;
  previousPageLink: string = '/balloon-refinance-getdetails/bank-account';
  lastDateOfDecision: any;
  commonNavTestID: string = 'wss-brg-summary';
  showCheckboxError: boolean = false;
  isOptionSelected: boolean = false;
  readonly destroy$ = new Subject();
  proposalData: ProposalRequest;
  serviceError: boolean = false;
  vehicleUses: any = appConfig.vehicleUses;
  registration: string = '';
  vehicleMake: string = '';
  model: string = '';
  vehicleUse: any;
  employeeStartDate: any;
  employmentStatus: any;
  employmentSector: any;
  employmentType: any;
  employmentTenure: any;
  employerAddress: any = {
    "address_number": null,
    "house_name": null,
    "house_number": null,
    "flat_number": null,
    "street": null,
    "district": null,
    "town": null,
    "postcode": null,
    "county": null
  };
  changeInCircumstances: any;
  bankSortCode: string = '';
  additionalAdresses: any = [{
    "address_number": null,
    "house_name": null,
    "house_number": null,
    "flat_number": null,
    "street": null,
    "district": null,
    "town": null,
    "postcode": null,
    "county": null,
    "overseas": "false",
    "months_at_address": null,
    "years_at_address": null
}]
  currentAddress: any;
  previousAddresses: any;
  dateOfBirth: string = '';
  gfvDetails: any = {};
  creditCheckData: any;
  timer: number[] = [15000, 15000, 30000];
  timerCounter: number = 0;
  decisionData: any;
  balloonRefinanceGetdetailsSummaryItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');
  residentStatus: any;
  maritalStatus: any;
  nationality: any;
  addressSummaryData: Array<any> = [];
  modagData: any;
  apr: any;
  totalPayableAmt: any;
  monthlyPayment: any;
  refinanceAmount: any;
  totalInterest: any;
  isQuoteAccordionExpanded: boolean = true;
  vehicleTypeCheck: string = '';
  agreementApr: any;
  capCode: string = '';
  callbackUrlText: string = '';
  splitPathname: string[] = window.location.pathname.split('/');
  pathname: string = this.splitPathname[1];
  defaultCallbackUrl: string =  window.location.origin + '/' + this.pathname + '/Design_Time_Addresses/CFMHub/v1/customers/817/proposals/submit';
  errorCode: string = '';

  constructor(
    private readonly bbContentService: ContentService,
    private datastore: DatastoreService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService,
    private balloonRefinanceSrv: BalloonRefinanceService,
    private modalService: NgbModal,
    private brgService: BalloonRefinanceGetdetailsService,
    private dealerplanService:  DealerplanService,
    config: NgbModalConfig
  ) { 
    this.proposalData = this.datastore.getProposalRequest();
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Summary',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.accountDetails = this.datastore.getAccountDetails();
    this.customerDetails = this.datastore.getCustomerDetail();    
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
    this.isOptionSelected = this.accountDetails?.customer_intention === 'R' || this.accountDetails?.customer_intention === 'T' || this.accountDetails?.customer_intention === 'F' || this.accountDetails?.customer_intention === 'P';
    this.setCurentPage();
    this.getContentData();
    this.getBankDetails();
    this.getAdditionalAddressDetails();
    this.getModagData();
    this.datastore.setVehicleType();
    this.datastore.setCheckVehicleType();
    this.getCapCode();
    this.datastore.setBrReturnJourney(false);
    this.changeDetectorRef.detectChanges();
  }

  setCurentPage() {
    this.datastore.setBRCurrentPage(this.currentPage);
  }

  navigateTo(path: string) {
    this.datastore.setBrReturnJourney(true);
    this.datastore.setIsReturnJourneyBrQuotePage(true);
    this.router.navigate([path]);
  }

  getAdditionalAddressDetails() {
    this.balloonRefinanceSrv.getAdditionalAddressDetails().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.serviceError = false;
      this.additionalAdresses = res;
      this.setAdditionalAddress(this.additionalAdresses);
      let allAddresses = res;
      this.currentAddress = allAddresses.filter((address: any) => address.address_detail?.id === '0');   
      if(this.currentAddress.length > 0) {
        this.currentAddress[0].moving_in_date = this.getMoveInDate(this.currentAddress[0]?.moving_in_date);
      }
      if(allAddresses.length > 1) {
        this.previousAddresses = allAddresses.filter((address: any) => address.address_detail?.id !== '0');
        this.previousAddresses.forEach((el: any) => {
          el.moving_in_date = this.getMoveInDate(el?.moving_in_date);
        });
      }
      this.changeDetectorRef.detectChanges();
    }, (error: any) => {
      this.showError();
    })
  }

  showError(val?: string): void {
    if(val){
      this.datastore.setErrorScreen(val);
    } else {
      this.datastore.setErrorServer();
    }    
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  getVehicleDetails() {
    let goodDesc = this.accountDetails?.goods_description;
    this.registration = this.accountDetails?.registration_no;  
    this.vehicleMake = goodDesc?.split(' ')[0];
    let makeDesc = goodDesc?.indexOf(' ');
    this.model = goodDesc?.substr(makeDesc);
    this.vehicleUse = this.vehicleUses.find((el: any) => el.value === this.proposalData.vehicle_use);
  }

  getAboutYouDetails() {
    const dayOfBirth = this.customerDetails?.individual.date_of_birth.substring(0, 2);
    const monthOfBirth = this.customerDetails?.individual.date_of_birth.substring(2, 4);
    const yearOfBirth = this.customerDetails?.individual.date_of_birth.substring(4, 8);
    this.dateOfBirth = dayOfBirth + '/' + monthOfBirth + '/' + yearOfBirth;    
    this.nationality = balloonRefinance.nationalityArray.find((el: any) => el.value === this.proposalData?.nationality);
    this.maritalStatus = appConfig.maritalStatus.find((el: any) => el.value === this.proposalData?.marital);
    this.residentStatus = appConfig.residentStatus.find((el: any) => el.value === this.proposalData?.resident);
  }

  getEmploymentDetails() {
    const employmentSectors = [
      { id: 'employmentSectorPrivate', value: 0, title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.employmentSectorPrivate },
      { id: 'employmentSectorGovernment', value: 1, title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.employmentSectorGovernment },
      { id: 'employmentSectorSelfEmployed', value: 2, title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.employmentSectorSelfEmployed },
      { id: 'empoymentSectorMilitary', value: 3, title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.empoymentSectorMilitary },
      { id: 'employSituationStudent', value: 4, title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.situtaionTypeStudent },
      { id: 'employSituationHome', value: 5, title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.situtaionTypeHome },
      { id: 'employSituationRetired', value: 6, title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.situtaionTypeRetired },
      { id: 'employSituationUnemploy', value: 7, title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.situtaionTypeUnemploy },
      { id: 'employSituationDisabilityBenefits', value: 8, title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.situtaionTypeDisabilityBenefits }
    ]

    const employmentTypeData = [
      { id: 'employmentTypePermanant', value: true , title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.employmentTypePermanant },
      { id: 'employmenetTypeTemporary', value: false , title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.employmenetTypeTemporary },
    ];

    const employmentTenureData = [
      { id: 'employmentTypeFullTime', value: true , title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.employmentTenureFullTime },
      { id: 'employmenetTypeHalfTime', value: false , title: this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment.employmentTenureHalfTime },
    ];

    this.employeeStartDate = this.getMonthYearFormattedDate(this.proposalData.months_at_employment, this.proposalData.years_at_employment);
    if (this.proposalData?.employment_type || this.proposalData?.employment_type === 0) {
      this.employmentSector = employmentSectors.find(el => el.value === this.proposalData.employment_type);
    } else {
      this.employmentSector = employmentSectors.find(el => el.value === this.datastore.getUnemployedStatus());
    }
    this.employmentType = employmentTypeData.find(el => el.value === this.proposalData.perm_employee);
    this.employmentTenure = employmentTenureData.find(el => el.value === this.proposalData.full_time);
    this.employerAddress.house_name = this.proposalData?.house_name;
    this.employerAddress.house_number = this.proposalData?.house_number;
    this.employerAddress.flat_number = this.proposalData?.flat_number;
    this.employerAddress.street_name = this.proposalData?.street;
    this.employerAddress.post_code = this.proposalData?.postcode;
    this.employerAddress.post_town = this.proposalData?.town;
    this.employerAddress.district = this.proposalData?.district;
    this.employerAddress.county = this.proposalData?.county;
    this.employmentStatus = this.proposalData.occupation ?
      this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment?.currentEmploymentEmployed : 
      this.brGetdetailsItem.balloonRefinanceGetdetailsEmployment?.currentEmploymentUnemployed;
  }

  getFinanceDetails() {
    const expectedChangeData = [  
      { value: 0, title: this.brGetdetailsItem.balloonRefinanceGetdetailsFinance.expectNone },
      { value: 1, title: this.brGetdetailsItem.balloonRefinanceGetdetailsFinance.expectRetire },
      { value: 2, title: this.brGetdetailsItem.balloonRefinanceGetdetailsFinance.expectRedund },
      { value: 3, title: this.brGetdetailsItem.balloonRefinanceGetdetailsFinance.expectReduct },
      { value: 4, title: this.brGetdetailsItem.balloonRefinanceGetdetailsFinance.expectCareer },
      { value: 5, title: this.brGetdetailsItem.balloonRefinanceGetdetailsFinance.expectFamily },
      { value: 6, title: this.brGetdetailsItem.balloonRefinanceGetdetailsFinance.expectOther }
    ];

    this.changeInCircumstances = expectedChangeData.find(el => el.value === this.proposalData.change_in_circumstances);
  }

  getBankDetails() {
    this.bankSortCode = this.proposalData.bank_sort_code?.slice(0, 2) + '-' + this.proposalData.bank_sort_code?.slice(2, 4) + '-' + this.proposalData.bank_sort_code?.slice(4, 6);
  }

  getMonthYearFormattedDate(mm: any, yy: any): any {
    if(mm && yy) {
      let currentYear = moment().subtract(yy, 'years').subtract(mm, 'months')
      let dateObj = {
        years: moment(currentYear).year(),
        months: moment(currentYear).month() < 10 ? '0' + moment(currentYear).month().toString() :  moment(currentYear).month().toString()
      };
      dateObj.months = (parseInt(dateObj.months) + 1).toString();
      const formattedDate = '01/' + dateObj.months + '/' + dateObj.years;
      return moment(formattedDate, 'DD/MM/YYYY').format('MMMM YYYY');
    }
  }

  getMoveInDate(date: any): any {
    let dateTemp = new Date(date).toLocaleDateString('en-GB');
    return moment(dateTemp).format('MMMM YYYY');
  }

  getContentData() {
    this.balloonRefinanceGetdetailsSummaryItem$.subscribe((pageItem: any) => {
      this.brGetdetailsItem = pageItem;
      this.brGetdetailsItem.balloonRefinanceGetdetailsSummary = injectBrandDetailIntoPage(this.brGetdetailsItem.balloonRefinanceGetdetailsSummary, this.brGetdetailsItem?.brandContactDetails);
      this.getAboutYouDetails();   
      this.getVehicleDetails();
      this.getEmploymentDetails();
      this.getFinanceDetails();
      this.callbackUrlText = this.brGetdetailsItem.balloonRefinanceGetdetailsSummary.callbackUrlText;
      this.changeDetectorRef.detectChanges();
    });
  }

  getGoodsCode() {
    const today = moment([]);
    const registrationDay = moment([this.accountDetails.registration_year, this.accountDetails.registration_month - 1, 1])
    const noOfMonths = Math.floor(moment(today).diff(moment(registrationDay), 'months', true));
    if (this.vehicleTypeCheck === 'C') {
      return this.getGoodsCodeForType(noOfMonths);
    } else if (this.vehicleTypeCheck === 'B') {
      return this.getGoodsCodeForTypeB(noOfMonths);
    } else {      
      return this.accountDetails?.goods_code;
    }    
  }

  getGoodsCodeForType(noOfMonths: number): string {
    if(noOfMonths <= 12) {
      return '01'
    } else if(12 < noOfMonths && noOfMonths < 36) {
      return '02'
    } else if(36 <= noOfMonths && noOfMonths <= 60) {
      return '03'
    } else {
      return '04'
    } 
  }

  getGoodsCodeForTypeB(noOfMonths: number): string {
    if((this.accountDetails?.goods_code === '009') && (noOfMonths > 12)) {
      return '10'
    } else {
      let goodsCodeTemp = this.accountDetails?.goods_code.slice(1);
      return goodsCodeTemp;
    } 
  }

  getCapCode() {
    this.vehicleTypeCheck = this.datastore.getCheckVehicleType();
    if(this.vehicleTypeCheck === 'B') {
      if(this.accountDetails.cap_code.length === 15) {
        let capCodeTemp = this.accountDetails.cap_code.slice(0, 13) + '    '  + this.accountDetails.cap_code.slice(-2) + 'B';
        this.capCode = capCodeTemp;
      } else if (this.accountDetails.cap_code.length === 14) {
        let capCodeTemp = this.accountDetails.cap_code.slice(0, 13) + '    '  + this.accountDetails.cap_code.slice(-1) + ' B';
        this.capCode = capCodeTemp;
      }
    } else {
      this.capCode = this.accountDetails.cap_code;
    }
  }

  saveProposalData(modalContent: any) {
    const payload = this.getPayloadForProposal();
    if(payload) { this.datastore.setProposalCustomerPayload(payload) };
    let reconcileCust: boolean = reconcileCustomer(
      payload.individual.email_address,
      this.datastore.getLoggedInEmail(),
      payload.individual.first_name,
      payload.individual.surname,      
      this.customerDetails.individual.first_name, 
      this.customerDetails.individual.surname);
    if(reconcileCust === true) {
      this.showLoader(modalContent);
      this.changeDetectorRef.detectChanges();
      this.balloonRefinanceSrv.postCustomer(payload, this.vehicleTypeCheck).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.serviceError = false;
        this.creditCheckData = this.datastore.getProposalCustomerDetails();
        if(res.proposal?.decision?.decision_status === 'INCOMPLETE'){
          this.postProposalData();
          this.hideLoader();
          this.router.navigate(['/balloon-refinance/cta-expired-eoc-period']);
        } else {
          this.handleNonIncompleteStatusPostCustRes(res);
        }
      }, 
      (error: any) => {
        this.handleSaveProposalDataError(error);
      });
    }    
  }

  handleNonIncompleteStatusPostCustRes(res: any) {
    this.postCustomersComments();
    if(res.proposal?.decision?.decision_message !== null && res.proposal?.decision?.decision_message.includes('PENDING')){
      this.getDecision(res.proposal.proposal_id, res.palis_customer_id, this.vehicleTypeCheck)
    } else {
      this.routingResponse(res.proposal?.decision);
    } 
  }

  handleSaveProposalDataError(error: any) {
    this.hideLoader();
    if(error.status === 400) {
      const errorCodes = error?.error?.map((e: any) => {
        return e.code
      })
      this.errorCode = errorCodes.join(', ');
      this.showError(this.brGetdetailsItem.balloonRefinanceGetdetailsTitle.payloadErr);
      this.scrollToErrorMessage();
    } else if ((error.status === 500) || (error.status === 404)) {
      this.datastore.setErrorBrServer(true);
      this.router.navigate(['/balloon-refinance-getdetails/connection-failure']);
    } else {
      this.router.navigate(['/balloon-refinance-getdetails/connection-failure']);;
    }
  }

  postProposalData() {
    this.proposalData.proposal_id = this.creditCheckData.proposal.proposal_id;
    this.proposalData.palisCustomerId = this.creditCheckData.palis_customer_id;
    this.proposalData.palis_proposal_number = this.creditCheckData?.proposal?.decision.proposal_number;
    this.proposalData.proposal_status = this.creditCheckData?.proposal?.decision?.decision_status === proposalStatus.incomplete ? proposalStatus.incomplete : this.decisionData?.decision_status;
    this.balloonRefinanceSrv.postProposalDetails(this.proposalData).subscribe((res: any) => {
      this.proposalData = res;
    })
  }

  getDecision = (proposalId: any, palisCustomerId: any, vehicleType: string) => {
    this.balloonRefinanceSrv.getDecision(proposalId, palisCustomerId, vehicleType).pipe(takeUntil(this.destroy$)).subscribe((res:any) => {
      this.decisionData = res;
      this.datastore.setDecisionData(res);
      if(this.timerCounter < 3 && res.decision_status === 'REFERRED' && res.decision_message.includes('PENDING')) {
        this.setTimer(this.timer[this.timerCounter], proposalId, palisCustomerId, this.vehicleTypeCheck);
        this.timerCounter++;
      } else {
        let payload = this.curmCommentsBuilder(this.decisionData?.decision_status)
        this.balloonRefinanceSrv.postCustomerComments(payload).pipe(takeUntil(this.destroy$)).subscribe();
        this.postProposalData();
        this.routingResponse(res);
      }
    }, 
    (error: any) => {
    });
  }

  setTimer(timer: number, proposalId: any, palisCustomerId: any, vehicleType: string) {
    setTimeout(() => {
      this.getDecision(proposalId, palisCustomerId, vehicleType);
    }, timer);
  }

  routingResponse(res: any){
    this.hideLoader();
    if(res.decision_status === 'ACCEPTED') {
      this.router.navigate(['/balloon-refinance-getdetails/accept']);
    } else if(res.decision_status === 'DECLINED') {
      this.router.navigate(['/balloon-refinance-getdetails/decline']);
    } else if(res.decision_status === 'REFERRED') {
      this.router.navigate(['/balloon-refinance-getdetails/referral']);
    }
  }

  setupAddress(setAddress: addressSummaryList, additionalAddress:any) {
    setAddress.address_number = additionalAddress.address_detail?.id;
    setAddress.house_name = additionalAddress.address_detail?.house_name;
    setAddress.house_number = additionalAddress.address_detail?.house_number;
    setAddress.flat_number = additionalAddress.address_detail?.flat_number;
    setAddress.street = additionalAddress.address_detail?.street_name;
    setAddress.district = additionalAddress.address_detail?.district;
    setAddress.town = additionalAddress.address_detail?.post_town;
    setAddress.postcode = additionalAddress.address_detail?.post_code?.replace(/\s/g, "");
    setAddress.county = additionalAddress.address_detail?.county;
    setAddress.overseas = additionalAddress.address_detail?.overseas_address;
    setAddress.months_at_address = additionalAddress?.months_at_address;
    setAddress.years_at_address = additionalAddress?.years_at_address;
  }

  setAdditionalAddress(addressAddr: any) {
    if(addressAddr.length > 0){
      for (let q = 0; q < addressAddr.length; q++) { 
        let appAddressTemp = new addressSummaryClass(); 
        this.setupAddress(appAddressTemp, addressAddr[q]);
        this.addressSummaryData[q] = appAddressTemp;
      }
    }   
  }

  getPayloadForProposal() {
    this.setRequiredAddressFieldsForProposal(this.addressSummaryData)
    return {
      customer_type: this.customerDetails.customer_type,
      existing_agreement_number: null,
      individual: this.setCustomerDetails(),
      address: this.addressSummaryData,
      employment: this.setEmploymentData(),
      marketing: this.setMarkettingData(),
      proposal: {
        callback_url: this.callbackUrlText ? this.callbackUrlText : this.defaultCallbackUrl,
        customer_permission_given: "true",
        financials: this.setFinancialData(),
        affordability: this.setAffordability(),
        addons: this.setAddons(),
        asset: this.setAssets(),
        decision: null,
        terms: null,
        not_taken_up: null,
        bank_details: this.setBankDetails(),
        agreements_available_to_contra: null
      }
    }
  }

  setCustomerDetails() {
    let customerDetails =  {
      contact_number_alternative: this.customerDetails.contact_details.work_phone_number ? this.customerDetails.contact_details.work_phone_number : this.customerDetails.contact_details.home_phone_number,
      number_of_dependants: this.proposalData.no_of_dependants,
      marital_status: this.proposalData.marital,
      title: this.customerDetails.individual.title,
      first_name: this.customerDetails.individual.first_name,
      middle_initial: this.customerDetails.individual.middle_initials,
      surname: this.customerDetails.individual.surname,
      date_of_birth: this.dateOfBirth,
      contact_number: this.customerDetails.contact_details.mobile_phone_number,
      email_address:this.datastore.getLoggedInEmail(),
      gender: this.getGender(this.customerDetails.individual.gender),
      residential_status: this.getResidentialData(this.customerDetails.individual.residential_status),
      nationality: this.proposalData.nationality
    };

    if(!this.customerDetails.contact_details.mobile_phone_number && !this.customerDetails.contact_details.home_phone_number && !this.customerDetails.contact_details.work_phone_number) {
      customerDetails.contact_number_alternative = "01333720727"
    }

    return customerDetails;
  }

  setBankDetails() {
    return {
      account_name: this.proposalData.bank_account_name,
      sort_code: this.proposalData.bank_sort_code,
      account_no: this.proposalData.bank_account_number,
      years_with_bank: this.proposalData.years_with_bank,
      months_with_bank: this.proposalData.months_with_bank,
      bank: null,
      branch: null
    }
  }

  setAssets() {
    return {
      goods_code: this.getGoodsCode(),
      registration_number: this.accountDetails.registration_no,
      business_use: "false",
      vehicle_mileage: this.proposalData.mileage?.toString(),
      registration_month: this.accountDetails.registration_month,
      registration_year: this.accountDetails.registration_year,
      new_vehicle: "false",
      cap_code: this.capCode,
      hpi: "",
      make: "",
      model: "",
      description: "",
      model_year: "",
      goods_identification: "",
      vehicle_use: this.proposalData.vehicle_use
    }
  }

  setAddons() {
    return {
        warranty: "0.00",
        guaranteed_asset_protection: "0.00",
        tyre_insurance: "0.00",
        breakdown_assistance: "0.00",
        service_plan: "0.00",
        cosmetic_protection: "0.00",
        leisure_non_tangibles: "0.00"
    }
  }

  setAffordability() {
    return {
      replace_existing_agreement: "true",
      gross_annual_income: this.proposalData.gross_annual_income?.toString(),
      mortgage_or_rent_contribution: this.proposalData.mortgage_rental_expenditure?.toString(),
      other_expenditure: this.proposalData.other_expenditure?.toString(),
      circumstantial_change:this.proposalData.change_in_circumstances?.toString()
    }

  }

  setFinancialData() {
    return {
      product: 'HP',
      plan: this.datastore.getVehicleType()?.bikeFlag ? this.dealerplanService.getDealerPlan().bikeDealer : this.dealerplanService.getDealerPlan().carDealer,
      cash_price: this.refinanceAmount,
      deposit: {
        total_deposit: null,
          manufacturer_deposit: "0.00",
          dealer_deposit: "0.00",
          part_exchange_deposit: "0.00",
          bank_transfer_deposit: "0.00",
          card_deposit: "0.00",
          cheque_deposit: "0.00",
          cash_deposit: "0.00",
          electric_vehicle_grant_deposit: null
      },
      deferred_payment: "0.00",
      max_annual_mileage: "",
      term: this.proposalData.proposed_term?.toString(),
      rate: this.apr,
      monthly_payment_amount: this.accountDetails.monthly_payment_amount,
      distance_marketed: "true",
      equalised_calculation: "false",
      part_exchange_shortfall: "0.00"
    }
  }

  setMarkettingData() {
    return {
      email: "true",
      phone: "false",
      sms: "false",
      mail: "false"
    }
  }

  setEmployerStreet() {
    let street = this.proposalData?.street;
    if (!street && this.proposalData?.house_name) {
      street = this.proposalData?.house_name;
    } else if (!street && !this.proposalData?.house_name && this.proposalData?.town) {
      street = this.proposalData?.town
    }
    return street
  }

  setEmployerAddress() {
    let employAddress = {
      address_number: "0",
      house_name: this.proposalData?.house_name,
      house_number: this.proposalData?.house_number,
      flat_number: this.proposalData?.flat_number,
      street: this.setEmployerStreet(),
      district: this.proposalData?.district,
      town: this.proposalData?.town,
      postcode: this.proposalData.postcode?.replace(/\s/g,""),
      county: this.proposalData?.county,
      overseas: "false"
    }
    this.replaceAmpersandWithAnd(employAddress);
    return employAddress;
  }

  setEmploymentData() {
    const employType = (this.proposalData?.employment_type || this.proposalData?.employment_type === 0) ? this.proposalData.employment_type : this.datastore.getUnemployedStatus();
    if (!appConfig.unemployedTypes.includes(employType as number)) {
      return {
        permanent_employee: this.proposalData.perm_employee?.toString(),
        full_time: this.proposalData.full_time?.toString(),
        employment_type: employType,
        occupation: this.proposalData.occupation,
        employer_name: this.proposalData.employer_name,
        years_with_employer: this.proposalData.years_at_employment,
        months_with_employer: this.proposalData.months_at_employment,
        use_company_name: this.proposalData.employment_type === 2 ? 'false' : '',
        employer_address: this.setEmployerAddress(),
        use_employer_address: this.proposalData.employment_type === 2 ? 'false' : '',
        vat_registered: this.proposalData.employment_type === 2 ? 'false' : ''
      }
    } else {
      return {
        permanent_employee: false,
        full_time: false,
        employment_type: employType,
        occupation: this.proposalData.occupation,
        years_with_employer: 0,
        //setting months to 0 receives an error response
        months_with_employer: 1
      }
    }
  }

  getGender(gender: string) {
    let gen;
    switch (gender) {
      case 'M':
        gen = "0"
        break;
      case 'F':
        gen = "1"
        break;
      case 'U':
        gen = "2"
        break;
      default:
        gen = "2"
        break;
    }
    return gen;
  }

  getResidentialData(status: string) {
    let residentialStatus;
    switch (status) {
      case 'H':
        residentialStatus = "0"
        break;
      case 'T':
        residentialStatus = "1"
        break;
      case 'L':
        residentialStatus = "2"
        break;
      default:
        residentialStatus = "3"
        break;
    }
    return residentialStatus;
  }

  onSaveButtonClick(e: any) {
    this.router.navigate(['myaccount']);
  }

  onContinue(e: any, modalContent: any) {
    const isOutOfHours = new Date().getHours() >= 22 || new Date().getHours() < 8;
    if(this.agreementConfirmation) {
      this.showCheckboxError = false;
      if (this.proposalData?.proposal_status) {
        this.router.navigate(['/myaccount']);
      } else if (isOutOfHours) {
        this.router.navigate(['/balloon-refinance/cta-out-of-hours']);
      } else if (this.isOptionSelected || moment().isAfter(this.lastDateOfDecision)) {
        this.router.navigate(['/balloon-refinance/cta-expired-eoc-period']);
      } else {
        this.saveProposalData(modalContent);
      }
    } else {
      this.showCheckboxError = true;
    }
  }

  postCustomersComments() {
    const payload = {
      comments: [
        { Remark: this.accountDetails.account_number + " " + curmMemoText.webservBR },
        { Remark: curmMemoText.proposalNo + this.creditCheckData?.proposal?.decision?.proposal_number },
        { Remark: curmMemoText.created + this.creditCheckData?.proposal?.decision?.last_updated.split(" ")[0]}
      ]
    }
    this.balloonRefinanceSrv.postCustomerComments(payload).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.serviceError = false;
    }, 
    (error: any) => {
      this.showError();
    });
  }

  curmCommentsBuilder(decision: string): any {
    let payload = { 
      customer_id: this.datastore.getCustomerId(),
      comments: [
        { Remark : this.accountDetails.account_number + " " + curmMemoText.webservBR },
        { Remark : curmMemoText.date + this.decisionData?.last_updated }
      ]
    }
    let curmMemoDecisionText;
    if (decision === proposalStatus.accepted) {
      curmMemoDecisionText = curmMemoText.lendingApproved;
    } else if (decision === proposalStatus.referred) {
      curmMemoDecisionText = curmMemoText.lendingReferred;
    } else if (decision === proposalStatus.declined) {
      curmMemoDecisionText = curmMemoText.lendingDeclined;
    } else {
      curmMemoDecisionText = ''
    }
    payload.comments.splice(1, 0, { Remark : curmMemoDecisionText });
    return payload;
  }
  
  showLoader(modalContent: any) {
    document.getElementById("appLoader")!.style.visibility = 'hidden';
    document.getElementsByTagName('body')[0].style.opacity = '1';    
    this.modalService.open(modalContent, { centered: true, windowClass: 'account-summary-modal' });
  }

  hideLoader() {
    this.modalService.dismissAll();
  }

  getModagData() {
    this.brgService.caculateModagQuote(null, this.proposalData?.proposed_term).subscribe((res: any) => {
      if(res) {
        this.modagData = res;
        this.apr = this.modagData.modag_quote_response.modag_quotes[0].APR;        
        this.monthlyPayment = this.modagData.modag_quote_response.modag_quotes[0].new_rental_sets[0].new_rental_value;
        this.refinanceAmount = parseFloat(this.modagData?.modag_quote_response?.outstanding_Balance) + 
        parseFloat(this.modagData?.modag_quote_response?.charges_Rebate);        
        this.totalInterest = this.modagData?.modag_quote_response?.new_Charges;
        this.totalPayableAmt = this.modagData?.modag_quote_response?.modag_quotes[0]?.total_Payable;       
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  setRequiredAddressFieldsForProposal(additionalAddresses: Array<addressSummaryClass>) {
      additionalAddresses.forEach((address_detail: addressSummaryClass) => {
        this.replaceAmpersandWithAnd(address_detail);
        if (isNotEmpOrNullOrUndefOrWhiteSpace(address_detail?.street) && /\d/.test(address_detail?.street)) {
          const houseDetails = this.getHouseDetailsFromStreetName(address_detail?.street);
          address_detail.street = houseDetails?.street_name;
          address_detail.house_number = houseDetails?.house_number;
        }
        if (isEmpOrNullOrUndefOrWhiteSpace(address_detail?.house_name) && isEmpOrNullOrUndefOrWhiteSpace(address_detail?.house_number) && isNotEmpOrNullOrUndefOrWhiteSpace(address_detail?.street)) {
          this.setHouseNameFromStreet(address_detail);
        }
        if (isEmpOrNullOrUndefOrWhiteSpace(address_detail?.town) && isNotEmpOrNullOrUndefOrWhiteSpace(address_detail?.district)) {
          this.setTownAndDistrict(address_detail);
        }
        if (isEmpOrNullOrUndefOrWhiteSpace(address_detail?.town) && isEmpOrNullOrUndefOrWhiteSpace(address_detail?.district) && isNotEmpOrNullOrUndefOrWhiteSpace(address_detail?.county)) {
          address_detail.town = address_detail.county;
          address_detail.county = '';
        }
        if (isEmpOrNullOrUndefOrWhiteSpace(address_detail?.town) && isEmpOrNullOrUndefOrWhiteSpace(address_detail?.district) && isEmpOrNullOrUndefOrWhiteSpace(address_detail?.county) &&
          isNotEmpOrNullOrUndefOrWhiteSpace(address_detail?.street)) {
            this.setTown(address_detail);
        }
        if (isNotEmpOrNullOrUndefOrWhiteSpace(address_detail?.house_name && address_detail?.house_name.length > 20)) {
          this.setStreetNameFromHouseName(address_detail);
        }
        if (isNotEmpOrNullOrUndefOrWhiteSpace(address_detail?.house_name) && isEmpOrNullOrUndefOrWhiteSpace(address_detail?.street)) {
          address_detail.street = address_detail.house_name;
        }
        if (isNotEmpOrNullOrUndefOrWhiteSpace(address_detail?.county) && address_detail.county.length > 16) {
          this.splitCountySetCountyAndDistrict(address_detail);
        }
    });
  }

  setHouseNameFromStreet(addressDetail: addressSummaryClass) {
    if (addressDetail.street.length > 20) {
      let splitStreetName = this.splitStringToCharLimitWholeWords(addressDetail?.street, 20)
      addressDetail.house_name = splitStreetName.firstString.trim();
    } else {
      addressDetail.house_name = addressDetail.street;
    }
  }

  setTownAndDistrict(addressDetail: addressSummaryClass) {
    if (addressDetail?.district.length > 24) {
      let splitDistrict = this.splitStringToCharLimitWholeWords(addressDetail?.district, 24)
      addressDetail.town = splitDistrict.firstString.trim();
      addressDetail.district = splitDistrict.secondString.trim();
    } else {
      addressDetail.town = addressDetail.district;
      addressDetail.district = '';
    }
  }

  setTown(addressDetail: addressSummaryClass) {
    if (addressDetail.street.length > 24) {
      let splitStreetName = this.splitStringToCharLimitWholeWords(addressDetail?.street, 24);
      addressDetail.town = splitStreetName.firstString.trim();
    } else {
      addressDetail.town = addressDetail.street;
    }
  }
  setStreetNameFromHouseName(addressDetail: addressSummaryClass) {
    let splitHouseName = this.splitStringToCharLimitWholeWords(addressDetail?.house_name, 20);
    let concatHouseNameOver20CharsToStartStreetName = splitHouseName.secondString + addressDetail?.street;
    addressDetail.house_name = splitHouseName.firstString;
    if (concatHouseNameOver20CharsToStartStreetName.length < 40) {
      addressDetail.street = concatHouseNameOver20CharsToStartStreetName;
    }
  }

  splitCountySetCountyAndDistrict(addressDetail: addressSummaryClass) {
    let splitCounty = this.splitStringToCharLimitWholeWords(addressDetail?.county, 16);
    addressDetail.county = splitCounty.firstString.trim();
    if(isEmpOrNullOrUndefOrWhiteSpace(addressDetail?.district) ) {
      addressDetail.district = splitCounty.secondString.trim()
    }
  }
  replaceAmpersandWithAnd(obj: any) {
    for (const key in obj) {
      if (obj[key] !== null && typeof obj[key] === 'string' && obj[key].includes('&')) {
        obj[key] = obj[key].replace(/&/g, 'and');
      }
    }
    return obj
  }

  getHouseDetailsFromStreetName(streetName: any) {
    let houseDetails = {
      street_name: '',
      house_number: ''
    }
    if(/\d/.test(streetName)){
      let str = streetName.split(' ');
      houseDetails.house_number = streetName.split(' ')[0];
      str.shift();
      str.map((el: any) => {
        houseDetails.street_name += el + " ";
      });
    }
    return houseDetails;
  }

  splitStringToCharLimitWholeWords(inintalString: string, charSplit: number) {
    let stringParts = {
      firstString: '',
      secondString: ''
    }
    let words = inintalString.split(" ");
    
    for (let i=0; i < words.length; i++) {
      if (stringParts.firstString.length + words[i].length <= charSplit) {
        stringParts.firstString += words[i] + " ";
      } else {
        for (let j=i; j < words.length; j++) {
          stringParts.secondString += words[j] + " ";
        }
        break;
      }
    }
    return stringParts;
  }
  scrollToErrorMessage() {
    document.getElementById('bannerMsgId')?.scrollIntoView({ behavior: 'smooth' })
  }

}
