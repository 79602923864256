import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { appConfig, creditcheckPlansDev, creditcheckPlansQa, creditcheckPlansProd } from '@wss/config/wss-app-constants';
import { DatastoreService } from '@wss/service/datastore.service';
import { Title } from '@angular/platform-browser';
import { getCompanyTitle } from '@wss/common/util/header-util';
import { TealiumUtagService } from '@wss/service/utag.service';
import { DealerplanService } from '@wss/service/dealerplan.service';


@Component({
  selector: 'bb-wss-app',
  template: '<bb-root></bb-root>',
})
export class AppComponent implements OnInit, OnDestroy {

  title: string = 'Page Title';
  urlLoc = window.location.href;

  constructor(
    private bnIdle: BnNgIdleService,
    private datastoreService: DatastoreService,
    private titleService: Title,
    private tealiumService: TealiumUtagService,
    private dealerplanService: DealerplanService,
    private renderer: Renderer2
  ) {
    this.setTealiumConfig();
    this.setDealerPlans();
    this.renderer.addClass(document.body, 'page-loaded');
  }

  ngOnInit(): void {
    if (this.urlLoc.indexOf(appConfig.welcomeURL) === -1) {
      this.setTitle();
    }
    if ((this.urlLoc.indexOf(appConfig.loginURL) === -1) && (this.urlLoc.indexOf(appConfig.regURL) === -1)
      && (this.urlLoc.indexOf(appConfig.welcomeURL) === -1)) {
        this.bnIdle.startWatching(appConfig.appTimeout).subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          this.datastoreService.sessionLogout();
        }
      });
    } else {
      if (localStorage.getItem('loggedIn') != 'true') {
        this.datastoreService.sessionLogin();
      }
    }
  }

  public setTitle() {
    this.title = getCompanyTitle();
    this.titleService.setTitle(this.title);
  }

  setTealiumConfig() {
    let loc = window.location.href;
    let envName = '';
    if (loc.indexOf(appConfig.envPhrase.dev) !== -1) {
      envName = appConfig.envName.dev;
    } else if (loc.indexOf(appConfig.envPhrase.sit) !== -1) {
      envName = appConfig.envName.sit;
    } else if (loc.indexOf(appConfig.envPhrase.preprod) !== -1) {
      envName = appConfig.envName.preprod;
    } else {
      envName = appConfig.envName.prod;
    }
    this.tealiumService.setConfig({ account: appConfig.tealiumAccount, profile: appConfig.tealiumProfile, environment: envName });
  }

  setDealerPlans() {
    let loc = window.location.href;
    let envName = '';
    if (loc.indexOf(appConfig.envPhrase.dev) !== -1) {
      this.dealerplanService.setDealerPlan(creditcheckPlansDev.carDealer, creditcheckPlansDev.bikeDealer);
      envName = appConfig.envName.dev;
    } else if ((loc.indexOf(appConfig.envPhrase.sit) !== -1) || (loc.indexOf('localhost') !== -1)) {
      this.dealerplanService.setDealerPlan(creditcheckPlansDev.carDealer, creditcheckPlansDev.bikeDealer);
    } else if (loc.indexOf(appConfig.envPhrase.preprod) !== -1) {
      this.dealerplanService.setDealerPlan(creditcheckPlansQa.carDealer, creditcheckPlansQa.bikeDealer);
    } else {
      this.dealerplanService.setDealerPlan(creditcheckPlansProd.carDealer, creditcheckPlansProd.bikeDealer);
    }
  }

  ngOnDestroy(): void {
    localStorage.setItem('loggedIn', 'false');
    localStorage.removeItem('loggedInEmail');
    this.renderer.removeClass(document.body, 'page-loaded');
  }
}
