<section id="br-getdetails-about" *ngIf="balloonRefinanceGetdetailsAboutItem$ | async as brGetdetailsAboutItem">
    <div role="alert" *ngIf="serviceError">
        <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
    </div>
    <wss-page-title [pageTitle]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.page2Title"
        [pageName]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.step2Title">
    </wss-page-title>
    <section class="content-width-max">
        <div class="mt-25px-mb-30px" test_id="braTextAfterPageTitle">
            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.textAfterPageTitle">
            </bb-content-html>
        </div>
        <form [formGroup]="aboutDetailsForm">
            <div class="card mb-10px p-20px">
                <div class="card-content text-left mb-n5px section-sub-title" test_id="braSectionPersonalTitle">                    
                    {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.sectionPersonalTitle}}             
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[0]" [idCollapse]="idCollapseArr[0]"
                    [idInforPanel]="idPanelArr[0]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblTitle"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInformationTitle">
                </wss-infor-tool>
                <div class="mt-15px">
                    <input type="text" class="form-control wss-form-input-small mb-0" id="title"
                        formControlName="title" readonly tabindex="-1" aria-label="Title">  
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[1]" [idCollapse]="idCollapseArr[1]"
                    [idInforPanel]="idPanelArr[1]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblFirstName"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInformationFirstName">
                </wss-infor-tool>
                <div class="mt-15px">
                    <input type="text" class="form-control wss-form-input-normal mb-0" id="firstName"
                        formControlName="firstName" readonly tabindex="-1" aria-label="Firstname">
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[2]" [idCollapse]="idCollapseArr[2]"
                    [idInforPanel]="idPanelArr[2]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblSurname"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInformationSurname">
                </wss-infor-tool>
                <div class="mt-15px">
                    <input type="text" class="form-control wss-form-input-normal mb-0" id="surname"
                        formControlName="surname" readonly tabindex="-1" aria-label="Surname">
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[3]" [idCollapse]="idCollapseArr[3]"
                    [idInforPanel]="idPanelArr[3]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblDOB"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInformationDOB">
                </wss-infor-tool>
                <div class="mt-15px ml-0 row wss-dob">
                    <div class="mr-20px">
                        <label class="mb-0">{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblDayOfBirth}}</label>
                        <input type="text" class="form-control mr-20px mt-8px mb-0" id="dayOfBirth"
                            formControlName="dayOfBirth" readonly tabindex="-1" aria-label="Day of birth">
                    </div>
                    <div class="mr-20px">
                        <label class="mb-0">{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblMonthOfBirth}}</label>
                        <input type="text" class="form-control mr-20px mt-8px mb-0" id="monthOfBirth"
                            formControlName="monthOfBirth" readonly tabindex="-1" aria-label="Month of birth">
                    </div>
                    <div class="mr-20px">
                        <label class="mb-0">{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblYearOfBirth}}</label>
                        <input type="text" class="form-control mr-20px mt-8px mb-0 year-disable" id="yearOfBirth"
                            formControlName="yearOfBirth" readonly tabindex="-1" aria-label="Year of birth">
                    </div>
                </div>
                <div class="mt-25px pb-25px br-btm-grey">
                    {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.getInTouch}} <a href="javascript:void(0)" (click)="toGetinTouch()" id="wss-Brg-About-personal-Gettouch-Link">{{brGetdetailsAboutItem.balloonRefinanceGetdetailsVehicle.linkGetInTouch}}</a>  
                </div>
                <div class="card-content text-left mt-25px mb-n5px section-sub-title">                    
                    {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.sectionExtraTitle}}                 
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[4]" [idCollapse]="idCollapseArr[4]"
                    [idInforPanel]="idPanelArr[4]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblNationality"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInformationNationality">
                </wss-infor-tool>
                <div class="col-md-4 p-0 mt-15px">
                    <select class="form-control w-100" formControlName="nationality" aria-label="Nationality">
                        <option value="null" disabled selected hidden>
                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.placeholderNationality}}</option>
                        <option *ngFor="let item of nationalities" [value]="item.value">{{item.title}}</option>
                    </select>
                </div>
                <div class="mt-5px text-danger" test_id="braNationalityError"
                    *ngIf="this.submitted && aboutDetailsForm.controls.nationality.status === 'INVALID'">
                    <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.nationalityError">
                    </bb-content-html>
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[5]" [idCollapse]="idCollapseArr[5]"
                    [idInforPanel]="idPanelArr[5]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblMarital"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInformationMarital">
                </wss-infor-tool>
                <div class="col-md-4 p-0 mt-15px">
                    <select class="form-control w-100" formControlName="marital" aria-label="Marital status">
                        <option value="null" disabled selected hidden>
                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.placeholderMarital}}</option>
                        <option *ngFor="let item of maritalStatus" [value]="item.value">{{item.title}}</option>
                    </select>
                </div>
                <div class="mt-5px text-danger" test_id="braMaritalError"
                    *ngIf="this.submitted && aboutDetailsForm.controls.marital.status === 'INVALID'">
                    <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.maritalError">
                    </bb-content-html>
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[6]" [idCollapse]="idCollapseArr[6]"
                    [idInforPanel]="idPanelArr[6]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblDependants"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInformationDependants">
                </wss-infor-tool>
                <div class="wss-summary-text">
                    <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.summaryDependants">
                    </bb-content-html>
                </div>
                <div class="col-md-4 p-0 mt-15px">
                    <select class="form-control w-100" formControlName="dependants" aria-label="Number of dependants">
                        <option value="null" disabled selected hidden>
                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.placeholderDependants}}</option>
                        <option *ngFor="let item of numberOfDependants" [value]="item">{{item}}</option>
                    </select>
                </div>
                <div class="mt-5px text-danger" test_id="braDependantsError"
                    *ngIf="this.submitted && aboutDetailsForm.controls.dependants.status === 'INVALID'">
                    <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.dependantsError">
                    </bb-content-html>
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[7]" [idCollapse]="idCollapseArr[7]"
                    [idInforPanel]="idPanelArr[7]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblResidential"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInformationResidential">
                </wss-infor-tool>
                <div class="col-md-4 mt-15px plr-0x">
                    <select class="form-control w-100" formControlName="residential" aria-label="Residential status">
                        <option value="null" disabled selected hidden>
                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.placeholderResidential}}</option>
                        <option *ngFor="let item of residentialStatus" [value]="item.value">{{item.title}}</option>
                    </select>
                </div>
                <div class="mt-5px text-danger" test_id="braResidentialError"
                    *ngIf="this.submitted && aboutDetailsForm.controls.residential.status === 'INVALID'">
                    <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.residentialError">
                    </bb-content-html>
                </div>


                <div class="card-content text-left pt-30px mb-n5px br-tp-grey section-sub-title mt-40px" test_id="braExtraDetailsTitle">                    
                    {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.addressDetailsTitle}}                    
                </div>
                <div class="p-0 mt-15px" test_id="textAfterAddressTitle" id="textAfterAddressTitle">
                    <bb-content-html
                        [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.textAfterAddressTitle">
                    </bb-content-html>
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[8]" [idCollapse]="idCollapseArr[8]"
                    [idInforPanel]="idPanelArr[8]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblCurrentAddress"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInforCurrentAddress">
                </wss-infor-tool>
                <ng-container *ngIf="this.currentAddress">
                    <wss-address-display [addressContent]="this.currentAddress.address_detail" [displayClass]="classLiveAddress">
                    </wss-address-display>
                </ng-container>
                <wss-infor-tool [idExpand]="idExpandArr[9]" [idCollapse]="idCollapseArr[9]"
                    [idInforPanel]="idPanelArr[9]"
                    [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblLiveAddress"
                    [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInforLiveAddress">
                </wss-infor-tool>
                <div class="mt-15px">
                    <div class="d-flex" *ngFor="let item of yesNo">
                        <input type="radio" class="mr-2" name="liveAddressOpt" [value]="item.value" [id]="'wss-brg-still-live-at-address-radio-' + item.id"
                            formControlName="liveAddressOpt" (change)="handleLiveAddress(item.value)" aria-label="Still live at address">
                        <label [for]="item" class="text-regular">{{item.title}}</label>
                    </div>
                </div>
                <div class="mt-5px text-danger" test_id="braLiveAddressError"
                    *ngIf="this.submitted && aboutDetailsForm.controls.liveAddressOpt.status === 'INVALID'">
                    <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.pleaseSelect">
                    </bb-content-html>
                </div>

                <ng-container *ngIf="aboutDetailsForm.controls.liveAddressOpt.value === 'yes'">
                    <wss-infor-tool 
                        [idExpand]="idExpandArr[10]" 
                        [idCollapse]="idCollapseArr[10]"
                        [idInforPanel]="idPanelArr[10]"
                        [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblMoveLive"
                        [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                        [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInforMoveLive">
                    </wss-infor-tool>
                    <div class="mt-20px flex-r">
                        <div>
                            <label>{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblMonthOfBirth}}</label>
                            <select id="liveAddressMonth" class="form-control w-100 month-select" placeholder="" formControlName="liveAddressMonth" 
                            (change)="checkDate('liveAddressMonth','liveAddressYear',0)" aria-label="Months at address">
                                <option selected value="">
                                    {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.monthDefaultText}}
                                </option>
                                <option *ngFor="let month of monthsData" [value]="month.value">
                                    {{month.title}}
                                </option>
                            </select> 
                            <div class="mt-5px text-danger" test_id="moveLiveMonthError" *ngIf="(this.submitted) && (aboutDetailsForm.controls.liveAddressMonth.status === 'INVALID')">
                                <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.moveLiveMonthError">
                                </bb-content-html>
                            </div>                           
                        </div>
                        <div class="div-year-select">
                            <label>{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblYearOfBirth}}</label>
                            <select id="liveAddressYear" class="form-control w-100 year-select" placeholder="" formControlName="liveAddressYear" 
                            (change)="checkDate('liveAddressMonth','liveAddressYear',0)" aria-label="Years at address">
                                <option selected value="">
                                    {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.yearDefaultText}}
                                </option>
                                <option *ngFor="let year of yearsList" [value]="year">
                                    {{year}}
                                </option>
                            </select>
                            <div class="mt-5px text-danger" test_id="moveLiveYearError" *ngIf="(this.submitted) && (aboutDetailsForm.controls.liveAddressYear.status === 'INVALID')">
                                <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.moveLiveYearError">
                                </bb-content-html>
                            </div> 
                        </div>
                    </div> 
                    <div class="mt-5px text-danger" test_id="currentDateError" *ngIf="errorsLiveObj.compareCurrentDateErr">
                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.compareCurrentDateError">
                        </bb-content-html>                        
                    </div>  
                    <div class="mt-5px text-danger" test_id="currentDateDOBError" *ngIf="errorsCurrentObj.compareDOBDateErr">
                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.compareDOBDateErr">
                        </bb-content-html>                        
                    </div>                  
                </ng-container>

                <ng-container *ngIf="aboutDetailsForm.controls.liveAddressOpt.value === 'no'">
                    <wss-infor-tool 
                        [idExpand]="idExpandArr[12]" 
                        [idCollapse]="idCollapseArr[12]"
                        [idInforPanel]="idPanelArr[12]"
                        [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.currentNewAddressLbl"
                        [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                        [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInforCurrentNewAddress">
                    </wss-infor-tool>                                           
                    <div>
                        <div class="form-group mt-12px">
                            <label for="houseNameCurent" id="houseNameCurrent">
                                {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.houseNumber}}
                            </label>
                            <input type="text" class="form-control" required="required" formControlName="houseNameCurrent" name="houseNameCurrent" maxlength="20"
                            placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.houseNumberPlaceholderText}}" aria-label="House number">
                            <div class="mt-5px text-danger" id="houseNameCurrentErr" *ngIf="(showElmLiveObj.submitted || this.submitted) && aboutDetailsForm.controls.houseNameCurrent.status === 'INVALID'">                    
                                <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.buildingNameErrorMessage"></bb-content-html>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="postCodeCurent" id="postCodeCurent">
                                {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.postCode}}
                            </label>
                            <input type="text" class="form-control wss-form-input-normal" required="required" formControlName="postCodeCurent" name="postCodeCurent"
                            placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.postCodePlaceholderText}}" aria-label="Postcode">
                            <div class="mt-5px text-danger" id="postCodeCurentErr" *ngIf="showElmLiveObj.submitted && 
                            aboutDetailsForm.controls.postCodeCurent.status === 'INVALID' &&
                            aboutDetailsForm.controls.postCodeCurent.value !== ''">                    
                                <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.searchPostCoderrorMessage"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger"  id="postCodeCurentNoValErr" *ngIf="(showElmLiveObj.submitted || this.submitted) && 
                            aboutDetailsForm.controls.postCodeCurent.status === 'INVALID' && aboutDetailsForm.controls.postCodeCurent.value === ''">                    
                                <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.postCodeErrorMessage"></bb-content-html>
                            </div>
                        </div>
                        <div class="mt-5px text-danger" *ngIf="errorsCurrentObj.showPrevAddressErr" id="currentAddressPrevErr">
                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.prevAddressError"></bb-content-html>
                        </div>                        
                        <div class="mt-5px text-danger" *ngIf="errorsCurrentObj.showSearchAddressErr" id="currentAddressSearchErr">
                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.currentAddressError"></bb-content-html>
                        </div>
                        <button class="wss-button mb-0" id="searchCurrentBtn" (click)="searchAddressCurrent(0)">
                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.searchButtonText}}
                        </button>
                        <div class="mt-5px text-danger" test_id="searchAddressCurrentError" *ngIf="!aboutDetailsForm.controls.houseNameCurrent.pristine && !aboutDetailsForm.controls.postCodeCurent.pristine && this.submitted && !isAddressSearched">
                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.searchAddressButtonError"></bb-content-html>
                        </div>
                    </div>
                    <div class="mt-25px col-md-5 address-block" *ngIf="showElmCurrentObj.showaddressBlock">                                    
                        <wss-address-display 
                            [addressContent]="addressData[0].address_detail" 
                            [displayClass]="classLiveAddress">
                        </wss-address-display>
                    </div>
                    <div *ngIf="showElmCurrentObj.showaddressBlock">
                        <wss-infor-tool 
                            [idExpand]="idExpandArr[24]" 
                            [idCollapse]="idCollapseArr[24]"
                            [idInforPanel]="idPanelArr[24]"
                            [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblConfirmAddress"                                    
                            [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInforConfirmAddress">
                        </wss-infor-tool>
                        <div class="mt-15px">
                            <div class="d-flex" *ngFor="let item of yesNo">
                                <input type="radio" class="mr-2" name="confirmAddressOpt" [value]="item.value"
                                    formControlName="confirmAddressOpt" (change)="handleConfirmAddress(item.value)"
                                    [id]="'wss-brg-is-address-correct-radio-' + item.id" aria-label="Is address correct">
                                <label [for]="item" class="text-regular">{{item.title}}</label>
                            </div>
                        </div>
                        <div class="mt-5px text-danger" test_id="braConfAdrRadioError"
                            *ngIf="this.submitted && aboutDetailsForm.controls.confirmAddressOpt.status === 'INVALID'">
                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.pleaseSelect">
                            </bb-content-html>
                        </div>
                    </div>
                    <div *ngIf="(this.aboutDetailsForm.controls.confirmAddressOpt) && (this.aboutDetailsForm.controls.confirmAddressOpt.value === 'yes')">
                        <div class="d-flex r-flex mt-15px">
                            <div class="wss-icon_infor_small">                            
                            </div>
                            <div class="term-success ml-10x">
                                <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.InforAddressPost">
                                </bb-content-html>                                
                            </div>
                        </div>
                        <wss-infor-tool
                            [idExpand]="idExpandArr[25]" 
                            [idCollapse]="idCollapseArr[25]"
                            [idInforPanel]="idPanelArr[25]"
                            [showLblAddress]="showElmLiveObj.showLblAddress"
                            [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.moveAddressLbl"
                            [lblAddress]="addressData[0].address_detail"
                            [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInforMoveAddress">
                        </wss-infor-tool> 
                        <div class="mt-20px flex-r">
                            <div>
                                <label>{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblMonthOfBirth}}</label>
                                <select id="currentAddressMonth" class="form-control w-100 month-select" placeholder="" 
                                formControlName="currentAddressMonth" (change)="checkDate('currentAddressMonth','currentAddressYear',0)" aria-label="Months at address">
                                    <option selected value="">
                                        {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.monthDefaultText}}
                                    </option>
                                    <option *ngFor="let month of monthsData" [value]="month.value">
                                        {{month.title}}
                                    </option>
                                </select> 
                                <div class="mt-5px text-danger" test_id="moveCurrentMonthError" *ngIf="this.submitted && aboutDetailsForm.controls.currentAddressMonth.status === 'INVALID'">
                                    <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.moveLiveMonthError">
                                    </bb-content-html>
                                </div>                           
                            </div>
                            <div class="div-year-select">
                                <label>{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblYearOfBirth}}</label>
                                <select id="currentAddressYear" class="form-control w-100 year-select" placeholder="" 
                                formControlName="currentAddressYear" (change)="checkDate('currentAddressMonth','currentAddressYear',0)" aria-label="Years at address">
                                    <option selected value="">
                                        {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.yearDefaultText}}
                                    </option>
                                    <option *ngFor="let year of yearsList" [value]="year">
                                        {{year}}
                                    </option>
                                </select>
                                <div class="mt-5px text-danger" test_id="moveCurrentYearError" *ngIf="this.submitted && aboutDetailsForm.controls.currentAddressYear.status === 'INVALID'">
                                    <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.moveLiveYearError">
                                    </bb-content-html>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="mt-5px text-danger" test_id="currentDateError" *ngIf="errorsCurrentObj.compareCurrentDateErr">
                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.compareCurrentDateError">
                        </bb-content-html>                        
                    </div>
                    <div class="mt-5px text-danger" test_id="currentDateDOBError" *ngIf="errorsCurrentObj.compareDOBDateErr">
                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.compareDOBDateErr">
                        </bb-content-html>                        
                    </div>
                </ng-container>
                <ng-container *ngIf="showElmLiveObj.showHistory">                    
                    <div *ngFor="let numItem of dataModel.addNum; let i = index">
                        <div class="d-flex r-flex mt-30px">
                            <div class="icon_warning_circle">                            
                            </div>
                            <div class="term-success ml-10x">
                                {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.warningTextTerm}}
                            </div>
                        </div>
                        <div class="br-dashed"></div>
                        <ng-container *ngIf="((this.aboutDetailsForm.controls.liveAddressOpt) 
                        && (this.aboutDetailsForm.controls.liveAddressOpt.value === 'no')) 
                        && (showElmLiveObj.showCurConfirm[i])
                        && (!isReturnJourney)">                            
                            <wss-infor-tool 
                                [idExpand]="idExpandArr[15]+i" 
                                [idCollapse]="idCollapseArr[15]+i"
                                [idInforPanel]="idPanelArr[15]+i"
                                [showLblRvAddress]="showElmLiveObj.showLblAddress"
                                [lblWas]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblMoveWas"
                                [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblMoveCurrent"
                                [lblAddress]="customerDetails.addresses.address_detail"
                                [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                                [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInforMoveCurrent">
                            </wss-infor-tool>                           
                            <div class="mt-15px">
                                <div class="d-flex" *ngFor="let item of yesNo">
                                    <input type="radio" class="mr-2" [value]="item.value"
                                        formControlName="{{lblElemCurrentObj.confirmCurrent+i}}" 
                                        name="{{lblElemCurrentObj.confirmCurrent+i}}" 
                                        id="{{lblElemCurrentObj.confirmCurrent+i+item.value}}" 
                                        (change)="handleCurAddress(i, item.value)" aria-label="Is address correct">
                                    <label [for]="item" class="text-regular">{{item.title}}</label>
                                </div>
                            </div>
                            <div class="mt-5px text-danger" id="{{lblElemCurrentObj.confirmCurrentErr+i}}"
                                *ngIf="this.submitted && aboutDetailsForm.controls[lblElemCurrentObj.confirmCurrent+i].status === 'INVALID'">
                                <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.pleaseSelect">
                                </bb-content-html>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="((this.aboutDetailsForm.controls.liveAddressOpt.value === 'yes') 
                        || (!showElmLiveObj.showCurConfirm[i])
                        || ((this.aboutDetailsForm.controls[lblElemCurrentObj.confirmCurrent+i])
                        && (this.aboutDetailsForm.controls[lblElemCurrentObj.confirmCurrent+i].value === 'no'))
                        || (isReturnJourney))">                            
                            <wss-infor-tool 
                                [idExpand]="idExpandArr[11]+i" 
                                [idCollapse]="idCollapseArr[11]+i"
                                [idInforPanel]="idPanelArr[11]+i"
                                [showLblAddress]="showElmLiveObj.showLblAddress"
                                [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.prevAddressLbl"
                                [lblAddress]="addressData[i].address_detail"
                                [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                                [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInforPrevAddress">
                            </wss-infor-tool>               
                            <div *ngIf="showElmLiveObj.showAddressQuery[i]">
                                <div *ngIf="!showElmLiveObj.showFullAddress[i]" class="mt-12px">
                                    <div class="form-group">
                                        <label for="{{lblElemLiveObj.hnameSearch+i}}">
                                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.houseNumber}}
                                        </label>
                                        <input type="text" class="form-control" formControlName="{{lblElemLiveObj.hnameSearch+i}}" name="{{lblElemLiveObj.hnameSearch+i}}" id="{{lblElemLiveObj.hnameSearch+i}}" maxlength="20"
                                        placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.houseNumberPlaceholderText}}" aria-label="House number">
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.hnameSearchErr+i}}" *ngIf="(this.submitted || showElmLiveObj.submitted) && !showElmLiveObj.showaddressBlock[i] && aboutDetailsForm.controls[lblElemLiveObj.hnameSearch+i].status === 'INVALID'">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.houseNumberErrorMessage"></bb-content-html>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="{{lblElemLiveObj.postcodeSearch+i}}">
                                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.postCode}}
                                        </label>
                                        <input type="text" class="form-control wss-form-input-normal" formControlName="{{lblElemLiveObj.postcodeSearch+i}}" name="{{lblElemLiveObj.postcodeSearch+i}}" id="{{lblElemLiveObj.postcodeSearch+i}}"
                                        placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.postCodePlaceholderText}}" aria-label="Postcode">
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.searchPcodeErr+i}}" 
                                        *ngIf="showElmLiveObj.submitted && aboutDetailsForm.controls[lblElemLiveObj.postcodeSearch+i].status === 'INVALID' && aboutDetailsForm.controls[lblElemLiveObj.postcodeSearch+i].value !== ''">
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.searchPostCoderrorMessage"></bb-content-html>
                                        </div>
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.postcodeSearchErr+i}}" 
                                        *ngIf="(this.submitted || showElmLiveObj.submitted) && !showElmLiveObj.showaddressBlock[i] && aboutDetailsForm.controls[lblElemLiveObj.postcodeSearch+i].status === 'INVALID' && aboutDetailsForm.controls[lblElemLiveObj.postcodeSearch+i].value === ''">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.postCodeErrorMessage"></bb-content-html>
                                        </div>                    
                                    </div>
                                    <div class="mt-5px text-danger" *ngIf="errorsLiveObj.showSearchAddressErr[i]" id="{{lblElemLiveObj.searchAddressErr+i}}">
                                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.searchAddressErrorMessage"></bb-content-html>
                                    </div>    
                                    <button class="wss-button mb-0" id="{{lblElemLiveObj.searchBtn+i}}" (click)="searchAddress(i)">
                                        {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.searchButtonText}}
                                    </button>
                                    <div class="mt-5px text-danger"  [attr.test_id]="'notSubmittedAddressSearchErr' + i" *ngIf="!aboutDetailsForm.controls[lblElemLiveObj.hnameSearch+i].pristine && !aboutDetailsForm.controls[lblElemLiveObj.postcodeSearch+i].pristine && this.submitted && !showElmLiveObj.submitted && !showElmLiveObj.addressSearchSubmitted[i] ">
                                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.searchAddressButtonError"></bb-content-html>
                                    </div>
                                    <ng-container *ngIf="!showElmLiveObj.showFullAddress[i]">
                                        <a href="javascript:void(0)" class="d-block text-semibold mt-20px" id="{{lblElemLiveObj.enterFullAdrLink+i}}" 
                                        (click)="toggleSearchTypeHistory(i, lblElemLiveObj.addressMonth+i, lblElemLiveObj.addressYear+i, lblElemLiveObj, showElmLiveObj);">
                                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.manualAddress}}
                                        </a>  
                                    </ng-container>         
                                </div>
                                <ng-container *ngIf="showElmLiveObj.showFullAddress[i]">
                                    <a href="javascript:void(0)" class="d-block text-semibold mt-20px" id="{{lblElemLiveObj.searchByPcode+i}}" 
                                    (click)="toggleSearchTypeHistory(i, lblElemLiveObj.addressMonth+i, lblElemLiveObj.addressYear+i, lblElemLiveObj, showElmLiveObj);">
                                        {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.searchByPC}}
                                    </a>
                                </ng-container>
                                <div *ngIf="showElmLiveObj.showFullAddress[i]" class="mt-20px">
                                    <div class="form-group w-100">
                                        <div>
                                            <label for="{{lblElemLiveObj.houseName+i}}" [ngClass]="{'ng-invalid': aboutDetailsForm.errors?.atLeastOneRequired}">
                                                {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.buildingName}}
                                            </label>
                                            <input type="text" class="form-control wss-form-input-normal" formControlName="{{lblElemLiveObj.houseName+i}}" 
                                            id="{{lblElemLiveObj.houseName+i}}" (change)="checkFullAddress(i, lblElemLiveObj, 'multiple', showElmLiveObj, errorsLiveObj, 1)" maxlength="20"
                                            placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.buildingNamePlaceHolderText}}" aria-label="Bulding name or number">
                                        </div>
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.houseNameErr+i}}" 
                                        *ngIf="aboutDetailsForm.controls[lblElemLiveObj.houseName+i].status === 'INVALID' && aboutDetailsForm.controls[lblElemLiveObj.houseName+i].value === ''">       
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.buildingNameErrorMessage"></bb-content-html>
                                        </div>
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.houseNameErr+i}}InvalidChars" *ngIf="aboutDetailsForm.controls[lblElemLiveObj.houseName+i]?.errors?.pattern">            
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.alphanumericHypenApostropheError"></bb-content-html>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <label for="{{lblElemLiveObj.houseNumber+i}}">
                                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.houseNumber}}
                                        </label>
                                        <input type="text" class="form-control wss-form-input-normal" formControlName="{{lblElemLiveObj.houseNumber+i}}" 
                                        id="{{lblElemLiveObj.houseNumber+i}}" (change)="checkFullAddress(i, lblElemLiveObj, 'multiple', showElmLiveObj, errorsLiveObj, 1)" maxlength="5"
                                        placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.houseNumberPlaceholderText}}" aria-label="House number">                                        
                                    </div>
                                    <div class="mt-5px text-danger" id="{{lblElemLiveObj.houseNumberErr+i}}" 
                                    *ngIf="aboutDetailsForm.controls[lblElemLiveObj.houseName+i].status === 'INVALID' && aboutDetailsForm.controls[lblElemLiveObj.houseNumber+i].value === ''">       
                                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.houseNumberErrorMessage"></bb-content-html>
                                    </div>
                                    <div class="mt-5px text-danger" id="{{lblElemLiveObj.houseNumberErr+i}}InvalidChars" *ngIf="aboutDetailsForm.controls[lblElemLiveObj.houseNumber+i]?.errors?.pattern">            
                                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.alphanumericHypenForwardSlashError"></bb-content-html>
                                    </div>
                                    <div class="mt-5px text-danger" id="noHouseOrNumberErrorMessage{{i}}" *ngIf="this.submitted && aboutDetailsForm?.errors?.atLeastOneRequired">
                                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.noHouseNameOrHouseNumberErrorMessage"></bb-content-html>
                                    </div>
                                    <div class="form-group w-100">
                                        <label for="{{lblElemLiveObj.flatNumber+i}}">
                                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.flatNumber}}
                                        </label>
                                        <input type="text" class="form-control wss-form-input-normal" formControlName="{{lblElemLiveObj.flatNumber+i}}" 
                                        id="{{lblElemLiveObj.flatNumber+i}}" (change)="checkFullAddress(i, lblElemLiveObj, 'multiple', showElmLiveObj, errorsLiveObj, 1)" maxlength="16"
                                        placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.flatNumberPlaceholderText}}" aria-label="Flat number">
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.flatNumber+i}}InvalidChars" *ngIf="aboutDetailsForm.controls[lblElemLiveObj.flatNumber+i]?.errors?.pattern">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.alphanumericAndHypenError"></bb-content-html>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <label for="{{lblElemLiveObj.streetName+i}}">
                                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.street}}
                                        </label>
                                        <input type="text" class="form-control wss-form-input-normal" formControlName="{{lblElemLiveObj.streetName+i}}" 
                                        id="{{lblElemLiveObj.streetName+i}}" (change)="checkFullAddress(i, lblElemLiveObj, 'multiple', showElmLiveObj, errorsLiveObj, 1)" maxlength="40"
                                        placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.streetPlaceholderText}}" aria-label="Street name">
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.streetNameErr+i}}" 
                                        *ngIf="this.submitted && aboutDetailsForm.controls[lblElemLiveObj.streetName+i].status === 'INVALID' && !aboutDetailsForm.controls[lblElemLiveObj.streetName+i].value">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.streetErrorMessage"></bb-content-html>
                                        </div>
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.streetName+i}}InvalidChars" *ngIf="aboutDetailsForm.controls[lblElemLiveObj.streetName+i]?.errors?.pattern">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.alphabeticHypenApostropheError"></bb-content-html>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <label for="{{lblElemLiveObj.district+i}}">
                                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.district}}
                                        </label>
                                        <input type="text" class="form-control wss-form-input-normal" formControlName="{{lblElemLiveObj.district+i}}" 
                                        id="{{lblElemLiveObj.district+i}}" (change)="checkFullAddress(i, lblElemLiveObj, 'multiple', showElmLiveObj, errorsLiveObj, 1)" maxlength="40"
                                        placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.districtPlaceholderText}}" aria-label="District">
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.districtErr+i}}" 
                                        *ngIf="this.submitted && aboutDetailsForm.controls[lblElemLiveObj.district+i].status === 'INVALID' && !aboutDetailsForm.controls[lblElemLiveObj.district+i].value">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.districtErrorMessage"></bb-content-html>
                                        </div>
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.district+i}}InvalidChars" *ngIf="aboutDetailsForm.controls[lblElemLiveObj.district+i]?.errors?.pattern">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.alphabeticHypenApostropheError"></bb-content-html>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <label for="{{lblElemLiveObj.postTown+i}}">
                                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.town}}
                                        </label>
                                        <input type="text" class="form-control wss-form-input-normal" formControlName="{{lblElemLiveObj.postTown+i}}" 
                                        id="{{lblElemLiveObj.postTown+i}}" (change)="checkFullAddress(i, lblElemLiveObj, 'multiple', showElmLiveObj, errorsLiveObj, 1)" maxlength="30"
                                        placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.townPlaceholderText}}" aria-label="Town/City name">
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.postTownErr+i}}" 
                                        *ngIf="this.submitted && aboutDetailsForm.controls[lblElemLiveObj.postTown+i].status === 'INVALID' && !aboutDetailsForm.controls[lblElemLiveObj.postTown+i].value">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.townErrorMessage"></bb-content-html>
                                        </div>
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.postTown+i}}InvalidChars" *ngIf="aboutDetailsForm.controls[lblElemLiveObj.postTown+i]?.errors?.pattern">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.alphabeticHypenApostropheError"></bb-content-html>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <label for="{{lblElemLiveObj.county+i}}">
                                            {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.county}}
                                        </label>
                                        <input type="text" class="form-control wss-form-input-normal" formControlName="{{lblElemLiveObj.county+i}}" 
                                        id="{{lblElemLiveObj.county+i}}" (change)="checkFullAddress(i, lblElemLiveObj, 'multiple', showElmLiveObj, errorsLiveObj, 1)" maxlength="16"
                                        placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.countyPlaceholderText}}" aria-label="County">
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.countyErr+i}}" 
                                        *ngIf="this.submitted && aboutDetailsForm.controls[lblElemLiveObj.county+i].status === 'INVALID' && !aboutDetailsForm.controls[lblElemLiveObj.county+i].value">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.countyErrorMessage"></bb-content-html>
                                        </div>
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.county+i}}InvalidChars" *ngIf="aboutDetailsForm.controls[lblElemLiveObj.county+i]?.errors?.pattern">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.alphabeticHypenError"></bb-content-html>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <div>
                                            <label for="{{lblElemLiveObj.postCode+i}}">
                                                {{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.postCode}}
                                            </label>
                                            <input type="text" class="form-control wss-form-input-small" formControlName="{{lblElemLiveObj.postCode+i}}" 
                                            id="{{lblElemLiveObj.postCode+i}}" (change)="checkFullAddress(i, lblElemLiveObj, 'multiple', showElmLiveObj, errorsLiveObj, 1)" minlength="6" maxlength="8"
                                            placeholder="{{brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.postCodePlaceholderText}}" aria-label="Postcode">
                                        </div>
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.postCodeErr+i}}" 
                                        *ngIf="this.submitted && aboutDetailsForm.controls[lblElemLiveObj.postCode+i].status === 'INVALID' && 
                                        aboutDetailsForm.controls[lblElemLiveObj.postCode+i]?.errors?.pattern?.requiredPattern !== '^[a-zA-Z0-9 ]+$'">                    
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsEmployment.postCodeErrorMessage"></bb-content-html>
                                        </div>
                                        <div class="mt-5px text-danger" id="{{lblElemLiveObj.postCodeErr+i}}InvalidChars" 
                                        *ngIf="aboutDetailsForm.controls[lblElemLiveObj.postCode+i]?.errors?.pattern?.requiredPattern === '^[a-zA-Z0-9 ]+$'">              
                                            <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.alphanumericError"></bb-content-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-25px mt-25px col-md-5 address-block" *ngIf="showElmLiveObj.showaddressBlock[i] && !showElmLiveObj.showFullAddress[i]">
                                <wss-address-display 
                                    [addressContent]="addressData[i+1].address_detail" 
                                    [displayClass]="classLiveAddress">
                                </wss-address-display>
                            </div>
                        </ng-container>
                        <div id="{{lblElemLiveObj.displayMonthYear+i}}" *ngIf="showElmLiveObj.showaddressBlock[i]">
                            <wss-infor-tool 
                                [idExpand]="idExpandArr[10] + i" 
                                [idCollapse]="idCollapseArr[10] + i"
                                [idInforPanel]="idPanelArr[10] + i"
                                [lblContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblMoveLive"
                                [lblInforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.inforText"
                                [inforContent]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.braInforMoveLive">
                            </wss-infor-tool>
                            <div class="mt-20px flex-r">
                                <div>
                                    <label>{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblMonthOfBirth}}</label>
                                    <select id="{{lblElemLiveObj.addressMonth+i}}" formControlName="{{lblElemLiveObj.addressMonth+i}}" 
                                    class="form-control w-100 month-select" (change)="checkDate(lblElemLiveObj.addressMonth+i,lblElemLiveObj.addressYear+i,i+1)" aria-label="Months at address">
                                        <option selected value="">{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.monthDefaultText}}</option>
                                        <option *ngFor="let month of monthsData" [value]="month.value">{{month.title}}</option>
                                    </select> 
                                    <div class="mt-5px text-danger" id="{{lblElemLiveObj.addressMonthAddErr+i}}" *ngIf="(this.submitted || showElmLiveObj.submitted) && aboutDetailsForm.controls[lblElemLiveObj.addressMonth+i].status === 'INVALID'">
                                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.moveLiveMonthError">
                                        </bb-content-html>
                                    </div>                          
                                </div>
                                <div class="div-year-select">
                                    <label>{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.lblYearOfBirth}}</label>
                                    <select id="{{lblElemLiveObj.addressYear+i}}" formControlName="{{lblElemLiveObj.addressYear+i}}" class="form-control w-100 year-select" (change)="checkDate(lblElemLiveObj.addressMonth+i,lblElemLiveObj.addressYear+i,i+1)" aria-label="Years at address">
                                        <option selected value="">{{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.yearDefaultText}}</option>
                                        <option *ngFor="let year of yearsList" [value]="year">{{year}}</option>
                                    </select>
                                    <div class="mt-5px text-danger" id="{{lblElemLiveObj.addressYearAddErr+i}}" *ngIf="(this.submitted || showElmLiveObj.submitted) && aboutDetailsForm.controls[lblElemLiveObj.addressYear+i].status === 'INVALID'">
                                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.moveLiveYearError">
                                        </bb-content-html>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5px text-danger" *ngIf="errorsLiveObj.dateCheckErr[i]" id="{{lblElemLiveObj.dateSelectErr+i}}">
                                <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.dateSelectError">
                                </bb-content-html>                                     
                            </div>
                            <div class="mt-5px text-danger" *ngIf="errorsLiveObj.dateCheckDOBErr[i]" id="{{lblElemLiveObj.dateSelectDOBErr+i}}">
                                <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsTitle.compareDOBDateErr">
                                </bb-content-html>                        
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="errorsCurrentObj.failureAddressCapture" class="d-flex r-flex mt-30px">
                    <div class="icon_warning_circle">                            
                    </div>
                    <div class="term-success ml-10x">
                        <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.warningLimit"></bb-content-html>
                    </div>
                </div>                
                <div *ngIf="showElmLiveObj.successAddressTerm" class="d-flex r-flex mt-30px">
                    <div class="wss-checked-icon">                            
                    </div>
                    <div class="term-success ml-10x">
                        {{brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.successTextTerm}}
                    </div>
                </div>
                <div *ngIf="(this.aboutDetailsForm.controls.confirmAddressOpt) && (this.aboutDetailsForm.controls.confirmAddressOpt.value === 'no')" class="mt-10px">
                    <bb-content-html [html]="brGetdetailsAboutItem.balloonRefinanceGetdetailsAbout.updateAddressError">
                    </bb-content-html>
                </div>
            </div>
        </form>    
        <div class="mb-40px">
            <wss-common-nav-buttons [lastDateOfDecision]="lastDateOfDecision"
                [content]="brGetdetailsAboutItem.balloonRefinanceGetdetailsNavBtns" [accountsDetails]="accountDetails"
                [previousPageLink]="previousPageLink" (primaryButtonClicked)="onSaveButtonClick($event)"
                (continueButtonClicked)="onContinue($event)" [testIdText]="commonNavTestID">
            </wss-common-nav-buttons>
        </div>
    </section>
</section>