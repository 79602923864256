import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ContentData } from '@wss/config/wss-app-constants';
import { ContentService as OldContentService } from "@wss/service/content.service";
import { PaymentService } from '../../services/payment.service';
import { DatastoreService } from 'libs/service/datastore.service';
import * as moment from 'moment';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { TealiumUtagService } from '@wss/service/utag.service';
import { MessageService } from '@wss/service/message.service';
import { isLPAgreement  } from 'libs/common/util/util';

@Component({
    selector: 'over-payment',
    templateUrl: './over-payment.component.html',
    styles: [
    ],
    providers: [ContentService]
})

export class OverPaymentComponent implements OnInit {
    contentTxt: string = ContentData.OverPayment;
    content: Array<any> = [];
    contentFolder: string = ContentData.MakePayment;
    amountExceedflag: boolean = false;
    overPaymentForm!: FormGroup;
    hpCustomerFlag: boolean = true;
    accountDetails: any;
    paymentButtonDisable: boolean = true;
    poundCssFlag: boolean = false;
    isLPAgreementFlag: any;

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');

    constructor(private router: RouterService, private formBuilder: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef, private contentService: OldContentService,
        private paymentService: PaymentService, private datastoreService: DatastoreService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService, private messageService: MessageService) {
    }

    ngOnInit() {
        this.getStaticContent(this.contentTxt, this.contentFolder);
        sessionStorage.removeItem('overpayment-exception');
        this.overPaymentForm = this.formBuilder.group({
            overPaymentAmount: ['', Validators.required]
        });
        this.accountDetails = this.datastoreService.getAccountDetails();
        this.isLPAgreementFlag = isLPAgreement(this.accountDetails); 
        if (this.accountDetails && (this.accountDetails.scheme_code == '01' || this.accountDetails.scheme_code == '1')) {
            this.hpCustomerFlag = true;
        } else {
            this.hpCustomerFlag = false;
        }
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Overpayment',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.messageService.setCurrentStep(0);
    }

    getStaticContent(contenttxt: string, contentfolder: string): void {
        this.contentService.getStaticContent(contenttxt, contentfolder).subscribe((content: any[]) => {
            this.content = content;
            this.changeDetectorRef.detectChanges();
        });
    }

    onCreateQuote() {
        let alreadyOverpaidAcctNo = localStorage.getItem("overpaymentDoneToday");
        let loggedInAcct = this.datastoreService.getAccountNo();
        let today = moment().format('DD/MM/YYYY');
        let alreadyPaidDate = '';
        if (alreadyOverpaidAcctNo && alreadyOverpaidAcctNo !== '') {
            alreadyPaidDate = alreadyOverpaidAcctNo.split("|")[0];
            alreadyOverpaidAcctNo = alreadyOverpaidAcctNo.split("|")[1];
        }
        if ((alreadyOverpaidAcctNo === loggedInAcct) && (alreadyPaidDate === today)) {
            this.router.navigate(['payment-result-process']);
        } else {
            sessionStorage.setItem('settlementAmount', this.overPaymentForm.controls['overPaymentAmount'].value);
            this.datastoreService.setSettlementAmount(this.overPaymentForm.controls['overPaymentAmount'].value);
            this.router.navigate(['over-payment-options']);
        }
    }

    requestSettlementClick() {
        this.router.navigate(['payment-menu']);
    }

    onAmountEnter(amountValue: any) {
        if (!amountValue || amountValue.length >= 0) {
            this.paymentButtonDisable = false;
        }
        let amountFloatValue = parseFloat(amountValue);
        if (amountFloatValue === .0 || amountValue === "." || this.overPaymentForm.get('overPaymentAmount')?.invalid) {
            this.paymentButtonDisable = false;
        }

    }
}

