import { PortalContentMock } from "@backbase/foundation-ang/web-sdk";
import { CarbonIoffsetBanner } from "@wss/account-summary-widget/src/model/account-summary";

export class DashboardWidgetItem {
    greetingText: string = "";
    settlementButtonText: string = "";
    paymentLink: string = "";
    transactionButtonText: string = "";
    hpSchemeDescriptionText: string = '';
    arrearMessage: ArrearMessage = new ArrearMessage(); 
    myAgreement: MyAgreement = new MyAgreement();
    speedometer: Speedometer = new Speedometer();
    nextPaymentContent: NextPayment = new NextPayment();
    additionalInfo: AdditionalInfoContent = new AdditionalInfoContent();
    paperlessBanner: PaperlessBanner = new PaperlessBanner();
    paperlessPopup: PaperLessPopup = new PaperLessPopup();
    contactPreferencePopup: ContactPreferencePopUp = new ContactPreferencePopUp();
    contactPreferenceBanner: ContactPreferenceBanner = new ContactPreferenceBanner()
    bannerMessage: BannerMessageContent = new BannerMessageContent();
    carbonIoffset: CarbonIoffsetBanner = new CarbonIoffsetBanner();
    leasePurchaseTxt: string = '';
}

export class BannerMessageContent {
    eocBannerDueText: string = '';
    eocOptionLinkText: string = '';
    eocBrContinueJourneyText: string = '';
    eocBrReturnAndSignText: string = '';
    eocBalloonRefineryText: string = '';
    eocSelectedOptionText: string = '';
    balloonRefinanceVehicleText: string = '';
    keepVehicleText: string = '';
    partExchangeText: string = '';
    returnVehicleText: string = '';
    nextSteps: string = '';
    reviewYourApplicationResultText: string ='';
}

export class ArrearMessage {
    titleTextBeforeData: string = '';
    titleTextAfterData: string = ``;
    subTitleText: string = '';
    subTitleTextAtEnd: string = '';
}

export class MyAgreement {
    sectionTitle: string = '';
    paymentLeft: string = '';
    currentLength: string = '';
    monthsText: string = '';
    tooltipContent: string = ``;
    manageYourAgreementButtonText: string = '';
}

export class Speedometer {
    amountPaid: string = '';
    amountLeft: string = '';
    finalPayment: string = '';
    finalPaymentLP: string = '';
    outstandingBalance: string = '';
    subjectNote: string = '';
    lastPayment: string = '';
    dateRecieved: string = '';
}

export class NextPayment {
    title: string = '​';
    amount: string = '';
    ddue: string = '';
    cpd: string = '';
}

export class AdditionalInfoContent {
    sectionTitle: string = '';
    startDate: string = '';
    apr: string = '';
    aprTooltipContent: string = ``;
    mileage: string = '';
    finalPayment: string = '';
    finalPaymentLP: string = '';
    ofpTooltipContent: string = ``;
    finalPaymentDateTxt: string = ``;
}

export class PaperlessBanner {
    paperlessBannerText: string = "";
    paperlessButtonText: string = '';
}
export class PaperLessPopup {
    paperLessPopupBodyText: string = '';
    paperLessPopupButtonText: string = '';
    paperLessPopupLinkText: string = '';
}

export class ContactPreferenceBanner {
   titleText: string = '';
   bodyText: string = '';
   linkText: string = '';
}

export class ContactPreferencePopUp {
    contactPreferencePopupTitleText: string = '';
    contactPreferencePopupBodyText: string = '';
    contactPreferencePopupButtonText: string = '';
    contactPreferencePopupLinkText: string = '';
}

export const dasboardContentRef = PortalContentMock.addStructuredContent<DashboardWidgetItem>('DashboardWidgetItem', {
    greetingText: "Welcome, ",
    settlementButtonText: "Get a settlement quote",
    paymentLink: "Make a payment",
    transactionButtonText: "View transactions",
    hpSchemeDescriptionText: 'Hire Purchase Agreement',
    arrearMessage: {
        titleTextBeforeData: 'Your agreement with us is in arrears of',
        titleTextAfterData: ``,
        subTitleTextAtEnd:'',
        subTitleText:''
    }, 
    bannerMessage: {
        eocBannerDueText: 'Your Contract is due to end on ',
        eocSelectedOptionText: 'You\'ve chosen to',
        nextSteps:'Next Step',
        eocOptionLinkText: `<a>Here are your options</a>`,
        eocBrContinueJourneyText: 'Continue application',
        eocBrReturnAndSignText: 'Return and sign your application',
        balloonRefinanceVehicleText: 'Balloon Refinance option selected',
        keepVehicleText: ' keep your vehicle',
        partExchangeText: ' part exchange your vehicle',
        returnVehicleText: ' return your vehicle',
        eocBalloonRefineryText: `<a href='#/eoc-options/eoc-balloon-refinance'>Learn more</a>`,
        reviewYourApplicationResultText: 'Review your application result'
    }
    ,
    myAgreement: {
        sectionTitle: 'My agreement:',
        paymentLeft: 'Payments left',
        currentLength: 'Current length',
        monthsText: 'Months',
        tooltipContent: `If you have missed any payments you may have more payments left than shown.
        If you have requested a payment holiday or a term extension, this will be included in the figures shown. Get in touch`,
        manageYourAgreementButtonText: 'manageYourAgreementButtonText'
    },
    speedometer: {
        amountPaid: 'Amount Paid',
        amountLeft: 'Amount left to pay',
        finalPayment: 'Optional final payment',
        outstandingBalance: 'Balance outstanding',
        subjectNote: '*Subject to fees and charges',
        lastPayment: 'Last payment amount',
        dateRecieved: 'Date received',
        finalPaymentLP: ""
    },
    nextPaymentContent: {
        title: 'Your next payment:​',
        amount: 'Amount',
        ddue: 'Date due',
        cpd: 'Change payment date'
    },
    additionalInfo: {
        sectionTitle: 'Additional info:',
        startDate: 'Start date',
        apr: 'APR',
        aprTooltipContent: `What is APR?
        Annual percentage rate (APR) is the overall cost of borrowing money under your finance agreement with us.
        It represents the amount of interest, as well as other charges, that’s added to the amount owed in your finance agreement. Learn more about APR and how it’s calculated in our Help Center.`,
        mileage: 'Annual mileage',
        finalPayment: 'Optional final payment',
        ofpTooltipContent: `What is Optional final payment?​
        The Optional final payment amount is the final amount paid to us if you choose to keep the vehicle/goods at the end of your agreement. Learn about the options available to you at the end of your agreement in our Help Center.`,
        finalPaymentDateTxt: '',
        finalPaymentLP: ''
    },
    paperlessBanner: {
        paperlessBannerText: "Make the switch",
        paperlessButtonText: 'Go paperless'
    },
    paperlessPopup: {
        paperLessPopupBodyText: 'A safe way to store your letters and statements, and help reduce paper waste.',
        paperLessPopupButtonText: 'Go paperless',
        paperLessPopupLinkText: 'Keep sending me letters',
    },
    contactPreferenceBanner:{
        titleText: '',
        bodyText: '',
        linkText: ''
    },
    contactPreferencePopup: {
        contactPreferencePopupTitleText: '',
        contactPreferencePopupBodyText: '',
        contactPreferencePopupButtonText: '',
        contactPreferencePopupLinkText: ''
    },
    carbonIoffset: {
        bannerInfo: 'Did you know you can offset your vehicle carbon emissions?',
        bannerLink: 'More about carbon offsetting',
        brandsToShowCarbonOffsetContent: 'blackhorse'
    },
    leasePurchaseTxt: ''
});