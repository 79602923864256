<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="payment-row section-title-container">
        <span class="section-title" test_id="make_payment_label_id">
            <!-- {{content[0]?.overPaymentTitle}} -->
            {{paymentsWidgetItem.overpayment.overPaymentPageName}}
        </span>
    </div>
    <div class="payment-column over-payment-column margin-bottom">
        <div class="parent-overpayment-content-container over-payment-container-dif-view left-container"
            aria-describedby="MakeOverpaymentSection">
            <div class="payment-row margin-top-20">
                <div class="make-oneoff-payment-label" test_id="overpaymentTimeInfoText">
                    <!-- {{content[0]?.makeOneOffPayment}} -->
                    <bb-content-html [html]="paymentsWidgetItem.overpayment.overpaymentTimeInfoText">
                    </bb-content-html>
                </div>
            </div>
            <div class="payment-row margin-top-20">
                <div class="overpayment-advantages">
                    <div *ngIf="hpCustomerFlag && !isLPAgreementFlag" test_id="overpaymentHPSelectionInfoText">
                        <!-- {{content[0]?.whenMakingOverpayment}} -->
                        <bb-content-html [html]="paymentsWidgetItem.overpayment.overpaymentHPSelectionInfoText">
                        </bb-content-html>
                    </div>
                    <div *ngIf="!hpCustomerFlag || isLPAgreementFlag" test_id="overpaymentPCPSelectionInfoText">
                        <!-- {{content[0]?.whenMakingOverpayment}} -->
                        <bb-content-html [html]="paymentsWidgetItem.overpayment.overpaymentPCPSelectionInfoText">
                        </bb-content-html>
                    </div>
                    <span *ngIf="hpCustomerFlag && !isLPAgreementFlag">
                        <ul>
                            <li test_id="overpaymentSelectionOption1Text"><span class="material-icons overpayment-list">check</span>
                                {{paymentsWidgetItem.overpayment.overpaymentSelectionOption1Text}}
                                <!-- <bb-content-html [html]="paymentsWidgetItem.overpayment.overpaymentSelectionOption1Text">
                            </bb-content-html> -->
                            </li>
                            <li test_id="overpaymentSelectionOption2Text"><span class="material-icons overpayment-list">check</span>
                                <!-- {{content[0]?.lowerEachMonth}} -->
                                {{paymentsWidgetItem.overpayment.overpaymentSelectionOption2Text}}
                                <!-- <bb-content-html [html]="paymentsWidgetItem.overpayment.overpaymentSelectionOption2Text">
                            </bb-content-html> -->
                            </li>
                        </ul>
                    </span>
                    <div test_id="overpaymentFurthertInfoText">
                        <!-- {{content[0]?.noAdditionalInterest}} -->
                        <bb-content-html [html]="paymentsWidgetItem.overpayment.overpaymentFurthertInfoText">
                        </bb-content-html>
                    </div>
                </div>
            </div>
            <!-- Commented below as moved this line in further info text -->
            <!-- <div class="payment-row margin-top-20">
                <div class="learn-more">
                    <span>{{content[0]?.learnMoreText}}
                        <a title="{{content[0]?.overpaymentFaqs}}" tabindex="0">{{content[0]?.overpaymentFaqs}}</a>
                    </span>
                </div>
            </div> -->
        </div>
        <div class="container-column payment-result-content over-payment over-payment-result-container right-container">
            <div class="container-column payment-result-section">
                <div class="container-row internal-section-title">
                    <div class="make-oneoff-payment-label" test_id="overpaymentPaybleAmountInfoText">
                        <!-- {{content[0]?.howMuchToPayForAdditionalPayment}} -->
                        <bb-content-html [html]="paymentsWidgetItem.overpayment.overpaymentPaybleAmountInfoText">
                        </bb-content-html>
                    </div>
                </div>
                <form [formGroup]="overPaymentForm" class="form" role="form" autocomplete="off" id="formPayment"
                    novalidate="" method="POST">
                    <div class="form-group overpayment-form">
                        <div class="over-payment-form-content">
                            <div class="container-row">
                                <span class="poundFocusCss"> £ </span>
                                <input #amount type="text" class="form-control form-control-lg rounded-2 amount-input"
                                    formControlName="overPaymentAmount" placeHolder="Enter amount" accesskey="o"
                                    (ngModelChange)="onAmountEnter(amount.value)">
                            </div>
                            <div class="d-flex justify-content-center container-row" *ngIf="hpCustomerFlag && !isLPAgreementFlag">
                                <button id="overpaymentOptionViewButton" class="wss-button button-width-padding" title="{{content[0]?.viewOverpaymentOptions}}"
                                    (click)="onCreateQuote()" [disabled]="paymentButtonDisable" test_id="overpaymentOptionViewButtonText">
                                    <!-- {{content[0]?.viewOverpaymentOptions}} -->
                                    {{paymentsWidgetItem.overpayment.overpaymentOptionViewButtonText}}
                                </button>
                            </div>
                            <div class="container-row" *ngIf="!hpCustomerFlag || isLPAgreementFlag">
                                <button id="overpaymentOptionViewButton" class="wss-button button-width-padding" title="{{content[0]?.createQuote}}" (click)="onCreateQuote()"
                                    [disabled]="paymentButtonDisable" test_id="newMonthlyPaymentButtonText">
                                    <!-- {{content[0]?.createQuote}} -->
                                    {{paymentsWidgetItem.overpayment.newMonthlyPaymentButtonText}}
                                </button> 
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="container-column content-center need-help-settle-amount">
                <!-- 
                <div>
                    {{content[0]?.lookingToSettleYourAccount}}
                   <bb-content-html [html]="paymentsWidgetItem.overpayment.settlementAccountInfo">
                    </bb-content-html>
                </div> -->
                <div>
                    <!-- {{content[0]?.youWillNeedTo}}  -->
                    <a (click)="requestSettlementClick()" tabindex="0"
                        title="{{paymentsWidgetItem.overpayment.settleRequestLinkText}}" class="payment-call-us" test_id="settleRequestLinkText">
                        {{paymentsWidgetItem.overpayment.settleRequestLinkText}}
                    </a>
                </div>
            </div>
            <div class="payment-row content-center">
                <!-- <a href="#" test_id="need_help_id" title="{{content[0]?.needHelp}}"
                    tabindex="0">{{content[0]?.needHelp}}</a> -->
                <span tabindex="0" title="{{paymentsWidgetItem.overpayment.needHelpLinkText}}" test_id="needHelpLinkText">
                    <bb-content-html [html]="paymentsWidgetItem.overpayment.needHelpLinkText">
                    </bb-content-html>
                </span>
            </div>
        </div>
    </div>
</div>