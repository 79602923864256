<section id="brg-accept" *ngIf="brgAcceptItem$ | async as brgAcceptItem">
    <div class="container-column">
        <div class="section-title-container">
            <h1 class="section-label-title" test_id="pageName">
                {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pageTitle}}
            </h1>
        </div>
        <section class="content-width-max">
            <div class="mt-25px-mb-30px" test_id="brbTextAfterPageTitle">
                <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.textAfterPageTitle">
                </bb-content-html>
            </div>
            <h2 class="mb-20px">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.summaryTitle}}</h2>
            <div class="quote-items-container">
                <div class="quote-item">
                    <div class="wss-add-box-icon quote-icons quote-icons-flat"></div>                    
                    <div class="quote-details-item">
                        <span class="quote-details-title" test_id="agrTotalInterestTitle">
                            {{brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteTotalInterestTitle}}
                            <ng-container *ngIf="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteTotalInterestPopoverText">
                                <wss-popover [popoverContent]="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteTotalInterestPopoverText" [popOverId]="'acceptTotalInterestPopoverId'"></wss-popover>
                            </ng-container>
                        </span>
                        <span test_id="agrTotalInterestText" class="font-size-16 line-height-24">
                            {{modagData.hireCharges | currency:'GBP':'symbol'}}
                        </span>                                                                       
                    </div>
                </div>
                <div class="quote-item">
                    <div class="wss-direct-debit-icon quote-icons quote-icons-refinance"></div>                                        
                    <div class="quote-details-item">
                        <span class="quote-details-title" test_id="agrRefinanceAmountTitle">
                            {{brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteRefinanceAmountTitle}}
                            <ng-container *ngIf="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteRefinanceAmountPopoverText">
                                <wss-popover [popoverContent]="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteRefinanceAmountPopoverText" [popOverId]="'acceptRefinanceAmountPopoverId'"></wss-popover>
                            </ng-container>
                        </span>
                        <span test_id="quoteRefinanceAmountText" class="font-size-16 line-height-24">
                            {{modagData.creditAmt | currency:'GBP':'symbol'}}
                        </span>                                                
                    </div>
                </div>
                <div class="quote-item">
                    <div class="wss-chart-icon quote-icons quote-icons-flat"></div>                    
                    <div class="quote-details-item"> 
                        <span class="quote-details-title" test_id="agrAPRTitle">
                            {{brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteAPRTitle}}
                            <ng-container *ngIf="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteAPRPopoverText">
                                <wss-popover [popoverContent]="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteAPRPopoverText" [popOverId]="'acceptAPRPopoverId'"></wss-popover>
                            </ng-container>
                        </span>
                        <span test_id="agrAPRText" class="font-size-16 line-height-24">
                            {{brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteAPRPercentage}}%
                        </span> 
                    </div>
                </div>
                <div class="quote-item">
                    <div class="wss-receipt-icon quote-icons quote-icons-normal"></div>                    
                    <div class="quote-details-item">
                        <span class="quote-details-title" test_id="agrTotalAmountTitle">
                            {{brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteTotalAmountTitle}}
                            <ng-container *ngIf="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteTotalAmountPopoverText">
                                <wss-popover [popoverContent]="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteTotalAmountPopoverText" [popOverId]="'acceptTotalAmountPopoverId'"></wss-popover>
                            </ng-container>
                        </span>
                        <span test_id="quoteTotalAmountText" class="font-size-16 line-height-24">
                            {{modagData.totalPayableAmt | currency:'GBP':'symbol'}}*
                        </span>                        
                    </div>                    
                </div>
                <div class="quote-item">
                    <div class="wss-calendar-month-icon quote-icons quote-icons-normal"></div>                    
                    <div class="quote-details-item">
                        <span class="quote-details-title" test_id="agrTermTitle">
                            {{brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteTermTitle}}
                            <ng-container *ngIf="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteTermPopoverText">
                                <wss-popover [popoverContent]="brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteTermPopoverText" [popOverId]="'acceptTermPopoverId'"></wss-popover>
                            </ng-container>
                        </span>
                        <span test_id="agrTermTxt">
                            {{proposalDetails?.proposed_term}}
                        </span>                        
                    </div>                    
                </div>
                <div class="quote-item">
                    <div class="wss-car-light-icon quote-icons quote-icons-car"></div>
                    <div class="quote-details-item">
                        <span class="quote-details-title" test_id="agrTitle">
                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.agreementType}}
                        </span>
                        <span test_id="agrTxt">
                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.agreementName}}
                        </span>
                    </div>
                </div>
                <div class="quote-item">
                    <div class="wss-credit-card-icon quote-icons quote-icons-refinance"></div>
                    <div class="quote-details-item">
                        <span class="quote-details-title" test_id="agrMonthlyTitle">
                            {{brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteMonthlyTitle}}
                        </span>
                        <span test_id="agrAgreementText" class="font-size-16 line-height-24">
                            <ng-container *ngIf="this.rentalScheme">                         
                                {{rentalScheme[1].new_rental_value | currency:'GBP':'symbol'}}
                            </ng-container>                            
                        </span>
                    </div>
                </div>
            </div>
            <div class="quote-details-item" test_id="quoteOptionFeeText">
                {{brgAcceptItem.balloonRefinanceGetdetailsSummary.quoteOptionFeeText}}                        
            </div>
            <h3 class="mb-20px mt-20px">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.paymentSchedule}}</h3>
            <div class="mb-20px">
                <ng-container *ngIf="this.rentalScheme">                    
                    {{brgAcceptItem.balloonRefinanceGetdetailsAccept.firstPaymentStart}} 
                    <span class="text-semibold">{{rentalScheme[0].new_rental_value | currency:'GBP':'symbol'}}
                    {{brgAcceptItem.balloonRefinanceGetdetailsAccept.firstPaymentEnd}} 
                    {{rentalScheme[0].new_rental_date}}</span>, 
                    {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRepayStart2}} 
                    <span class="text-semibold">{{rentalScheme[1].new_number_of_rentals}}</span> 
                    {{brgAcceptItem.balloonRefinanceGetdetailsAccept.secondPaymentMiddle}} 
                    <span class="text-semibold">{{rentalScheme[1].new_rental_value | currency:'GBP':'symbol'}}, 
                    {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRepayStart3}} 
                    {{rentalScheme[2].new_rental_value | currency:'GBP':'symbol'}}</span>
                </ng-container>
            </div>
            <h2 class="mb-20px">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.accordionHeader}}</h2>
            <div class="transparent-accordion mb-45px balloon-refinance-accordion-header">
                <mat-accordion class="accordion mat-accordion-override w-100">
                    <mat-expansion-panel  id="wss-brg-accept-pce-expansion-panel" [expanded]="isPCEOpen" (opened)="isPCEOpen = true"
                        (closed)="isPCEOpen = false"
                        class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="mat-expansion-panel-header-title-override font-size-16">
                                <span *ngIf="!isPCERead"
                                    class="material-icons info-icon br-accept-icon br-accept-icon--info">info_outlined</span>
                                <span *ngIf="isPCERead"
                                    class="material-icons br-accept-icon br-accept-icon--tick">check_circle_outline</span>
                                <div>
                                    <p class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEHeading}}</p>
                                    <bb-content-html *ngIf="!isPCERead"
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCETextToReview">
                                    </bb-content-html>
                                    <bb-content-html *ngIf="isPCERead"
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCETextHasReviewed">
                                    </bb-content-html>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accordian-content font-regular">
                            <div class="mb-25px content-text-bold">
                                {{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCENotes}} {{modagData.brand?.creditorsName}}                                                               
                            </div>
                            <div class="flex-w-space mb-20px">
                                <div class="w-flex-50-100">                                    
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEIntroTitle}}</h3>
                                </div>
                                <div class="w-flex-50-100 txt-nl">
                                    <div class="mb-15px">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEIntroTxtPara1Start}} {{modagData.brand?.agreementDisplayName}} {{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEIntroTxtPara1End}}
                                    </div>
                                    <bb-content-html
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEIntroTxtFollowParas">
                                    </bb-content-html>                                                                    
                                </div>
                            </div>
                            <div class="flex-w-space mb-20px">
                                <div class="w-flex-50-100">                                    
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEModagTitle}}</h3>
                                </div>
                                <div class="w-flex-50-100 txt-nl">
                                    <bb-content-html
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEModagText">
                                    </bb-content-html>
                                </div>
                            </div>
                            <div class="flex-w-space mb-20px">
                                <div class="w-flex-50-100">                                                                        
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEGoodsTitle}}</h3>
                                </div>
                                <div class="w-flex-50-100 txt-nl ">
                                    <span class="text-semibold">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEGoodsTextHeader">
                                        </bb-content-html>
                                    </span>                                    
                                    <bb-content-html
                                        [html]="isMotorbike ? brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEGoodsTextMotorbike : brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEGoodsText">
                                    </bb-content-html>
                                </div>
                            </div>
                            <div class="flex-w-space mb-20px">
                                <div class="w-flex-50-100">
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEFailureTitle}}</h3>
                                </div>
                                <div class="w-flex-50-100 txt-nl">
                                    <span class="text-semibold">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEFailureTextHeader">
                                        </bb-content-html>
                                    </span>
                                    <bb-content-html
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEFailureText">
                                    </bb-content-html>
                                </div>
                            </div>
                            <div class="flex-w-space mb-20px">
                                <div class="w-flex-50-100">                                    
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEPCCITitle}}</h3>
                                </div>
                                <div class="w-flex-50-100 txt-nl">
                                    <bb-content-html
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEPCCIText">
                                    </bb-content-html>
                                </div>
                            </div>
                            <div class="flex-w-space mb-10px">
                                <div class="w-flex-50-100">
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEFinalTitle}}</h3>
                                </div>
                                <div class="w-flex-50-100 txt-nl">
                                    <div class="mb-15px">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEFinalPara1Start}} 
                                        {{modagData.brand?.agreementDisplayName}}                                     
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEOn}} 
                                        <a href="javascript:void(0);" (click)="linkHandler(modagData.brand?.customerService, '_self', true)" tabindex="-1">{{modagData.brand?.customerService}}</a>. 
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEFinalPara1End}}
                                    </div>                                    
                                    <bb-content-html
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCEFinalFollowPara">
                                    </bb-content-html>
                                    <button class="wss-button-secondary" id="wss-brg-accept-confirm-PCE-btn" (click)="acceptPCE()">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.acceptButton}}</button>                                 
                                </div>                                
                            </div>
                            <div class="mb-10px text-small">
                                <bb-content-html
                                    [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.docRefPCE">
                                </bb-content-html>                                
                            </div>                            
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel id="wss-brg-accept-pcci-expansion-panel" [expanded]="isPCCIOpen" (opened)="isPCCIOpen = true" (closed)="isPCCIOpen = false"
                        class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="mat-expansion-panel-header-title-override font-size-16">
                                <span *ngIf="!isPCCIRead"
                                    class="material-icons info-icon br-accept-icon br-accept-icon--info">info_outlined</span>
                                <span *ngIf="isPCCIRead"
                                    class="material-icons br-accept-icon br-accept-icon--tick">check_circle_outline</span>
                                <div class="txt-nl">
                                    <p class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.PCCIHeading}}</p>
                                    <bb-content-html *ngIf="!isPCCIRead"
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCCITextToReview">
                                    </bb-content-html>
                                    <bb-content-html *ngIf="isPCCIRead"
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.PCCITextHasReviewed">
                                    </bb-content-html>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accordian-content font-regular">
                            <div class="mb-25px txt-nl" data-cs-mask>
                                <span class="d-block text-semibold">  
                                    <bb-content-html
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.customerNameAndAddress">
                                    </bb-content-html>
                                </span>
                                <span>{{nameTitle | titlecase}} {{fullName | titlecase}}</span>,
                                <span *ngIf="(currentAddress?.flat_number !== ' ') && (currentAddress?.flat_number !== null)">
                                    {{currentAddress.flat_number}},
                                </span>
                                <span *ngIf="(currentAddress?.house_number !== ' ') && (currentAddress?.house_number !== null)">
                                    {{currentAddress.house_number}}, 
                                </span>
                                <span *ngIf="(currentAddress?.house_name !== ' ') && (currentAddress?.house_name !== null)">
                                    {{currentAddress.house_name | titlecase}}, 
                                </span>
                                <span *ngIf="(currentAddress?.street_name !== ' ') && (currentAddress?.street_name !== null)">
                                    {{currentAddress.street_name | titlecase}}, 
                                </span>
                                <span *ngIf="(currentAddress?.post_town !== ' ') && (currentAddress?.post_town !== null)">
                                    {{currentAddress.post_town | titlecase}}, 
                                </span>
                                <span *ngIf="(currentAddress?.post_code !== ' ') && (currentAddress?.post_code !== null)">
                                    {{currentAddress.post_code}}
                                </span>
                                <div class="mt-20px">
                                    <span class="text-semibold">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.proposalNo}}: </span><span data-cs-mask>{{accountDetails.account_number}}</span>
                                </div>    
                            </div>
                            <div class="flex-w-space mb-15px">
                                <div class="w-flex-50-100">
                                <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciContactTitle}}</h3>
                                </div>
                                <div class="w-flex-50-100 txt-nl">
                                    <span class="text-semibold mb-p-0">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.creditor">
                                        </bb-content-html>
                                    </span>
                                    <span class="d-block pb-20px">{{modagData.brand?.creditorsName}}</span>
                                    <span class="d-block text-semibold mb-p-0">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.addressFullStop">
                                        </bb-content-html>
                                    </span>
                                    <span>
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.creditorAddress">
                                        </bb-content-html>
                                    </span>                                    
                                </div>
                            </div>
                            <div class="flex-w-space mb-25px">
                                <div class="w-flex-50-100">
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciKeyTitle}}</h3>                                    
                                </div>
                                <div class="w-flex-50-100 txt-nl">
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciTypeCreditTitle}}
                                    </span>
                                    <span class="d-block">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciTypeCreditTxt">
                                        </bb-content-html>
                                    </span>
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciAmountCreditTitle}}
                                    </span>
                                    <span class="d-block">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciAmountCreditTxt">
                                        </bb-content-html>                                        
                                    </span>
                                    <div class="pb-20px">
                                        <span class="mr-15px">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.hirePurchaseTitle}}</span>
                                        {{modagData.creditAmt | currency:'GBP':'symbol'}}
                                    </div>
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciDurationCreditTitle}}                                        
                                    </span>
                                    <div class="pb-20px">                                        
                                        <span class="mr-15px">
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.hirePurchaseTitle}}
                                        </span>
                                        {{proposalDetails?.proposed_term}} {{brgAcceptItem.balloonRefinanceGetdetailsAccept.monthsTxt}}                                        
                                    </div>
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRepaymentsTitle}}
                                    </span>  
                                    <span class="d-block pb-20px">
                                        <ng-container *ngIf="this.rentalScheme">
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.hirePurchaseTitle}} - 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRepayStart1}} 
                                            {{rentalScheme[0].new_rental_value | currency:'GBP':'symbol'}}, 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRepayStart2}} 
                                            {{rentalScheme[1].new_number_of_rentals}} 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRepayEnd2}} 
                                            {{rentalScheme[1].new_rental_value | currency:'GBP':'symbol'}}, 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRepayStart3}} 
                                            {{rentalScheme[2].new_rental_value | currency:'GBP':'symbol'}}
                                        </ng-container>
                                    </span>  
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciAmountPayTitle}}
                                    </span>
                                    <bb-content-html
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciAmountPayTxt">
                                    </bb-content-html>
                                    <span class="mr-15px">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.hirePurchaseTitle}}</span>
                                    {{modagData.totalPayableAmt | currency:'GBP':'symbol'}}                                    
                                </div>
                            </div>
                            <div class="flex-w-space mb-20px">
                                <div class="w-flex-50-100">
                                <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciCostCreditTitle}}</h3>
                                </div>
                                <div class="w-flex-50-100 txt-nl">
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciInterestCreditTitle}}                                        
                                    </span>
                                    <div>
                                        <span class="mr-15px">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.hirePurchaseTitle}}</span>
                                        {{modagData.interestRate}}%
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciInterestCreditTxt">
                                        </bb-content-html>
                                    </div>
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciAPRTitle}}                                        
                                    </span>
                                    <div class="pb-10px">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciAPRTxt">
                                        </bb-content-html>                                        
                                    </div> 
                                    <span class="d-block">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciAPRTxtExtra}}</span>      
                                    <span class="mr-15px">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.hirePurchaseTitle}}</span> 
                                        {{modagData.agreementAPR}}% {{brgAcceptItem.balloonRefinanceGetdetailsAccept.aprText}}                                                                                             
                                    <span class="d-block text-semibold pt-20px">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRelatedCostTitle}}
                                    </span>
                                    <span class="d-block pb-20px">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRelatedCostTxt">
                                        </bb-content-html>
                                    </span>
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciMissingPaymentsTitle}}
                                    </span>
                                    <bb-content-html
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciMissingPaymentsTxt">
                                    </bb-content-html>
                                </div>
                            </div>
                            <div class="flex-w-space">
                                <div class="w-flex-50-100">
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciLegalAspectsTitle}}</h3>                                    
                                </div>
                                <div class="w-flex-50-100 txt-nl">
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRightWithdrawalTitle}}                                        
                                    </span>
                                    <span class="d-block">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciRightWithdrawalTxt">
                                        </bb-content-html>                                       
                                    </span>
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciEarlyRepaymentTitle}}
                                    </span>
                                    <span class="d-block">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciEarlyRepaymentTxt">
                                        </bb-content-html>
                                    </span>
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciCreditReferenceTitle}}                                        
                                    </span>
                                    <span class="d-block">
                                        <bb-content-html
                                            [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciCreditReferenceTxt">
                                        </bb-content-html>                                        
                                    </span>
                                    <span class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciDraftCreditTitle}}                                        
                                    </span>
                                    <bb-content-html
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.pcciDraftCreditTxt">
                                    </bb-content-html>
                                    <button class="wss-button-secondary" id="wss-brg-accept-confirm-PCCI-btn" (click)="acceptPCCI()">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.acceptButton}}</button>
                                </div>                         
                            </div>
                            <div class="mb-10px text-small">
                                <bb-content-html
                                    [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.docRefPCCI">
                                </bb-content-html>                                    
                            </div>                           
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel id="wss-brg-accept-modag-expansion-panel" [expanded]="isModagOpen" (opened)="isModagOpen = true"
                        (closed)="isModagOpen = false"
                        class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="mat-expansion-panel-header-title-override font-size-16">
                                <span *ngIf="!isModagRead"
                                    class="material-icons info-icon br-accept-icon br-accept-icon--info">info_outlined</span>
                                <span *ngIf="isModagRead"
                                    class="material-icons br-accept-icon br-accept-icon--tick">check_circle_outline</span>
                                <div>
                                    <p class="text-semibold">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagHeading}}</p>
                                    <bb-content-html
                                        *ngIf="!isModagRead"
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.modagTextToReview">
                                    </bb-content-html>
                                    <bb-content-html
                                        *ngIf="isModagRead"
                                        [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.modagTextHasReviewed">
                                    </bb-content-html>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <section class="mt-32px line-height-24 font-regular">
                            <div class="row justify-content-between font-size-18 mb-20px">
                                <div class="col-md-4">
                                    <span class="text-semibold">{{modagData.brand?.agreementDisplayName}}</span>
                                </div>
                                <div class="col-md-8">
                                    <span class="text-semibold" test_id="agreementNumber">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.agreementNumber}}&nbsp;</span>
                                    <span class="font-size-16" data-cs-mask>{{accountDetails.account_number}}</span>
                                </div>
                            </div>
                            <div class="font-size-16 line-height-24 text-semibold" test_id="regulationDisclaimer">
                                <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.regulationDisclaimer"></bb-content-html>
                            </div>
                            <div class="row">
                                <div class="col-md-4" test_id="creditorNameAndAddress">                                    
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.creditorNameAndAddress}}</h3>                                    
                                </div>
                                <div class="col-md-8">
                                    <div class="font-size-16 line-height-24 mb-20px">
                                        <div class="text-semibold mb-p-0" test_id="creator">
                                            <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.creator"></bb-content-html>
                                        </div>
                                        <span>{{modagData.brand?.creditorsName}}</span>
                                    </div>
                                    <div class="font-size-16 line-height-24 mb-25px">
                                        <div class="text-semibold mb-p-0" test_id="address">
                                            <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.address"></bb-content-html>
                                        </div>
                                        <div test_id="brgModAgbrandWiseAddress">
                                            <bb-content-html
                                                [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.creditorAddress">
                                            </bb-content-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" test_id="customerNameAndAddress">
                                    <h3>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.customerNameAndAddress}}</h3>
                                </div>
                                <div class="col-md-8">
                                    <div class="font-size-16 line-height-24 mb-20px">
                                        <div class="text-semibold mb-p-0" test_id="customerName">
                                            <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.customerName"></bb-content-html>
                                        </div>
                                        <span>{{customerDetails?.individual.title}}&nbsp;{{customerDetails?.individual.first_name}}&nbsp;{{customerDetails?.individual.surname}}</span>
                                    </div>
                                    <div class="font-size-16 line-height-24 mb-25px">
                                        <div class="text-semibold mb-p-0" test_id="customerAddress">
                                            <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.customerAddress"></bb-content-html>
                                        </div>
                                        <wss-address-display 
                                            [addressContent]="currentAddress" 
                                            [displayClass]="classLiveAddress">
                                        </wss-address-display>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <h3 test_id="financialInformationGoods">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.financialInformationGoods}}</h3>
                                </div>
                                <div class="col-md-8 mb-25px">
                                    <div class="font-size-16 line-height-24 mb-20px" test_id="goodsDescription">
                                        <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.goodsDescription"></bb-content-html>
                                    </div>
                                    <div>
                                        <table aria-labelledby="description" class="table table-borderless wss-table-stripped font-size-16 line-height-24">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-semibold">
                                                        <span test_id="amountOfCreditLbl">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.amountOfCreditLbl}}</span>
                                                    </td>
                                                    <td>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.letterA}}</td>
                                                    <td>
                                                        <span class="gbp">{{modagData.creditAmt | currency:'GBP':'symbol'}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-semibold">
                                                        <span test_id="hirePurchaseChargesLbl">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.hirePurchaseChargesLbl}}</span>
                                                    </td>
                                                    <td>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.numberI}}</td>
                                                    <td>
                                                        <span class="gbp">{{modagData.hireCharges | currency:'GBP':'symbol'}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-semibold">
                                                        <span test_id="creditFacilityFeeLbl">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.creditFacilityFeeLbl}}</span>
                                                    </td>
                                                    <td>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.numberII}}</td>
                                                    <td>
                                                        <span class="gbp">{{modagData.creditFee | currency:'GBP':'symbol'}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-semibold">
                                                        <span test_id="purchaseFeeLbl">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.purchaseFeeLbl}}</span>
                                                    </td>
                                                    <td>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.numberIII}}</td>
                                                    <td>
                                                        <span class="gbp">{{modagData.optionFee | currency:'GBP':'symbol'}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-semibold">
                                                        <span test_id="totalChargeOfCreditLbl">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.totalChargeOfCreditLbl}}</span>
                                                    </td>
                                                    <td>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.letterB}} {{brgAcceptItem.balloonRefinanceGetdetailsAccept.chargesAddNo}}</td>
                                                    <td>
                                                        <span class="gbp">{{modagData.totalCharges | currency:'GBP':'symbol'}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-semibold">
                                                        <span test_id="totalAmountPayableLbl">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.totalAmountPayableLbl}}</span>
                                                    </td>
                                                    <td>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.letterC}} {{brgAcceptItem.balloonRefinanceGetdetailsAccept.amountAddNo}}</td>
                                                    <td>
                                                        <span class="gbp">
                                                            {{modagData.totalPayableAmt | currency:'GBP':'symbol'}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-semibold">
                                                        <span test_id="aprLbl">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.aprLbl}}</span>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <span class="">{{modagData.agreementAPR}}%</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-semibold">
                                                        <span test_id="interestRateLbl">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.interestRateLbl}}</span>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <span class="">{{modagData.interestRate}}% {{brgAcceptItem.balloonRefinanceGetdetailsAccept.perAnnumTxt}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-semibold">
                                                        <span test_id="durationLbl">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.durationLbl}}</span>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <span class="">{{modagData.duration}} {{brgAcceptItem.balloonRefinanceGetdetailsAccept.monthsTxt}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <h3 test_id="financialInformationInsuranceProduct">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.financialInformationInsuranceProduct}}</h3>
                                </div>
                                <div class="col-md-8">
                                    <div class="font-size-16 line-height-24 mb-20px" test_id="insuranceProductDescription">
                                        <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.insuranceProductDescription"></bb-content-html>
                                    </div>
                                    <div class="mb-20px">
                                    <table aria-labelledby="description" class="table table-borderless wss-table-stripped font-size-16 line-height-24">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td class="text-semibold">
                                                    <span test_id="extraAmountCredit">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.amountOfCreditLbl}}</span>
                                                </td>
                                                <td>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.letterD}}</td>
                                                <td>
                                                    <span class="gbp">£0.00</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-semibold">
                                                    <span test_id="extraTotalCredit">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.totalChargeOfCreditLbl}}</span>
                                                </td>
                                                <td>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.letterE}}</td>
                                                <td>
                                                    <span class="gbp">£0.00</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-semibold">
                                                    <span test_id="extraTotalPayable">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.totalAmountPayableLbl}}</span>
                                                </td>
                                                <td>{{brgAcceptItem.balloonRefinanceGetdetailsAccept.letterF}} {{brgAcceptItem.balloonRefinanceGetdetailsAccept.extrasAddNo}}</td>
                                                <td>
                                                    <span class="gbp">£0.00</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-semibold">
                                                    <span test_id="extraAPR">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.aprLbl}}</span>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span class="gbp">0.0%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-semibold">
                                                    <span test_id="extraInterestRate">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.interestRateLbl}}</span>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span class="gbp">0.0% {{brgAcceptItem.balloonRefinanceGetdetailsAccept.perAnnumTxt}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-semibold">
                                                    <span test_id="extraMonth">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.totalChargeOfCreditLbl}}</span>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span class="gbp">0 {{brgAcceptItem.balloonRefinanceGetdetailsAccept.monthsTxt}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    <div class="font-size-16 line-height-24 mb-25px" test_id="insuranceProductTextAfterTable">
                                        <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.insuranceProductTextAfterTable"></bb-content-html>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <h3 test_id="yourRepayments">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.yourRepayments}}</h3>
                                </div>
                                <div class="col-md-8 font-size-16 mb-20px" *ngIf="rentalScheme">
                                    <div class="mb-15px">
                                        <span class="text-semibold">
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.oneTxt}}
                                        </span> 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.repaymentOn}} 
                                            {{rentalScheme[0].new_rental_date}} 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.ofTxt}}
                                        <span class="text-semibold">
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.goodsInBrackets}} 
                                            {{rentalScheme[0].new_rental_value | currency:'GBP':'symbol'}} 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.insuranceExtrasInBrackets}} 
                                            £0.00 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.totalInBrackets}} 
                                            {{rentalScheme[0].new_rental_value | currency:'GBP':'symbol'}}
                                        </span> 
                                    </div>
                                    <div class="mb-15px">
                                        <span class="text-semibold">
                                            {{rentalScheme[1].new_number_of_rentals}}                                
                                        </span>
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.repaymentAt}}
                                        <span class="text-semibold">
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.oneTxt}}
                                        </span>
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.monthlyInterval}}
                                        <span class="text-semibold">
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.oneTxt}}
                                        </span>
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.eachOf}}
                                        <span class="text-semibold">
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.goodsInBrackets}}
                                            {{rentalScheme[1].new_rental_value | currency:'GBP':'symbol'}}
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.insuranceExtrasInBrackets}} 
                                            £0.00 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.totalInBrackets}} 
                                            {{rentalScheme[1].new_rental_value | currency:'GBP':'symbol'}}
                                        </span>
                                    </div>
                                    <div class="mb-15px">
                                        <span class="text-semibold">
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.oneTxt}}                                
                                        </span>
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.repayment}}
                                        <span class="text-semibold">
                                            {{proposalDetails?.proposed_term}} 
                                        </span>
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.afterAgreementDateText}}
                                        <span class="text-semibold">
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.goodsInBrackets}} 
                                            {{rentalScheme[2].new_rental_value | currency:'GBP':'symbol'}} 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.insuranceExtrasInBrackets}} 
                                            £0.00 
                                            {{brgAcceptItem.balloonRefinanceGetdetailsAccept.totalInBrackets}} 
                                            {{rentalScheme[2].new_rental_value | currency:'GBP':'symbol'}}
                                        </span>                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-15px">
                                <div class="col-md-4">
                                    <h3 test_id="importantInformation">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.importantInformation}}</h3>
                                </div>
                                <div class="col-md-8 font-size-16">
                                    <span class="text-semibold" test_id="modagWithdrawalTitle">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagWithdrawalTitle}}
                                    </span>
                                    <div class="mb-20px" test_id="modagWithdrawalTxt">
                                        <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.modagWithdrawalTxt">                                            
                                        </bb-content-html>                                                                                
                                    </div>
                                    <span class="text-semibold" test_id="modagRepaymentsTitle">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagRepaymentsTitle}}
                                    </span>
                                    <div class="mb-20px" test_id="modagRepaymentsTxt">
                                        <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.modagRepaymentsTxt">                                            
                                        </bb-content-html>                                                                                
                                    </div>
                                    <span class="text-semibold" test_id="modagEarlyRepayTitle">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagEarlyRepayTitle}}
                                    </span>                                    
                                    <div class="mb-20px" test_id="modagEarlyRepayText">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagEarlyRepayStart}} 
                                        <a href="javascript:void(0);" (click)="linkHandler(modagData.brand?.customerService, '_self', true)" tabindex="-1">{{modagData.brand?.customerService}}</a> 
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagEarlyRepayEnd}}                                        
                                    </div>
                                    <span class="text-semibold" test_id="modagExtraTitle">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagExtraTitle}}
                                    </span>
                                    <div class="mb-20px" test_id="modagExtraTxt">
                                        <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.modagExtraTxt">                                            
                                        </bb-content-html>                                                                                
                                    </div>
                                    <span class="text-semibold" test_id="modagMissTitle">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagMissTitle}}
                                    </span>
                                    <div class="mb-20px" test_id="modagMissTxt">
                                        <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.modagMissTxt">                                            
                                        </bb-content-html>                                                                                
                                    </div>       
                                    <span class="text-semibold" test_id="modagTerminateTitle">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagTerminateTitle}}
                                    </span>
                                    <div class="mb-20px" test_id="modagTerminateTxt">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagTerminateStart}} 
                                        {{modagData.terminateAmt | currency:'GBP':'symbol'}}.                                          
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagTerminateEnd}}                                        
                                    </div>
                                    <span class="text-semibold" test_id="modagReposTitle">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagReposTitle}}
                                    </span>
                                    <div class="mb-20px" test_id="modagReposTxt">
                                        <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.modagReposTxt">                                            
                                        </bb-content-html>                                                                                
                                    </div>
                                    <span class="text-semibold" test_id="modagSuperTitle">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagSuperTitle}}
                                    </span>
                                    <div class="mb-20px" test_id="modagSuperTxt">
                                        <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.modagSuperTxt">                                            
                                        </bb-content-html>                                                                                
                                    </div>
                                    <span class="text-semibold" test_id="modagCompliantTitle">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagCompliantTitle}}
                                    </span>                                    
                                    <div class="mb-20px" test_id="modagCompliantText">
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagCompliantStart}} 
                                        <a href="javascript:void(0);" (click)="linkHandler(modagData.brand?.complainNo, '_self', true)" tabindex="-1">{{modagData.brand?.complainNo}}</a>. 
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagCompliantMiddle}}
                                        <a href="javascript:void(0);" (click)="linkHandler(brgAcceptItem.balloonRefinanceGetdetailsAccept.modagOmbudsmanLnk, '_blank')" tabindex="-1">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagOmbudsmanTxt}}</a>. 
                                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.modagCompliantEnd}}                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <h3 test_id="termsAndCondition">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.termsAndCondition}}</h3>
                                </div>
                                <div class="col-md-8">
                                    <div class="font-size-16 line-height-24 mb-10px" test_id="termsAndConditionText">
                                        <div class="mb-15px">1. {{brgAcceptItem.balloonRefinanceGetdetailsAccept.termsAndCondTxt1Start}} {{accountDetails.inception_date}} {{brgAcceptItem.balloonRefinanceGetdetailsAccept.termsAndCondTxt1End}}</div>
                                        <div><bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.termsAndCondTxtFollow"></bb-content-html></div>                                        
                                    </div>
                                    <button class="wss-button-secondary" id="wss-brg-accept-confirm-modag-btn" (click)="acceptModag()">{{brgAcceptItem.balloonRefinanceGetdetailsAccept.acceptButton}}</button>
                                </div>
                            </div>
                        </section>
                        <div class="mb-30px text-small">
                            <bb-content-html
                                [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.docRefModag">
                            </bb-content-html>                                    
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="card p-20px mb-10px">
                <div test_id="doYouAgreeText">
                    <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.doYouAgreeText">
                    </bb-content-html>
                </div>
                <div test_id="checkboxText">
                    <mat-checkbox id="wss-brg-accept-agreement-checkbox" [ngClass]="!agreementConfirmation && showCheckboxError ? 'checkbox-error' : ''"
                        [checked]="agreementConfirmation" (click)="onCheckboxClick($event)">
                        {{brgAcceptItem.balloonRefinanceGetdetailsAccept.checkboxText}}</mat-checkbox>
                </div>
                <div class="mt-5px text-danger" test_id="confirmationCheckboxError"
                    *ngIf="!agreementConfirmation && showCheckboxError">
                    <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.confirmationCheckboxError">
                    </bb-content-html>
                </div>
            </div>
            <div class="mb-40px">
                <wss-common-nav-buttons [content]="brgAcceptItem.balloonRefinanceGetdetailsNavBtns"
                    [accountsDetails]="accountDetails" [lastDateOfDecision]="lastDateOfDecision"
                    [testIdText]="commonNavTestID" [isAgreementPage]="isAgreementPage"
                    [isPrimaryDisabled]="!agreementConfirmation" [isSecondaryDisabled]="agreementConfirmation"
                    [isWithin14To6DaysOfExpiryDate]="isWithin14To6DaysOfExpiryDate"
                    [lastDateToSignAgreement]="lastDateToSignAgreement"
                    (primaryButtonClicked)="onSaveButtonClick($event)" (continueButtonClicked)="onContinue()">
                </wss-common-nav-buttons>
            </div>
            <div class="mt-25px pb-25px br-btm-grey">
                <bb-content-html [html]="brgAcceptItem.balloonRefinanceGetdetailsAccept.getInTouchText">
                </bb-content-html>
            </div>
        </section>
    </div>
</section>