import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { patternMap } from '@wss/config/wss-app-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatastoreService } from '@wss/service/datastore.service';
import { PaymentService } from "../../services/payment.service";
import { MessageService } from 'libs/service/message.service';
import { SendSMSComponent } from '@wss/common/components/send-sms/send-sms.component';
import { SMSModal } from '@wss/model/sms-modal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { isErrServer, suspendedDateDifference } from '@wss/common/util/util';
import * as moment from 'moment';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { SendSMS } from '@wss/model/send-sms';
import { TealiumUtagService } from '@wss/service/utag.service';
import { LocationStrategy } from '@angular/common';

@Component({
    selector: 'payment-result',
    templateUrl: './payment-result.component.html',
    styles: [
    ],
    providers: [ContentService]
})

export class PaymentResultComponent implements OnInit {

    smsModalContent: Array<any> = [];
    messageForm!: FormGroup;
    showSuccessSection: boolean = true;
    showSMSConfirmFlag: boolean = false;
    showResultSection: any;
    mobileNumber: any;
    settlementDirectionFlag: boolean = false;
    pcpCustomerFlag: boolean = true;
    modalRef: any;
    paymentTransactionDetail: any = {};
    paymentErrorDetail: any;
    smsNotificationConfirmation: any;
    smsNotificationErrorMessage: any;
    showSMSNotificationError: boolean = false;
    paymentUnsuccessfulDeclineInfo: boolean = false;
    resultStatus: any;
    failCount: any = 0;
    paymentGuid: any;
    smsResponse: any;
    readonly destroy$ = new Subject();
    serviceError: boolean = false;
    blockedDate: string = "31/12/2099 23:59";
    smsModal: SMSModal = new SMSModal();
    paymentMadeToday: boolean = false;
    paymentLockout: boolean = false;

    sendSMS: SendSMS = new SendSMS();

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    paymentsWidgetItem: PaymentsWidgetItem = new PaymentsWidgetItem();
    accountDetail: any = {};
    paymentBlockedEarlier: boolean = false;
    paymentSuspendedEarlier: boolean = false;
    paymentBlockedNow: boolean = false;
    paymentSuspendedNow: boolean = false;
    dateDifference: any;
    showPaymentSuccessMessage: boolean = false;
    showPaymentResultContent: boolean = false;
    verifoneUrl: any;
    showBrowserBackPopup: boolean = false;
    browserRefreshFlag: any;

    constructor(private router: Router, private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef,
        private modalService: NgbModal, private datastoreService: DatastoreService,
        public paymentService: PaymentService, private messageService: MessageService, private route: ActivatedRoute,
        private readonly bbContentService: ContentService, private tealiumService: TealiumUtagService,
        private location: LocationStrategy) { }

    ngOnInit() {
        this.onModalOpen('');
        this.messageService.setCurrentStep(0);
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Payment Result Process',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );        
        this.datastoreService.verifoneUrl.pipe(takeUntil(this.destroy$)).subscribe(data => this.verifoneUrl = data);
        if (this.verifoneUrl) {
            location.href = this.verifoneUrl;
        } else {
            this.onModalClose();
            this.handleErrors();
            this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
                this.paymentsWidgetItem = paymentsWidgetItem;
            });
            this.messageForm = this.formBuilder.group({
                mobileNumber: ['', Validators.compose([
                    Validators.pattern(patternMap.mobileNumber)
                ])]
            });
            this.accountDetail = this.datastoreService.getAccountDetails();
            this.failCount = this.datastoreService.getFailCountValue();
            this.processPaymentResultScreen();
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onModalOpen(processingContent: any) {
        this.modalRef = this.modalService.open(processingContent, { windowClass: 'save-card-modal' });
    }

    onModalClose() {
        this.modalRef.close();
    }

    processPaymentResultScreen() {
        if (this.datastoreService.getpaymentErrorDetails()?.reasonCode == "TOO_MANY_PAYMENT") {
            this.resultStatus = 'TOO_MANY_PAYMENT';
            this.showResultSection = 'warningResult';
            this.showPaymentResultContent = true;
        } else {
            this.showPaymentResultContent = false;
            this.resultStatus = this.returnPaymentResultValue();           
            this.paymentMadeToday = this.checkingPaymentMadeToday();
            this.handlingPaymentAllowDateSuspendedFlow();
            this.refreshAccountData();
        }
    }

    returnPaymentResultValue() {
        if (this.route.snapshot.queryParamMap.get("transaction_id")) {
            return 'SUCCESS';
        } else if (this.route.snapshot.queryParamMap.get("error")) {
            return 'FAILED';
        } else if (!this.route.snapshot.queryParamMap.get("transaction_id") && !this.route.snapshot.queryParamMap.get("authentication_id")) {
            localStorage.removeItem('paymentStatus');
            return 'CANCELLED';
        } else if (localStorage.getItem('paymentStatus') == "INVALID_CARD_TYPE") {
            localStorage.removeItem('paymentStatus');
            return 'INVALID_CARD_TYPE';
        } else if (localStorage.getItem('paymentStatus') == "GENERIC_ERROR") {
            localStorage.removeItem('paymentStatus');
            return 'GENERIC_ERROR';
        }
        return null;
    }

    checkingPaymentMadeToday() {
        let alreadyOverpaidAcctNo = localStorage.getItem("overpaymentDoneToday");
        let loggedInAcct = this.datastoreService.getAccountNo();
        let today = moment().format('DD/MM/YYYY');
        let alreadyPaidDate = '';
        if (alreadyOverpaidAcctNo && alreadyOverpaidAcctNo !== '') {
            alreadyPaidDate = alreadyOverpaidAcctNo.split("|")[0];
            alreadyOverpaidAcctNo = alreadyOverpaidAcctNo.split("|")[1];
        }
        if (localStorage.getItem('redirectionFlag') === 'overpayment' && localStorage.getItem("overpaymentDoneToday") && (alreadyOverpaidAcctNo === loggedInAcct) && (alreadyPaidDate === today)) {
            return true;
        }
        else {
            this.updatePayment();
            return false;
        }
    }

    handlingPaymentAllowDateSuspendedFlow() {
        if (!this.accountDetail.allow_payment && this.accountDetail.date_suspended_to !== "") {
            this.paymentLockout = true;
            if (this.accountDetail.date_suspended_to === this.blockedDate) {
                this.paymentBlockedEarlier = true;
                this.paymentBlockedNow = false;
            }
            else {
                this.paymentSuspendedEarlier = true;
                this.paymentSuspendedNow = false;
            }
        } else {
            this.paymentLockout = false;
            this.paymentSuspendedEarlier = false;
            this.paymentBlockedEarlier = false;
        }
    }

    onSmsClick() {
        this.sendSMS.sendSMSTitleText = this.paymentsWidgetItem.paymentResult.paymentSendSms.sendSMSTitleText;
        this.sendSMS.sendSMSHelpNoteText = this.paymentsWidgetItem.paymentResult.paymentSendSms.sendSMSHelpNoteText;
        this.sendSMS.mobileNumberLabelText = this.paymentsWidgetItem.paymentResult.paymentSendSms.mobileNumberLabelText;
        this.sendSMS.mobileNumberPlaceholderText = this.paymentsWidgetItem.paymentResult.paymentSendSms.mobileNumberPlaceholderText;
        this.sendSMS.sendSMSButtonText = this.paymentsWidgetItem.paymentResult.paymentSendSms.sendSMSButtonText;
        this.sendSMS.mobileNumberErrorMessageText = this.paymentsWidgetItem.paymentResult.paymentSendSms.mobileNumberErrorMessageText;

        if (!this.settlementDirectionFlag) {
            this.sendSMS.sendSMSTitleText = this.paymentsWidgetItem.paymentResult.paymentSendSms.sendSMSTitleText;
            this.sendSMS.sendSMSTopBodyText = this.paymentsWidgetItem.paymentResult.paymentSendSms.sendSMSTopBodyText;

        } else if (this.settlementDirectionFlag) {
            this.sendSMS.sendSMSTitleText = this.paymentsWidgetItem.paymentResult.paymentSendSms.sendSMSSettlementTitleText;
            this.sendSMS.sendSMSTopBodyText = this.paymentsWidgetItem.paymentResult.paymentSendSms.sendSMSSettlementTopBodyText;
        }
        this.sendSMS.smsType = this.settlementDirectionFlag ? "SPR" : "PR";
        this.messageService.setSendSMSContentData(this.sendSMS);
        this.modalRef = this.modalService.open(SendSMSComponent, { windowClass: 'save-card-modal' });
        this.modalRef.componentInstance.smsModal = this.smsModal;

        this.messageService.getSMSResponse().subscribe((response: any) => {
            this.smsResponse = response;
            if (this.smsResponse.code === "OK") {
                this.showSMSConfirmFlag = true;
                this.smsNotificationConfirmation = this.smsResponse.message;
            } else {
                this.showSMSNotificationError = true;
                this.smsNotificationErrorMessage = this.smsResponse.message;
            }
            this.changeDetectorRef.detectChanges();
        });
    }
    backToMyAccountClick() {
        this.messageService.setActiveNav("My account");
        this.router.navigate(['myaccount']);
    }

    backToHomeClick() {
        this.messageService.setActiveNav("Payments");
        this.router.navigate(['payment-menu']);
    }

    onSettlementPaymentResult() {
        let settlementValue = localStorage.getItem('redirectionFlag');
        if (settlementValue === 'settlement') {
            this.settlementDirectionFlag = true;
        } else if (settlementValue === 'paymentMenu') {
            this.settlementDirectionFlag = false;
        }

        let paymentDetail: any = localStorage.getItem("paymentUpdateDetails");
        paymentDetail = paymentDetail && JSON.parse(paymentDetail);
        this.paymentTransactionDetail = paymentDetail;

        let paymentErrorDetail: any = localStorage.getItem("paymentErrorDetails");
        paymentErrorDetail = paymentErrorDetail && JSON.parse(paymentErrorDetail);
        this.paymentErrorDetail = paymentErrorDetail;

        this.paymentGuid = this.paymentTransactionDetail?.guid;

        if ((this.paymentTransactionDetail && this.paymentTransactionDetail.auth_message === 'AUTH CODE:789DE') || this.resultStatus === 'SUCCESS') {
            this.paymentGuid = this.paymentTransactionDetail.guid;
            this.showResultSection = 'successResult';
        } else if (this.paymentErrorDetail && this.paymentErrorDetail.reasonCode && (this.paymentErrorDetail.reasonCode === 'CUSTOMER_NOT_FOUND')) {
            this.showResultSection = 'warningResult';
        } else if ((this.paymentErrorDetail && this.paymentErrorDetail.reasonCode && (this.paymentErrorDetail.reasonCode === 'TXN_FAILED' || this.paymentErrorDetail.reasonCode === 'VERIFONE_ERROR' || this.paymentErrorDetail.reasonCode === 'INVALID_DATA') || this.resultStatus === 'FAILED' || this.resultStatus === 'REFERRAL')) {
            this.datastoreService.setFailCountValue(this.failCount + 1);
            this.dateDifference = suspendedDateDifference(this.accountDetail.date_suspended_to);
            if (this.failCount > 2) {
                if ((this.failCount > 2 && this.failCount < 6) && (this.dateDifference > 0 && this.dateDifference < 3)) {
                    this.paymentSuspendedEarlier = true;
                    this.paymentSuspendedNow = false;
                } else if (this.dateDifference > 3) {
                    this.paymentBlockedEarlier = true;
                    this.paymentBlockedNow = false;
                }
                this.paymentLockout = true;
            } else {
                this.paymentUnsuccessfulDeclineInfo = true;
                this.paymentLockout = false;
                this.paymentSuspendedEarlier = false;
                this.paymentBlockedEarlier = false;
            }
            this.showResultSection = 'unsuccessResult';
        } else if (this.resultStatus === 'CANCELLED') {
            this.showResultSection = 'cancelledResult';
        } else if (this.resultStatus === 'GENERIC_ERROR' || this.paymentErrorDetail.reasonCode) {
            this.showResultSection = 'genericError';
        } else if (this.resultStatus === 'INVALID_CARD_TYPE' || this.paymentErrorDetail.reasonCode === "INVALID_CARD_TYPE") {
            this.showResultSection = 'invalidCardType';
        } else if (this.resultStatus === 'TOO_MANY_PAYMENT') {
            this.showResultSection = 'warningResult';
        }
        this.changeDetectorRef.detectChanges();
    }

    sendEmailConfirmation() {
        this.paymentService.sendEmailConfirmation().subscribe((data) => {
        })
    }

    handleErrors() {
        this.messageService.getServiceError().subscribe((error: any) => {
            if (error) {
                this.showPaymentResultContent = false;
                if (error.reasonCode && error.reasonCode === 'SMS_ERROR') {
                    this.smsNotificationErrorMessage = error.message;
                    this.showSMSNotificationError = true;
                } else if (error.reasonCode === "TOO_MANY_PAYMENT") {
                    this.showPaymentResultContent = true;
                    this.showResultSection = 'warningResult';
                } else if (error.reasonCode === "INVALID_CARD_TYPE") {
                    this.showPaymentResultContent = true;
                    this.showResultSection = 'invalidCardType';
                    localStorage.setItem("paymentStatus", "INVALID_CARD_TYPE");
                } else if (error.reasonCode === "INVALID_DATA" && this.resultStatus == 'CANCELLED') {
                    this.showPaymentResultContent = true;
                    this.showResultSection = 'cancelledResult';
                    localStorage.setItem("paymentStatus", "CANCELLED");
                } else {
                    this.showPaymentResultContent = true;
                    this.showResultSection = 'genericError';
                    localStorage.setItem("paymentStatus", "GENERIC_ERROR");
                }
                this.showError(error.status);
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    updatePayment() {
        if (this.failCount > 2) {
            this.resultStatus = "FAILED";
        }
        let cardPaymentDetails: any = localStorage.getItem('cardPaymentsResponse');
        cardPaymentDetails = JSON.parse(cardPaymentDetails);
        if (this.resultStatus && this.resultStatus != 'TOO_MANY_PAYMENT') {
            let payLoadBody = {
                'result': this.resultStatus,
                'transaction_id': this.route.snapshot.queryParamMap.get("transaction_id") ? this.route.snapshot.queryParamMap.get("transaction_id") : '',
                'authentication_id': this.route.snapshot.queryParamMap.get("authentication_id") ? this.route.snapshot.queryParamMap.get("authentication_id") : '',
                'checkout_id': cardPaymentDetails.checkout_id,
                'error': this.route.snapshot.queryParamMap.get("error") ? this.route.snapshot.queryParamMap.get("error") : '',
            }
            this.paymentService.updatePaymentResultDetails(payLoadBody).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
                this.showPaymentResultContent = true;
                this.paymentTransactionDetail.receipt_number = data.payment_detail.receipt_number;
                if (this.resultStatus === 'SUCCESS') {
                    this.showPaymentSuccessMessage = true;
                    this.datastoreService.setPaymentSuccessReciptNumber(data.payment_detail.receipt_number);
                    this.sendEmailConfirmation();
                } else {
                    this.showPaymentSuccessMessage = false;
                }
                if (data.payment_detail) {
                    this.dateDifference = suspendedDateDifference(data.payment_detail.date_suspended_to);
                    if ((JSON.parse(data.payment_detail.no_of_failed_attempts) > 2 || this.failCount > 2)
                        && this.dateDifference > 0) {
                        this.paymentLockout = true;
                    } else {
                        this.paymentLockout = false;
                    }
                    if (JSON.parse(data.payment_detail.no_of_failed_attempts) > 5 || this.failCount > 5) {
                        this.paymentBlockedNow = true;
                        this.paymentBlockedEarlier = false;
                    }
                    else if ((data.payment_detail.date_suspended_to !== "" && data.payment_detail.date_suspended_to !== this.blockedDate
                        && (JSON.parse(data.payment_detail.no_of_failed_attempts) > 2 && JSON.parse(data.payment_detail.no_of_failed_attempts) < 6
                            || (this.failCount > 2 && this.failCount < 6)) && !this.paymentSuspendedEarlier && this.dateDifference > 0)) {
                        this.paymentSuspendedNow = true;
                    }
                }

                if (data.payment_detail.receipt_number && localStorage.getItem('redirectionFlag') === 'overpayment') {
                    localStorage.setItem("overpaymentDoneToday", moment().format('DD/MM/YYYY') + "|" + this.datastoreService.getAccountNo());
                }
                this.changeDetectorRef.detectChanges();
            },
                (error: any) => {
                    this.changeDetectorRef.detectChanges();
                });
            this.onSettlementPaymentResult();
        }
    }

    makeAPaymentClick() {
        this.messageService.setActiveNav("Payments");
        this.router.navigate(['payment-menu']);
    }
    closeSMSInfo(value: any) {
        if (value === 'success') {
            this.showSMSConfirmFlag = false;
        } else if (value === 'error') {
            this.showSMSNotificationError = false;
        }
    }

    showError(errStatus: number): void {
        if (isErrServer(errStatus)) {
            this.datastoreService.setErrorServer();
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    refreshAccountData() {
        this.paymentService.refreshAccountData();
    }

    proceedClick() {
        this.showBrowserBackPopup = false;
        location.href = '#/payments/payment-menu';
    }

    closePopup() {
        this.showBrowserBackPopup = false;
    }
}
