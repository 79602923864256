import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { injectBrandDetailIntoPage } from '@wss/common/util/util';
import { BalloonRefinanceItem } from '@wss/model/balloon-refinance/balloon-refinance';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable, Subject } from 'rxjs';
import { ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { BalloonRefinanceService } from '../../services/balloon-refinance.service';
import { takeUntil } from 'rxjs/operators';
import { proposalStatus } from '@wss/config/wss-app-constants';

@Component({
  selector: 'wss-br-get-started',
  templateUrl: './br-get-started.component.html'
})
export class BrGetStartedComponent implements OnInit {

  balloonRefinanceWidgetItem$: Observable<BalloonRefinanceItem | undefined> = this.bbContentService.getContent<BalloonRefinanceItem>('balloonRefinanceWidgetItem');
  brWidgetItem: any = new BalloonRefinanceItem();
  nextPageRoute: string = 'balloon-refinance-getdetails/vehicle';
  previousPageLink: string = '/balloon-refinance/get-a-quote';
  accountDetails: any;
  lastDateOfDecision: any;
  commonNavTestID: string = 'wss-Br-GetStarted-';
  serviceError: boolean = false;

  private updateProposalData: ProposalRequest = {
    customer_id: null,
    account_number: null,
    palis_proposal_number: null,
    proposal_date: null,
    proposal_updated: null,
    proposal_status: null,
    replace_existing_account: null,
    proposed_term: null,
    vehicle_use: null,
    mileage: null,
    resident: null,
    marital: null,
    change_in_circumstances: null,
    perm_employee: null,
    full_time: null,
    employment_type: null,
    occupation: null,
    employer_address_1: null,
    employer_address_2: null,
    employer_address_3: null,
    employer_address_4: null,
    gross_annual_income: null,
    mortgage_rental_expenditure: null,
    other_expenditure: null,
    bank_sort_code: null,
    bank_account_number: null,
    bank_account_name: null,
    years_with_bank: null,
    months_with_bank: null,
    months_at_employment: null,
    years_at_employment: null,
    employer_name: null,
    county:  null,
    district: null,
    flat_number: null,
    house_name: null,
    house_number: null,
    postcode: null,
    street: null,
    town: null,
    nationality: null,
    no_of_dependants: null,
    proposal_id: null,
    palisCustomerId: null,
    IsDecision_Changed: null,
    IsRejectedByCustomer: null
  };

  readonly destroy$ = new Subject();
  proposalData: any;

  constructor(
    private readonly bbContentService: ContentService,
    private changeDetectorRef: ChangeDetectorRef,
    private datatstoreSrv: DatastoreService,
    private router: Router,
    private tealiumService: TealiumUtagService,
    private balloonRefinanceSrv: BalloonRefinanceService

  ) { 
    this.proposalData = this.datatstoreSrv.getProposalRequest();
  }

  ngOnInit(): void {
    this.tealiumService.view({ tealium_event:'Balloon_Refinance_getstarted', URI: '/dashboard#/balloon-refinance/get-started' });
    this.accountDetails = this.datatstoreSrv.getAccountDetails();
    this.lastDateOfDecision = this.datatstoreSrv.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
    this.getContentData();
  }

  getContentData() {
    this.balloonRefinanceWidgetItem$.subscribe((pageItem: any) => {
      this.brWidgetItem = pageItem;
      this.brWidgetItem.balloonRefinanceGetStarted = injectBrandDetailIntoPage(this.brWidgetItem.balloonRefinanceGetStarted, this.brWidgetItem?.brandContactDetails)
      this.changeDetectorRef.detectChanges();
    });   
  }

  saveDataProposal(urlElem: string) {    
    this.updateProposalData.customer_id = this.datatstoreSrv.getCustomerId();
    this.updateProposalData.account_number = this.accountDetails?.account_number;
      this.getTerm();
      this.balloonRefinanceSrv.postProposalDetails(this.updateProposalData).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.updateProposalData = this.datatstoreSrv.getProposalRequest();
        this.router.navigate([urlElem]);
      }, 
      (error: any) => {
        this.showError();
      });
  }

  getTerm() {
    let getQuoteData = this.datatstoreSrv.getQuoteData();
    this.updateProposalData.proposed_term = getQuoteData.termLength;    
    this.datatstoreSrv.setProposalRequest(this.updateProposalData);
  }
  
  showError(): void {
    this.datatstoreSrv.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  onCommonNavButtonClick(e: any) {
    if (this.proposalData?.proposal_status) {
      this.routeBasedOnDecision();
    } else {
      this.saveDataProposal('myaccount');
    }
  }

  onContinue(e: any) {
    if (this.proposalData?.proposal_status) {
      this.routeBasedOnDecision();
    } else {
      this.saveDataProposal(this.nextPageRoute);
    }
  }

  routeBasedOnDecision() {
    const route = this.proposalData?.proposal_status === proposalStatus.incomplete ? '/balloon-refinance/cta-expired-eoc-period' : '/myaccount';
    this.router.navigate([route]);
  }

}
