<ng-container *ngIf="helpCentreWidgetItem">   
    <div class="help-centre-widget-container">
        <div class="help-centre-landing-container">
            <div class="container-column">
                <div class="help-centre-title">
                    <span class="section-label-title" test_id="pageName">
                        {{helpCentreWidgetItem.helpCentreLanding.pageName}}</span>
                </div>
                <div class="customer-support-text margin-top-30">
                    <div test_id="pageHeaderBelowText">
                        <bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.pageHeaderBelowText"></bb-content-html>
                    </div>
                </div>
            </div>

            <!-- <bb-help-centre-support></bb-help-centre-support> -->
            <div class="help-centre-support container-row margin-top-20">
                <!-- Dynamic accordion -->
                <bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreLanding.mainAccordionContent" [testIDText]="'helpCentreLandingMainAccordion'"></bb-common-expansion-panel>
            </div>

            <!-- <bb-help-links-container></bb-help-links-container> -->
            <div class="get-help-container">
                <div class="get-help-title margin-top-30" test_id="helpCentreLandingSecondHeaderTitle">
                    <bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.secondHeaderTitle"></bb-content-html>
                </div>
                <div class="container-row arrear-box-height margin-top-20" (click)="onMakePaymentClick()">
                    <div class="arrear-info-box text-center">
                    </div>
                    <div class="container-row arrear-text-context">
                        <div id="makePaymentTitle-btn" class="container-row arrear-text-margin get-help-link">
                            <!-- <svg style="flex: 0.3;" class="info-icon" xmlns="http://www.w3.org/2000/svg" width="32px" height="26px"
                                viewBox="0 0 32 26">
                                <path fill="#616161" [attr.d]="svgHelpWithMakePayment" />
                            </svg> -->
                            <span class="wss-help-payment-icon"></span>
                            <span class="arrear-flex-width" test_id="makePaymentTitle">
                                <bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.makePaymentTitle"></bb-content-html>
                            </span>
                        </div>
                    </div>
                </div>
            
                <div class="container-row arrear-box-height margin-top-20" (click)="onMyAgreementClick()">
                    <div class="arrear-info-box">
                    </div>
                    <div class="container-row arrear-text-context">
                        <div id="yourAgreementTitle-btn" class="container-row arrear-text-margin get-help-link">
                            <!-- <svg style="flex: 0.3;" class="info-icon" xmlns="http://www.w3.org/2000/svg" width="32px" height="26px"
                                viewBox="0 0 32 26">
                                <path fill="#616161" [attr.d]="svgAssignment" />
                            </svg> -->

                            <span class="wss-assignment-icon"></span>
                            <span class="arrear-flex-width" test_id="yourAgreementTitle">
                                <bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.yourAgreementTitle"></bb-content-html>
                            </span>
                        </div>
                    </div>
                </div>
            
                <div class="container-row arrear-box-height margin-top-20" (click)="onManageAccountClick()">
                    <div class="arrear-info-box">
                    </div>
                    <div class="container-row arrear-text-context">
                        <div id="managingAccountTitle-btn" class="container-row arrear-text-margin get-help-link">
                            <!-- <svg style="flex: 0.3;" class="info-icon" xmlns="http://www.w3.org/2000/svg" width="32px" height="26px"
                                viewBox="0 0 32 26">
                                <path fill="#616161" [attr.d]="svgAgreement" />
                            </svg> -->
                            <span class="wss-agreement-icon"></span>
                            <span class="arrear-flex-width" test_id="managingAccountTitle">
                                <bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.managingAccountTitle"></bb-content-html>
                            </span>
                        </div>
                    </div>
                </div>
            
                <div class="container-row arrear-box-height margin-top-20" (click)="onMoneyWorriesClick()">
                    <div class="arrear-info-box">
                    </div>
                    <div class="container-row arrear-text-context">
                        <div id="moneyWorriesTitle-btn" class="container-row arrear-text-margin get-help-link">
                            <!-- <svg style="flex: 0.3;" class="info-icon" xmlns="http://www.w3.org/2000/svg" width="32px" height="26px"
                                viewBox="0 0 32 26">
                                <path fill="#616161" [attr.d]="svgHelpWithPayment" />
                            </svg> -->
                            <span class="wss-help-payment-icon"></span>
                            <span class="arrear-flex-width" test_id="moneyWorriesTitle">
                                <bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.moneyWorriesTitle"></bb-content-html>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <bb-help-extra-support></bb-help-extra-support> -->
            <div class="extra-support-text margin-top-30">
                <div test_id="helpCentreLandingsecondHeaderBelowText">
                    <strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.secondHeaderBelowText"></bb-content-html></strong>
                </div>
            
                <div *ngIf="portalName === 'blackhorse'" class="white-container-column extra-support-section margin-top-20">
                    <div class="icon-div wss-pdf-icon margin-top-0"></div>
                        <div class="text-center" test_id="helpCentreLandingViewTheGuideTitle">
                            <strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.viewTheGuideTitle"></bb-content-html></strong>
                        </div>
                        <div class="customer-help-text text-center margin-top-10" test_id="helpCentreLandingViewTheGuideText">
                            <bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.viewTheGuideText"></bb-content-html>
                        </div>
                        <div class="content-center margin-bottom margin-top-10 d-flex justify-content-center">
                            <button id="viewTheGuideSupportButton" class="wss-button-secondary access-help-support-btn" (click)="viewGuidePDF()" test_id="viewTheGuideButtonText">
                                {{helpCentreWidgetItem.helpCentreLanding.viewTheGuideButtonText}}
                            </button>
                        </div>
                </div>
            
                <div [ngClass]="portalName === 'blackhorse' ? 'white-container-column extra-support-section margin-top-20' : 'white-single-container-column extra-support-section margin-top-30'">
                    <div class="icon-div wss-get-in-touch-icon"></div>
                    <div class="contact-us text-center" test_id="helpCentreLandinggetInTouchTitle">
                        <strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.getInTouchTitle"></bb-content-html></strong>
                    </div>
                    <div class="customer-help-text text-center margin-top-10" test_id="helpCentreLandinggetInTouchText">
                        <bb-content-html [html]="helpCentreWidgetItem.helpCentreLanding.getInTouchText"></bb-content-html>
                    </div>
                    <div class="content-center margin-bottom margin-top-10 d-flex justify-content-center">
                        <button id="getInTouchButton" class="wss-button-secondary get-in-touch-btn" 
                            (click)="getInTouchCall()" test_id="getInTouchButtonText">
                            {{helpCentreWidgetItem.helpCentreLanding.getInTouchButtonText}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>