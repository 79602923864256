import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { ContentService } from '@backbase/universal-ang/content';
import { DatastoreService } from '../../../../service/datastore.service';
import { Observable, Subject } from 'rxjs';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { getPortalName, isEmpOrNullOrUndef, isErrServer, isPreviouslyStartedBrJourney, setResumeBrApplciationLink } from '@wss/common/util/util';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'libs/service/message.service';
import { appConfig, proposalStatus } from '@wss/config/wss-app-constants';
import { TealiumUtagService } from '@wss/service/utag.service';
import * as moment from 'moment';
import { ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { BannerMessage } from '@wss/model/bannerMessage';

@Component({
  selector: 'bb-eoc-landing',
  templateUrl: 'eoc-landing.component.html',
  providers: [ContentService]
})

export class EocLandingComponent implements OnInit {

  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');

  accountDetail: any = {};
  withinNinteyDays: boolean = false;
  lastDateForDecision: any;
  eocCompletionDate: any;
  //accountDetail: any;
  serviceError: boolean = false;
  isOptionSelected: boolean = false;
  readonly destroy$ = new Subject();
  withinNinetyDays: boolean = false;
  fileTagFlag: boolean = false;
  inGoodsScopeBR: boolean = false;
  isEligibleForBalloonRefinance: boolean = true;
  proposalDetails: ProposalRequest;
  bannerMessage: BannerMessage = new BannerMessage();
  isEocLandingPageBanner: boolean = true;
  isResumableBrApplication: boolean = false;
  resumeBrApplicationLink: string ='';
  resumeBrApplicationLinkText: string ='';
  continueApplicationText: string = '';
  isEocLandingPageResumeBrAppBannerClosed: boolean = false;
  decisionData: any;
  eocBrReturnAndSignText: any;
  proposalStatus: any;
  isDecisionChanged: any;
  reviewYourApplicationResultText: any;

  constructor(
    private router: Router,
    private readonly bbContentService: ContentService,
    private eocService: EndOfContractService,
    private datastoreService: DatastoreService,
    private messageService: MessageService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef
  ) { 
    this.proposalDetails = this.datastoreService.getProposalRequest();
  }

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'End of Contract Landing',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.messageService.setActiveNav("End of contract");
    this.accountDetail = this.datastoreService.getAccountDetails();
    // remove below code
    // this.accountDetail = this.eocDetails;
    this.lastDateForDecision = this.accountDetail?.final_payment_date ? this.eocService.getLastDateForDecision(this.accountDetail?.final_payment_date) : '';
    this.eocCompletionDate = this.accountDetail?.final_payment_date ? new Date(this.eocService.getFormattedDate(this.accountDetail?.final_payment_date)) : '';
    this.isOptionSelected = this.accountDetail?.customer_intention === 'R' || this.accountDetail?.customer_intention === 'T' || this.accountDetail?.customer_intention === 'F' || this.accountDetail?.customer_intention === 'P' || this.accountDetail?.customer_intention === 'E';
    this.withinNinetyDays = this.eocService.isContractWithinNintyDays();
    this.fileTagFlag = appConfig.fileTags.includes(this.accountDetail?.file_tag);
    this.inGoodsScopeBR = appConfig.goodsCodesForBR.includes(this.accountDetail?.goods_code);
    this.isEligibleForBalloonRefinance = this.isValidForBalloonRefinance();
    this.proposalStatus = this.proposalDetails?.proposal_status;
    this.isDecisionChanged = this.proposalDetails?.IsDecision_Changed === null ? false : this.proposalDetails?.IsDecision_Changed;
    if (this.proposalDetails && !(this.proposalStatus == proposalStatus.declined && this.isDecisionChanged === false) && isEmpOrNullOrUndef(this.accountDetail?.customer_intention.trim()) && 
    this.proposalDetails?.IsRejectedByCustomer !== true) {
      this.setBrResumeApplicationBanner();
    }
    this.isEocLandingPageResumeBrAppBannerClosed = this.datastoreService.getIsEocLandingPageResumeBrAppBannerClosed();
  }

  navigateToOption(url: string) {
    this.datastoreService.setEocRoute(url);
    this.messageService.setActiveNav("End of contract");
    this.router.navigate(['/eoc-options/' + url]);
    window.scroll(0, 0);
  }

  isValidForBalloonRefinance() {
    const brandName = getPortalName();
    return this.accountDetail?.pcp_ind && this.accountDetail?.regulated_ind && this.accountDetail.palis_scheme !== '5A' && appConfig.brandsEligibleForBalloonRefinance.includes(brandName) && this.inGoodsScopeBR;
  }

  handleErrors() {
    this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
      this.showError(error?.status);
    });
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  navigateToBrPage() {
    if (this.accountDetail?.customer_intention === 'E') {
      this.router.navigate(['/balloon-refinance-getdetails/mod-ag-next-step'])
    } else if (this.isOptionSelected && this.accountDetail?.customer_intention !== 'E') {
      this.router.navigate(['/eoc-options/get-in-touch']);
    } else if (moment().isAfter(this.lastDateForDecision)) {
      this.router.navigate(['/balloon-refinance/cta-expired-eoc-period']);
    } else if (this.proposalStatus === proposalStatus.declined && this.isDecisionChanged === false) {
      this.router.navigate(['/balloon-refinance-getdetails/post-decline']);
    } else {
      this.router.navigate(['/balloon-refinance/landing']);
    }
  }

  setBrResumeApplicationBanner() {
    this.isResumableBrApplication = isPreviouslyStartedBrJourney(this.proposalDetails);
    this.resumeBrApplicationLink = setResumeBrApplciationLink(this.proposalDetails, this.accountDetail);
    this.changeDetectorRef.detectChanges();

    this.bannerMessage.closeIconClass = "clear";
    this.bannerMessage.infoTypeClass = "icon_warining_amber";
    this.bannerMessage.leftSectionColorClass = "message-warning";
    this.bannerMessage.materialIconClass = "warning_amber";
    
    this.endOfContractWidgetItem$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.continueApplicationText = res?.endOfContractLanding?.currentOngoingRefinanceApplicationText;
      this.resumeBrApplicationLinkText = res?.endOfContractLanding?.continueApplicationLinkText;
      this.bannerMessage.isBannerClosed = false;
      this.messageService.setBannerMessage(this.bannerMessage);
      this.eocBrReturnAndSignText = res?.endOfContractLanding?.returnAndSignText;
      this.reviewYourApplicationResultText = res?.endOfContractLanding?.reviewYourApplicationResultText;
      this.changeDetectorRef.detectChanges();
    })
  }

}
