<div class="acct-smry-widget" *ngIf="dashboardWidgetItem$ | async as dashboardWidgetItem">
    <div class="content-margin">
        <div class="content-wrapper-container">
            <div class="reg-row plr-10pt" role="alert" *ngIf="serviceError">
                <bb-wss-banner-message></bb-wss-banner-message>
            </div>
            <div class="reg-row" role="alert" *ngIf="!serviceError && isArrearMsgHidden && showEocBanner && !isShowEocBannerForSeletedOption">
                <bb-wss-banner-message [eocDetails]="accountDetail" [eocFlag]="within90days" bannerType="eocPCPBanner"
                    [eocLinkText]="dashboardWidgetItem.bannerMessage.eocOptionLinkText"
                    [eocBrContinueJourneyText]="dashboardWidgetItem.bannerMessage.eocBrContinueJourneyText"
                    [eocBrReturnAndSignText]="dashboardWidgetItem.bannerMessage.eocBrReturnAndSignText"
                    [resumeBrApplicationLink]="resumeBrApplicationLink" [proposalStatus]="proposalStatus"
                    [isResumableBrApplication]="isResumableBrApplication" [isDecisionChanged]="isDecisionChanged"
                    [reviewYourApplicationResultText]="reviewYourApplicationResultText">
                </bb-wss-banner-message>
            </div>
            <div class="reg-row" role="alert" *ngIf="!serviceError && showEocBallonRefinaryBanner  && !isShowEocBannerForSeletedOption">
                <bb-wss-banner-message [eocDetails]="accountDetail" eocFlag="true" bannerType="eocHPBanner"
                    [eocLinkText]="dashboardWidgetItem.bannerMessage.eocBalloonRefineryText"></bb-wss-banner-message>
            </div>
            
            <div class="reg-row" role="alert" *ngIf="!serviceError && isShowEocBannerForSeletedOption">
                <bb-wss-banner-message [eocDetails]="accountDetail" eocFlag="true"  bannerType="eocIntentionSelectedBanner"
                [linkText]="dashboardWidgetItem.bannerMessage.nextSteps" [routeUrlInBanner]='routeUrlInBanner' [eocOptionBannerLinkId]="eocOptionBannerLinkId"></bb-wss-banner-message>
            </div>

            <div class="reg-row" role="alert" 
                *ngIf="!serviceError && isArrearMsgHidden && !showEocBanner && !showEocBallonRefinaryBanner && !isShowEocBannerForSeletedOption && isShowContactPreferenceBanner">
                    <bb-wss-banner-message  
                        [linkText]="dashboardWidgetItem.contactPreferenceBanner.linkText" 
                        [contactPreferenceFlag]="true" 
                        [routeUrlInBanner]="routeUrlInBanner">
                    </bb-wss-banner-message>
            </div>
            <bb-wss-contact-preference-popup *ngIf="isShowContactPreferencePopup" [contactPreferencePopupContent]="dashboardWidgetItem.contactPreferencePopup"></bb-wss-contact-preference-popup>

            <bb-wss-paperless-account-popup *ngIf="isFirstLogin && !isShowContactPreferencePopup"
                [paperlessPopupContent]="dashboardWidgetItem.paperlessPopup">
            </bb-wss-paperless-account-popup>
            <bb-wss-paperless-account-info *ngIf="isArrearMsgHidden && !showEocBanner && !showEocBallonRefinaryBanner && !isShowEocBannerForSeletedOption && !isShowContactPreferenceBanner && paperlessBannerFlag"
                [paperlessBanner]="dashboardWidgetItem.paperlessBanner">
            </bb-wss-paperless-account-info>
            <bb-wss-account-in-arrear  
                [arrearMessage]="dashboardWidgetItem.arrearMessage" [arrearData]="accountSummary.arrearData"
                *ngIf="!isArrearMsgHidden"></bb-wss-account-in-arrear> 
            <div class="agreement-speedometer-wrapper">
                <div class="agreement-wrapper" *ngIf="!largeScreen">
                    <bb-wss-my-agreement [customerDetail]="customerDetail"
                        [myAgreementData]="accountSummary.myAgreementData"
                        [greetingText]="dashboardWidgetItem.greetingText"
                        [isPcpToHpBalloonRefinanceFlag]="isPcpToHpBalloonRefinanceFlag"
                        [leasePurchaseText]="dashboardWidgetItem.leasePurchaseTxt"
                        [isLPAgreementFlag]="isLPAgreementFlag">
                    </bb-wss-my-agreement>
                </div>
                <div class="speedometer-container" *ngIf="isSpeedometerActive">
                    <bb-wss-speedometer [speedometerContent]="dashboardWidgetItem.speedometer"
                        [speedometerData]="accountSummary.speedometerData" [accountDetail]="accountDetail" [isPcpToHpBalloonRefinanceFlag]="isPcpToHpBalloonRefinanceFlag" [isLPAgreementFlag]="isLPAgreementFlag">
                    </bb-wss-speedometer>
                    <div class="container-row content-center button-link">
                        <!-- <button class="wss-button" test_id="request_settlement_id" title="Get a new settlement quote" (click)="navigate('/payments/payment-settlement')">{{content[0]?.settlement}}</button> -->
                        <button *ngIf="portalName === 'jaguar'" class="btn wss-button" test_id="request_settlement_id" title="Get a new settlement quote"
                            (click)="navigate('/payments/payment-settlement')">{{dashboardWidgetItem.settlementButtonText}}</button>
                        <button *ngIf="portalName !== 'jaguar'" class="wss-button" test_id="request_settlement_id" title="Get a new settlement quote"
                        (click)="navigate('/payments/payment-settlement')">{{dashboardWidgetItem.settlementButtonText}}</button>
                    </div>
                    <div class="container-row content-center make-payment-container" test_id="paymentLink">
                        <bb-content-html [html]="dashboardWidgetItem.paymentLink"></bb-content-html>
                        <!-- <span class="make-a-payment-link"><a test_id="make_a_payment_id" title="Make payment" (click)="navigate('/payments')">{{content[0]?.make}}</a></span> -->
                    </div>
                </div>
                <div class="agreement-wrapper" *ngIf="largeScreen">
                    <bb-wss-my-agreement [customerDetail]="customerDetail"
                        [myAgreementData]="accountSummary.myAgreementData"
                        [greetingText]="dashboardWidgetItem.greetingText"
                        [hpSchemeDescriptionText]="dashboardWidgetItem.hpSchemeDescriptionText"
                        [leasePurchaseText]="dashboardWidgetItem.leasePurchaseTxt"
                        [isPcpToHpBalloonRefinanceFlag]="isPcpToHpBalloonRefinanceFlag"
                        [isLPAgreementFlag]="isLPAgreementFlag">
                    </bb-wss-my-agreement>
                    <div class="container-row content-center button-link">
                        <!-- <button class="wss-button" test_id="request_settlement_id" title="Get a new settlement quote" (click)="navigate('/payments/payment-settlement')">{{content[0]?.settlement}}</button> -->
                        <button *ngIf="portalName === 'jaguar'" class="btn wss-button" test_id="request_settlement_id" title="Get a new settlement quote"
                            (click)="navigate('/payments/payment-settlement')">{{dashboardWidgetItem.settlementButtonText}}</button>
                        <button *ngIf="portalName !== 'jaguar'" class="wss-button" test_id="request_settlement_id" title="Get a new settlement quote"
                        (click)="navigate('/payments/payment-settlement')">{{dashboardWidgetItem.settlementButtonText}}</button>
                    </div>
                    <div class="container-row content-center make-payment-container" test_id="paymentLink">
                        <bb-content-html [html]="dashboardWidgetItem.paymentLink"></bb-content-html>
                        <!-- <span class="make-a-payment-link"><a test_id="make_a_payment_id" title="Make payment" (click)="navigate('/payments')">{{content[0]?.make}}</a></span> -->
                    </div>
                </div>
            </div>
            <div class="acc-summary-bottom-wrapper">
                <div class="contract-payment-container">
                    <div class="contract-payment-wrapper">
                        <div class="contract-detail-container">
                            <bb-wss-contract [contractData]="accountSummary.contractData" [iconColor]="svgIconColor" [svgInfoIcon]="svgInfoIcon"
                                [myAgreementContent]="dashboardWidgetItem.myAgreement"></bb-wss-contract>
                                <span>
                                    <button class="wss-button-secondary" (click)="navigateToManageAgreement()" id="wss-nav-manage-agreement">{{ dashboardWidgetItem.myAgreement.manageYourAgreementButtonText }}</button>
                                </span>
                        </div>
                        <div class="next-payment-detail-container">
                            <bb-wss-next-payment [nextPaymentContent]="dashboardWidgetItem.nextPaymentContent"
                                [accountDetail]="accountDetail" [nextPaymentData]="accountSummary.nextPaymentData">
                            </bb-wss-next-payment>
                            <span class="transaction-btn-align">
                                <button class="wss-button-secondary" title="View transactions" test_id="view_transactions_id"
                                (click)="navigate('/transactions')">{{dashboardWidgetItem.transactionButtonText}}</button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="additional-info-wrapper" *ngIf="accountDetail">
                    <bb-wss-additional-info [additionalInfoData]="accountSummary.additionalInfoData" [iconColor]="svgIconColor"
                        [svgInfoIcon]="svgInfoIcon" [additionalInfoContent]="dashboardWidgetItem.additionalInfo"
                        [accountDetail]="accountDetail" [isPcpToHpBalloonRefinanceFlag]="isPcpToHpBalloonRefinanceFlag" [isLPAgreementFlag]="isLPAgreementFlag">
                    </bb-wss-additional-info>
                </div>
            </div>
            <div class="carbon-offset-wrapper" *ngIf="showCarbonOffsetContent">
                <bb-wss-carbon-ioffset [carbonIoffsetBanner]="dashboardWidgetItem.carbonIoffset"></bb-wss-carbon-ioffset>
            </div>
        </div>
    </div>
</div>