<div class="container-row banner-message-box" *ngIf="!messageData?.isBannerClosed"
    id="bannerMsgId" (click)="openModal(msgBannerModal);">
    <div [ngClass]="messageData.leftSectionColorClass === ''? 'container-row section1': 'container-row section1 ' + messageData.leftSectionColorClass">
        <span *ngIf="messageData?.materialIconClass === ''" class="{{messageData?.infoTypeClass}}"></span>
        <span role="img" class="material-icons-outlined {{messageData?.iconPositionClass}}" *ngIf="messageData?.materialIconClass !== '' && archiveFlag" aria-label="Banner attention icon">{{messageData?.materialIconClass}}</span>
        <span role="img" class="material-icons {{messageData?.iconPositionClass}}" *ngIf="messageData?.materialIconClass !== '' && !eocFlag && !archiveFlag && !isEocLandingPageBanner && !isBrLandingPageBanner" aria-label="Banner attention icon">{{messageData?.materialIconClass}}</span>
        <span role="img" class="material-icons-outlined {{messageData?.iconPositionClass}}" *ngIf="messageData?.materialIconClass !== '' && eocFlag || isEocLandingPageBanner || isBrLandingPageBanner" aria-label="Banner attention icon">{{messageData?.materialIconClass}}</span>
        
    </div>
    <div [ngClass]="messageData.rightSectionColorClass === ''? 'container-column section2': 'container-column section2 ' + messageData.rightSectionColorClass">
        <div class="container-column section2-inner">
            <span class="para-inline" *ngIf="titleText !== '' && !errorCode" [innerHTML]="titleText"></span>
            <span *ngIf="titleText !== '' && errorCode">
                <div class="para-inline" [innerHTML]="titleText"></div>
                <div>Error code: {{ errorCode }}</div>
            </span>
            <span class="subtitle-inline">
                <span class="para-inline" [ngClass]="eocFlag && isIntentionSelectionBanner || contactPreferenceFlag ? 'font-regular': ''" *ngIf="subtitleText !== ''" [innerHTML]="subtitleText"> </span>  
                <span *ngIf="inArrears"> 
                    <a class="link-text" (click)="navigateToHelpcenter()">{{arrearData.subTitleTextAtEnd}}</a>
                </span> 
            </span>
            <a class="help-reset-password" *ngIf="showBannerLink" href="#/forgot-password">{{bannerLinkText}}</a>
            <span *ngIf="showRegistrationLink">{{getPleaseText}}<a class="help-reset-password" *ngIf="showRegistrationLink" href="#/enter-email">{{getErrorTxt}}</a>.</span>
            <!-- <span test_id="eocLinkText" *ngIf="eocFlag"> <a class="eoc-options-link" href="#/eoc-options">{{eocLinkText}}</a>  </span> -->
            <span *ngIf="eocFlag && !(isIntentionSelectionBanner || contactPreferenceFlag)" (click)="setBalloonRefinanceFlag()" class="mb-p-0" id="wss-banner-eoc-options">
                <div>
                    <div class="container-row"> 
                        <span class="material-icons-outlined eoc-banner-right-arrow">
                            arrow_right
                        </span>
                        <bb-content-html [html]="eocLinkText" id="wss-banner-eoc-options-link"></bb-content-html>
                    </div>
                    
                    <span *ngIf="isResumableBrApplication" id="wss-banner-br-resume-application">
                        <div class="container-row">
                            <span class="material-icons-outlined eoc-banner-right-arrow">
                                arrow_right
                            </span>
                            <strong><a class="br-resume-banner-link-text" [href]=resumeBrApplicationLink role="link"> 
                                <span id="br-resume-application-link" *ngIf="proposalStatus === null || proposalStatus === 'INCOMPLETE'">{{eocBrContinueJourneyText}}</span>
                                <span id="br-return-and-sign-link" *ngIf="proposalStatus === 'ACCEPTED' && isDecisionChanged === false">{{eocBrReturnAndSignText}}</span>  
                                <span id="br-review-your-application-link" *ngIf="proposalStatus === 'REFERRED' || isDecisionChanged === true ">{{reviewYourApplicationResultText}}</span>  
                            </a></strong>
                        </div>
                    </span>

                </div>
            </span> 

            <span *ngIf="isEocLandingPageBanner || isBrLandingPageBanner" id="wss-banner-br-resume-application">
                <div class="container-row">
                    <span>
                        <strong>{{continueApplicationText}}&nbsp; <a class="br-resume-banner-link-text" [href]=resumeBrApplicationLink role="link">
                            <span id="br-resume-application-link" *ngIf="proposalStatus === null || proposalStatus === 'INCOMPLETE'">{{resumeBrApplicationLinkText}}</span>
                            <span id="br-return-and-sign-link"  *ngIf="proposalStatus === 'ACCEPTED' && isDecisionChanged === false">{{eocBrReturnAndSignText}}</span>  
                            <span id="br-review-your-application-link" *ngIf="proposalStatus === 'REFERRED' || isDecisionChanged === true">{{reviewYourApplicationResultText}}</span>  
                        </a></strong>
                    </span>
                </div>
            </span>
            
            <div *ngIf="eocFlag && isIntentionSelectionBanner || contactPreferenceFlag" [ngClass]="{'arrow-right': contactPreferenceFlag}">
                <a class="link-text" (click)="navigateTo()" id={{eocOptionBannerLinkId}}> {{linkText}} </a>
            </div>
        </div>
    </div>
        <div class="container-row section3">
        <span class="material-icons clear-icon" (click)="closeBanner()">{{messageData?.closeIconClass}}</span>
    </div>
</div>

<ng-template #msgBannerModal let-modal>
    <div class="modal-body">
        <div class="container-row">
            <div class="container-column">
                <!-- <p>{{modalText}}</p> -->
                <bb-content-html [html]="modalText"></bb-content-html>
            </div>
            <span (click)="modal.dismiss()" class="material-icons">clear</span>
        </div>
    </div>
</ng-template>