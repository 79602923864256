import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { BalloonRefinanceGetdetailsService } from '@wss/balloon-refinance-getdetails-widget/src/services/balloon-refinance-getdetails.service';
import { BalloonRefinanceGetdetailsItem, ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable, Subject } from 'rxjs';
import { appConfig, curmMemoText } from '@wss/config/wss-app-constants';
import { takeUntil } from 'rxjs/operators';
import { BannerMessage } from '@wss/model/bannerMessage';
import { MessageService } from '@wss/service/message.service';
import { getPortalName, injectComplaintNumber, isErrServer } from '@wss/common/util/util';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { EndOfContractService } from '@wss/end-of-contract-widget/src/services/end-of-contract.service';
import * as moment from 'moment';

@Component({
  selector: 'bb-brg-accept',
  templateUrl: './brg-accept.component.html'
})
export class BrgAcceptComponent implements OnInit {
  brgAcceptItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');
  readonly destroy$ = new Subject();
  isPCERead: boolean = false;
  isPCCIRead: boolean = false;
  isModagRead: boolean = false;
  showCheckboxError: boolean = false;
  agreementConfirmation: any;
  lastDateOfDecision: any;
  commonNavTestID: string = 'wss-brg-accept';
  accountDetails: any;
  hasAllAccordionsChecked: any = false;
  isAgreementPage: any = true;
  isPCEOpen: boolean = false;
  isPCCIOpen: boolean = false;
  isModagOpen: boolean = false;
  decisionData: any;
  bannerMessage: BannerMessage = new BannerMessage();
  isAcceptBanner: boolean = true;
  isBrReferralToAcceptBannerClosed: any;
  proposalDetails: ProposalRequest;
  serviceError: boolean = false;
  customerDetails: any;
  nameTitle: string = '';
  fullName: string = '';
  postCode: string = '';
  modagResponse: any = {};
  proposedTerm: any;
  rentalScheme: any;
  modagData: any = {
    brand: 'blackhorse',
    duration: 0,
    agreementAPR: '0.00',
    creditAmt: '0.00',
    totalPayableAmt: '0.00',
    interestRate: '0.00',
    totalCharges: 0,
    hireCharges: 0,
    creditFee: 0,
    optionFee: 0,
    terminateAmt: 0,
  }
  classLiveAddress: string = 'col-md-4 p-0';
  modagImportantInformationContent: any;
  isWithin14To6DaysOfExpiryDate: any;
  lastDateToSignAgreement: any;
  isMotorbike: boolean = false;
  isWithin6DaysOfBrDate: any;
  currentAddress: any;

  constructor(
    private datastore: DatastoreService,
    private readonly bbContentService: ContentService,
    private router: Router,
    private balloonRefinanceSrv: BalloonRefinanceService,
    private balloonRefinanceGDSrv: BalloonRefinanceGetdetailsService,
    private tealiumService: TealiumUtagService,
    private messageService: MessageService,
    private changeDetector: ChangeDetectorRef,
    private changeDetectorRef: ChangeDetectorRef,
    private eocService: EndOfContractService,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig
  ) { 
      this.proposalDetails = this.datastore.getProposalRequest();
      this.isWithin14To6DaysOfExpiryDate = this.balloonRefinanceGDSrv.isWithin14To6DaysOfExpiryDate();
  }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Accept',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.accountDetails = this.datastore.getAccountDetails();
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
    this.datastore.setBrHideStepper(true);
    this.accountDetails = this.datastore.getAccountDetails();
    this.customerDetails = this.datastore.getCustomerDetail();
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
    this.lastDateToSignAgreement = this.datastore.getLastDateForSigningAgreement(this.accountDetails?.final_payment_date);
    this.isWithin6DaysOfBrDate = moment().isAfter(this.lastDateToSignAgreement);
    this.decisionData = this.datastore.getDecisionData();
    this.getPersonalDetails();
    this.caculateModagQuote();
    this.modagData.brand = appConfig.brandDetails.find(arr => arr.brandName === getPortalName());
    this.injectComplaintTelephoneNumber();
    this.changeDetectorRef.detectChanges();
    this.checkIfGdsCodeIsMotorbike();
    this.getCurrentAddress();
  }

  caculateModagQuote() {
    this.balloonRefinanceGDSrv.caculateModagQuote(null, this.proposalDetails?.proposed_term).subscribe((res: any) => {
      if(res) {
        this.modagResponse = res;
        this.datastore.setCalculatedModagDetails(this.modagResponse);
        this.modagData.agreementAPR = this.modagResponse?.modag_quote_response?.modag_quotes[0]?.APR;
        this.modagData.interestRate = this.modagResponse?.modag_quote_response?.modag_quotes[0]?.interest_rate;        
        this.modagData.creditAmt = parseFloat(this.modagResponse?.modag_quote_response?.outstanding_Balance) + parseFloat(this.modagResponse?.modag_quote_response?.charges_Rebate);
        this.modagData.hireCharges = this.modagResponse?.modag_quote_response?.new_Charges;
        this.modagData.creditFee = this.modagResponse?.modag_quote_response?.credit_Fac_Fee;
        this.modagData.optionFee = this.modagResponse?.modag_quote_response?.final_Fee
        this.modagData.totalPayableAmt = this.modagResponse?.modag_quote_response?.modag_quotes[0]?.total_Payable;
        this.modagData.terminateAmt = this.modagResponse?.modag_quote_response?.half_the_total_amount_payable;
        this.rentalScheme = this.modagResponse?.modag_quote_response?.modag_quotes[0]?.new_rental_sets;
        this.modagData.totalCharges = parseFloat(this.modagData.hireCharges) + parseFloat(this.modagData.creditFee) + parseFloat(this.modagData.optionFee);
        this.rentalScheme.forEach((element: any) => {
          this.modagData.duration += parseInt(element.new_number_of_rentals)
        });
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  getPersonalDetails() {
    this.nameTitle = this.customerDetails?.individual.title;
    this.fullName = this.customerDetails?.individual.first_name + ' ' + this.customerDetails?.individual.surname;
    if((this.customerDetails?.addresses.address_detail.post_code !== ' ') && (this.customerDetails?.addresses.address_detail.post_code !== null)) {
      this.postCode = this.customerDetails?.addresses.address_detail.post_code?.replace(' ','');
    }
  }

  postCustomersCommentsSigned() {
    const today = new Date().toLocaleDateString()
    const payload = {
      customer_id: this.datastore.getCustomerId(),
      comments: [
        { Remark : this.accountDetails.account_number + " " + curmMemoText.webservBR },
        { Remark : curmMemoText.modagSigned },
        { Remark : curmMemoText.date + today }
      ]
    }
    this.balloonRefinanceSrv.postCustomerComments(payload).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.router.navigate(['/balloon-refinance-getdetails/mod-ag-next-step']);
    });
  }

  onCheckboxClick(e: any){
    e.preventDefault();
    if(this.hasAllAccordionsChecked) {
      this.toggleAgreementConfirmation();
      this.showCheckboxError = false;
    } else {
      this.showCheckboxError = true;
    }
  }

  toggleAgreementConfirmation() {
    this.agreementConfirmation = !this.agreementConfirmation;
  }

  acceptPCE() {
    this.isPCERead = true;
    this.isPCEOpen = false;
    this.checkAccordionsChecked();
  }

  acceptPCCI() {
    this.isPCCIRead = true;
    this.isPCCIOpen = false;
    this.checkAccordionsChecked();
  }

  acceptModag() {
    this.isModagRead = true;
    this.isModagOpen = false;
    this.checkAccordionsChecked();
  }

  checkAccordionsChecked() {
    if(this.isPCERead && this.isPCCIRead && this.isModagRead) {
      this.hasAllAccordionsChecked = true;
      this.showCheckboxError = false;
    }
  }

  onContinue() {
    if (this.isWithin6DaysOfBrDate) {
      this.router.navigate(['/balloon-refinance/cta-expired-eoc-period']);
    } else if (this.agreementConfirmation) {
      this.showCheckboxError = false;
      this.postModagDetails();
      this.eocService.saveExtendVehicleOption().subscribe(() => {
        this.postCustomersCommentsSigned();
      })
    } else {
      this.showCheckboxError = true;
    }
  }

  onSaveButtonClick(e: any) {
    if (this.isAgreementPage && this.isWithin14To6DaysOfExpiryDate) {
      this.saveProposalData();
    } else {
      this.router.navigate(['myaccount']);
    }
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.datastore.setErrorServer();
      this.serviceError = true;
      this.changeDetector.detectChanges();
    }
  }

  injectComplaintTelephoneNumber() {
    this.brgAcceptItem$.subscribe((res: any) => {
      this.modagImportantInformationContent = injectComplaintNumber(res.balloonRefinanceGetdetailsAccept.importantInformationText, res.brandContactDetails);
    })
  }

  saveProposalData() {
    this.proposalDetails.IsRejectedByCustomer = true;
    this.balloonRefinanceSrv.postProposalDetails(this.proposalDetails).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.proposalDetails = this.datastore.getProposalRequest();
      this.router.navigate(['/eoc-options/landing']);
    },
      (error: any) => {
        this.showError(error.status);
    });
  }

  checkIfGdsCodeIsMotorbike(){
    let gdsCode = this.accountDetails?.goods_code;
    if(appConfig.motorbikeGdsCodes.includes(gdsCode)){
      this.isMotorbike = true;
    }
  }

  postModagDetails() {   
    let brQuoteRequest: any = {
      'modag_Type': 'B',
      'requested_Term': this.modagData.duration,
      'email_Address': this.datastore.getLoggedInEmail(),
      'is_Email_Accepted': true,
      'is_No_Circ_Changes': true      
    };
    this.balloonRefinanceGDSrv.postModagDetails(brQuoteRequest).subscribe();
  }

  linkHandler(addressURL: string, type: string, phone?: boolean) {
    if(phone) {
      window.open('tel:' + addressURL, type);
    } else {
      window.open(addressURL, type);
    }    
  }

  getCurrentAddress() {
    this.balloonRefinanceSrv.getAdditionalAddressDetails().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.serviceError = false;
      this.currentAddress = res[0]?.address_detail;
    }, (error: any) => {
      this.showError(error.status);
    })
  }
}
