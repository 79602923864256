<div class="container-column" *ngIf="headerContentWidgetItem$ | async as headerWidgetItem">
  <div class="pb-40px">
    <div class="user-contact-details-wrapper">
      <div class="title-header">
        <span class="section-label-title" test_id="update_address_label">
          {{headerWidgetItem?.userAddress?.pageTitle}}
        </span>
        <hr />
      </div>
      <div class="update-address-wrapper">
        <div class="address-warning-content" *ngIf="showAddress">
          <div class="container-column">
            <div class="container-row mt-2">
              <div class="d-flex">
                <span class="location-icon location-icon-style"></span>
              </div>
              <div class="pull-item-right" data-cs-mask>
                <span
                  *ngIf="(address?.flat_number != null && address?.flat_number &&  address?.flat_number?.length > 0 && address?.flat_number != ' ')">
                  {{address.flat_number}},</span>
                <span class="caps-title"
                  *ngIf="(address?.house_name != null && address.house_name &&  address?.house_name.length > 0 && address?.house_name != ' ')">
                  {{address.house_name}},</span>
                <span class="caps-title"
                  *ngIf="(address?.street_name != null && address?.street_name &&  address?.street_name?.length > 0 && address?.street_name != '  ')">
                  {{address.street_name}},</span>&nbsp;
                <span class="caps-title"
                  *ngIf="(address?.county != null && address?.county &&  address?.county?.length > 0 && address?.county != '  ')">
                  {{address.county}},
                </span>
                <span *ngIf="address?.post_code?.length > 0">{{address.post_code}}</span>
              </div>
            </div>
            <div class="container-row mt-3">
              <span class="wss-additonal-info-icon additonal-info-icon-style"></span>
              <span class="para-inline contact-number-text pull-item-right note mb-10px">
                <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfo"></bb-content-html>
                <span *ngIf="headerWidgetItem.userAddress.addressPageWarningInfoPartTwo">
                  <bb-content-html [html]="headerWidgetItem.brandContactDetails.portalName"></bb-content-html>
                  <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfoPartTwo"></bb-content-html>
                </span>
              </span>
            </div>
          </div>
          <!-- <div class="col location-icon location-icon-style">
            <p class="mgn-left-15px caps-title">
              <span
                *ngIf="(address?.flat_number != null && address?.flat_number &&  address?.flat_number?.length > 0 && address?.flat_number != ' ')">
                {{address.flat_number}},</span>
              <span
                *ngIf="(address?.house_name != null && address.house_name &&  address?.house_name.length > 0 && address?.house_name != ' ')">
                {{address.house_name}},</span>
              <span
                *ngIf="(address?.street_name != null && address?.street_name &&  address?.street_name?.length > 0 && address?.street_name != '  ')">
                {{address.street_name}},</span>&nbsp;
              <span
                *ngIf="(address?.county != null && address?.county &&  address?.county?.length > 0 && address?.county != '  ')">
                {{address.county}},
              </span>
              <span *ngIf="address?.post_code?.length > 0">{{address.post_code}}</span>
            </p>
          </div> -->
        </div>

        <!-- <div class="row address-warning-content" *ngIf="showAddress">
          <div class="col wss-additonal-info-icon address-warnig-icon-style">
            <span class="para-inline contact-number-text pull-item-right note">
              <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfo"></bb-content-html>
              <bb-content-html [html]="headerWidgetItem.brandContactDetails.portalName"></bb-content-html>
              <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfoPartTwo"></bb-content-html>
            </span>
          </div>
        </div> -->
        <form [formGroup]="locationForm" class="update-address-form">
          <div *ngIf="showAddress">
            <div class="form-group input-field post-code-style">
              <label for="postcode" test_id="ua_postcode">
                {{headerWidgetItem?.userAddress?.postCodeLabel}}
              </label>
              <input type="text" class="form-control" name="postcode" formControlName="post_code"
                aria-describedby="postCode" required="true" placeholder="E.g. CF23 8GF" accesskey="p" data-cs-mask>
              <div class="error-text"
                *ngIf="!locationForm.controls.post_code?.valid && locationForm.controls.post_code?.dirty">
                <bb-content-html [html]="headerWidgetItem.userAddress.postcodeInputErrorMsg"></bb-content-html>
              </div>
            </div>
            <div class="form-group input-field">
              <label for="homenumber" test_id="ua_home">
                {{headerWidgetItem?.userAddress?.homeLabel}}
              </label>
              <input type="text" class="form-control" formControlName="house_name" name="homenumber" required="true"
                placeholder="" maxlength="35" minlength="1" aria-describedby="homeNumber" data-cs-mask>
              <div class="error-text"
                *ngIf="!locationForm.controls.house_name?.valid && locationForm.controls.house_name?.dirty">
                <bb-content-html [html]="headerWidgetItem.userAddress.homeInputErrorMsg"></bb-content-html>
              </div>
            </div>
            <div class="links-container">
              <div class="d-flex justify-content-left contact-details-form-btn">
                <button id="selectThisAddressButton" type="button" class="wss-button wss-button-update-form" (click)="onAddrsSubmit()"
                  test_id="ua_chooseaddress" title="{{headerWidgetItem?.userAddress?.selecteThisAddressButtonLabel}}"
                  [disabled]="!(locationForm.controls.house_name?.valid && locationForm.controls.post_code.valid)">
                  {{headerWidgetItem?.userAddress?.selecteThisAddressButtonLabel}}
                </button>
              </div>
            </div>
            
          </div>
          <div *ngIf="showFormDate">
            <div class="address-warning-content">
              <div class="container-row mt-2">
                <div class="d-flex">
                  <span class="location-icon location-icon-style"></span>
                </div>
                <div class="pull-item-right" data-cs-mask>
                  <span
                    *ngIf="(prePopAddres?.flat_number != null && prePopAddres?.flat_number &&  prePopAddres?.flat_number?.length > 0 && prePopAddres?.flat_number != ' ')">
                    {{prePopAddres.flat_number}},</span>
                  <span class="caps-title"
                    *ngIf="(prePopAddres?.house_name != null && prePopAddres.house_name &&  prePopAddres?.house_name.length > 0 && prePopAddres?.house_name != ' ')">
                    {{prePopAddres.house_name}},</span>
                  <span class="caps-title"
                    *ngIf="(prePopAddres?.street_name != null && prePopAddres?.street_name &&  prePopAddres?.street_name?.length > 0 && prePopAddres?.street_name != '  ')">
                    {{prePopAddres.street_name}},</span>&nbsp;
                  <span class="caps-title"
                    *ngIf="(prePopAddres?.county != null && prePopAddres?.county &&  prePopAddres?.county?.length > 0 && prePopAddres?.county != '  ')">
                    {{prePopAddres.county}},
                  </span>
                  <span *ngIf="prePopAddres?.post_code?.length > 0">{{prePopAddres.post_code}}</span>
                </div>
              </div>
              <!-- <div class="col location-icon location-icon-style">
                <p class="mgn-left-15px caps-title">
                  <span
                    *ngIf="(prePopAddres?.flat_number != null && prePopAddres?.flat_number &&  prePopAddres?.flat_number?.length > 0 && prePopAddres?.flat_number != ' ')">
                    {{prePopAddres.flat_number}},</span>
                  <span
                    *ngIf="(prePopAddres?.house_name != null && prePopAddres.house_name &&  prePopAddres?.house_name.length > 0 && prePopAddres?.house_name != ' ')">
                    {{prePopAddres.house_name}},</span>
                  <span
                    *ngIf="(prePopAddres?.street_name != null && prePopAddres?.street_name &&  prePopAddres?.street_name?.length > 0 && prePopAddres?.street_name != '  ')">
                    {{prePopAddres.street_name}},</span>&nbsp;
                  <span
                    *ngIf="(prePopAddres?.county != null && prePopAddres?.county &&  prePopAddres?.county?.length > 0 && prePopAddres?.county != '  ')">
                    {{prePopAddres.county}},
                  </span>
                  <span *ngIf="prePopAddres?.post_code?.length > 0">{{prePopAddres.post_code}}</span>
                </p>
              </div> -->
            </div>
            <div class="form-group input-field address-calendar">
              <label for="homenumber" test_id="ua_calendar">
                {{headerWidgetItem?.userAddress?.calendarLabel}}
              </label>
              <div class="input-group">
                <input class="form-control" placeholder="DD/MM/YYYY" name="dp" formControlName="moving_in_date"
                  maxlength="10" [displayMonths]="1" [navigation]="'arrows'" [outsideDays]="'hidden'"
                  [ngClass]="{'is-invalid':(!locationForm.controls.moving_in_date?.valid || !f) && locationForm.controls.moving_in_date?.dirty}"
                  [showWeekNumbers]="false" [autoClose]="false" ngbDatepicker [footerTemplate]="footerTemplate"
                  #d="ngbDatepicker" (input)="dateFormat(locationForm.value.moving_in_date)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"
                    [ngClass]="{'is-invalid':(!locationForm.controls.moving_in_date?.valid || !f) && locationForm.controls.moving_in_date?.dirty}">
                    <span class="calendar_today"></span>
                  </button>
                </div>
              </div>
              <div class="error-text"
                *ngIf="(!locationForm.controls.moving_in_date?.valid || !f) && locationForm.controls.moving_in_date?.dirty">
                <bb-content-html [html]="headerWidgetItem.userAddress.calendarErrorMsg"></bb-content-html>
              </div>
            </div>
            <ng-template #footerTemplate>
              <hr class="my-0">
              <button class="btn btn-primary btn-sm m-2 float-right" (click)="handleDateSelect($event,d)"
                [disabled]="!locationForm.controls.moving_in_date?.valid">Select</button>
              <a class="button-text text-underline mt-2 float-right text-button" tabindex="0" title="Cancel"
                (click)="cancel(d)">Cancel</a>
            </ng-template>
          </div>
          <div class="container-row mt-3" *ngIf="showFormDate">
            <span class="wss-additonal-info-icon additonal-info-icon-style"></span>
            <span class="para-inline contact-number-text pull-item-right note">
              <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfo"></bb-content-html>
              <span *ngIf="headerWidgetItem.userAddress.addressPageWarningInfoPartTwo">
                <bb-content-html [html]="headerWidgetItem.brandContactDetails.portalName"></bb-content-html>
                <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfoPartTwo"></bb-content-html>
              </span>
            </span>
          </div>
          <div *ngIf="showFormDate" class="links-container">
            <!-- <div class="row address-warning-content-calendar">
              <div class="col wss-additonal-info-icon address-warnig-icon-style">
                <span class="para-inline contact-number-text pull-item-right note" test_id="ua_pagewarning">
                  <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfo"></bb-content-html>
                  <bb-content-html [html]="headerWidgetItem.brandContactDetails.portalName"></bb-content-html>
                  <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfoPartTwo"></bb-content-html>
                </span>
              </div>
            </div> -->
            <!-- <div class="container-row mt-3">
              <span class="wss-additonal-info-icon"></span>
              <span class="para-inline contact-number-text pull-item-right note" class="pull-item-right note">
                <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfo"></bb-content-html>
                <bb-content-html [html]="headerWidgetItem.brandContactDetails.portalName"></bb-content-html>
                <bb-content-html [html]="headerWidgetItem.userAddress.addressPageWarningInfoPartTwo"></bb-content-html>
              </span>
            </div> -->

            <div class="d-flex justify-content-left contact-details-form-btn">
              <button type="button" class="wss-button update-contact-btn wss-button-update-form" (click)="onSubmit()"
                [disabled]="((locationForm.invalid && !calendarSelect) || !f)"
                title="{{headerWidgetItem?.userAddress?.calendarLabel}}" test_id="ua_confirmaddress">
                {{headerWidgetItem?.userAddress?.confirmThisAddressButtonLabel}}
              </button>
            </div>
            <div class="d-flex justify-content-center choose-diff-title mb-10px">
              <a class="text-button" (click)="refreshPage()" test_id="ua_chooseDiffrent">
                {{headerWidgetItem?.userAddress?.chooseDiffrentLabel}}
              </a>
            </div>
            <div class="d-flex justify-content-center help-blink" >
              <bb-content-html [html]="headerWidgetItem.userAddress.needHelpLink"></bb-content-html>
            </div>
          </div>

          <div *ngIf="showAddress" class="links-container">
            <div class="d-flex justify-content-center help-blink" >
              <bb-content-html [html]="headerWidgetItem.userAddress.needHelpLink"></bb-content-html>
            </div>
          </div>
        
        </form>
        <!-- *ngIf="successStatus"  -->
        <div class="change-password-success-container result-container" *ngIf="successStatus" >
          <div class="request-result-section">
            <div class="trophy-icon-div">
              <span class="success_icon success-icon-style" title="icon-success"></span>
            </div>
            <div class="d-flex justify-content-center result-info-text para-inline">
              <bb-content-html [html]="headerWidgetItem.userAddress.commonSuccess"></bb-content-html>
                </div>
        
            <div class="d-flex justify-content-center request-result-text" *ngIf="!selectedFuture">
              <!-- <b> -->

                <bb-content-html [html]="headerWidgetItem.userAddress.successMessageOnCurrentDate"></bb-content-html>
              <!-- </b> -->
            </div>
            <div class="d-block justify-content-center result-info-text para-inline" *ngIf="selectedFuture">
              {{headerWidgetItem?.userAddress?.successMessageOnFutureDay}}
              {{futureDate}}.
              <bb-content-html [html]="headerWidgetItem.userAddress.successMessageOnFutureDayMsg"></bb-content-html>
            </div>

            <div class="d-flex justify-content-center">
              <button type="button" class="wss-button wss-button-update success-back-button"
                (click)="backToMyAccountPage()" title="{{headerWidgetItem?.userAddress?.backToHomeButtonLabel}}"
                test_id="ua_backtoHome">
                {{headerWidgetItem?.userAddress?.backToHomeButtonLabel}}
              </button>
            </div>
          </div>
        </div>
        <!-- *ngIf="errorStatus"  -->
        <div class="change-password-success-container  result-container result-content-error" *ngIf="errorStatus" >
          <div class="request-result-section">
            <div class="trophy-icon-div">
              <span class="not-found-icon not-found-icon-style" alt="Not Found"></span>
            </div>
            <div class="d-flex justify-content-center request-result-text">
              <bb-content-html [html]="headerWidgetItem.userAddress.notFoundWarnning"></bb-content-html>
            </div>
            <div class="d-flex justify-content-center result-info-text">
              <p (click)="refreshPage()">
                <bb-content-html [html]="headerWidgetItem.userAddress.pageWarningWithLink"></bb-content-html>
              </p>
            </div>
            <div class="d-flex justify-content-center ">
              <button type="button" class="wss-button wss-button-update success-back-button"
                (click)="backToMyAccountPage()" title="{{headerWidgetItem?.userAddress?.backToHomeButtonLabel}}"
                test_id="ua_backtoHomelabel">
                {{headerWidgetItem?.userAddress?.backToHomeButtonLabel}}
              </button>
            </div>
          </div>
        </div>
        <div  *ngIf="successStatus">
          <div class="d-flex justify-content-center help-blink">
            <bb-content-html [html]="headerWidgetItem.userAddress.needHelpLink"></bb-content-html>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>