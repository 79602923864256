<div class="get-in-touch-page" *ngIf="helpCentreWidgetItem">
	<div class="container-column">
		<div class="help-centre-title">
			<span class="section-label-title" test_id="pageName">
				{{helpCentreWidgetItem.getInTouchPage.pageName}}</span>
		</div>
		<div class="customer-support-text margin-top-30">
			<div class="get-in-touch-header" test_id="pageHeaderBelowText">
				<bb-content-html [html]="helpCentreWidgetItem.getInTouchPage.pageHeaderBelowText"></bb-content-html>
			</div>

			<!-- New Complaints page starts here-->
			<div class="mt-15px">
				<mat-tab-group animationDuration="0ms">
					<mat-tab label='{{ helpCentreWidgetItem.getInTouchPage.queriesLabel }}'>
						<div class="mtb-12px">
							<div class="get-in-touch-menu mrl-12px">
								<div class='pl-5px'>
									<mat-menu>
										<button id="queriesLabel" mat-menu-item>{{
											helpCentreWidgetItem.getInTouchPage.queriesLabel }}</button>
										<button id="complaintsLabel" mat-menu-item>{{
											helpCentreWidgetItem.getInTouchPage.complaintsLabel }}</button>
									</mat-menu>
								</div>
							</div>
						</div>
						<div class="faq-contents" test_id="frequentlyAskedQuestionsText">
							<bb-content-html
								[html]="helpCentreWidgetItem.getInTouchPage.frequentlyAskedQuestionsText"></bb-content-html>
						</div>
						<div class="mt-30px">
							<span test_id="talkToUsText">
								<bb-content-html
									[html]="helpCentreWidgetItem.getInTouchPage.talkToUsText"></bb-content-html>
							</span>
							<div class="call-us-number mb-15px">
								<span class="call-us-text para-inline" test_id="callUsText">
									<bb-content-html
										[html]="helpCentreWidgetItem.getInTouchPage.callUsText"></bb-content-html>
								</span>
							</div>
						</div>
						<div class="call-us-details" test_id="customerContactUsDetails">
							<bb-content-html
								[html]="helpCentreWidgetItem.getInTouchPage.customerContactUsDetails"></bb-content-html>
						</div>

						<div class="write-to-us-details margin-top-30">
							<div test_id="thirdHeaderTitle">
								<bb-content-html
									[html]="helpCentreWidgetItem.getInTouchPage.thirdHeaderTitle"></bb-content-html>
							</div>

							<span class="span-icon mail-icon"
								*ngIf="helpCentreWidgetItem.brandContactDetails.brandWiseAddress">
								<span class="wss-mail-help-icon"></span>
							</span>
							<span test_id="writeToUsAddress" class="write-to-us">
								<bb-content-html
									[html]="helpCentreWidgetItem.brandContactDetails.brandWiseAddress"></bb-content-html>
							</span>

							<div test_id="includeTelephoneNumberText">
								<bb-content-html
									[html]="helpCentreWidgetItem.getInTouchPage.includeTelephoneNumberText"></bb-content-html>
							</div>
						</div>
					</mat-tab>
					<mat-tab label='{{ helpCentreWidgetItem.getInTouchPage.complaintsLabel }}'>
						<div>
							<div class="info-alert motor-commission-complaints">
								<div class="alert alert-info" role="alert">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
											viewBox="0 0 24 24">
											<path fill="#333333" [attr.d]="svgInfoIcon" />
										</svg>
									</span>
									<div test_id="motorCommissionComplaint">
										<div class="header">
											<bb-content-html
												[html]="helpCentreWidgetItem.helpCentreComplaints.motorCommissionComplaintsHeader"></bb-content-html>
										</div>
										<div class="motor-commission-complaint-contact">
											<bb-content-html
												[html]="helpCentreWidgetItem.motorCommissionComplaintsContactDetails"></bb-content-html>
										</div>
										<div class="show-more-text mb-10px">
											<span class="show-more">
												<a (click)="openInfo()">{{helpCentreWidgetItem.helpCentreComplaints.showMoreLabel}}
													<span *ngIf="showMoreArrowDown"
														class="material-icons material-icons-color-arrear">keyboard_arrow_up</span>
													<span *ngIf="!showMoreArrowDown"
														class="material-icons material-icons-color-arrear">keyboard_arrow_down</span>
												</a>
											</span>
											<div
												[ngClass]="showMoreArrowDown ? 'show-more-info show-info' : 'show-more-info'">
												<span>
													<bb-content-html
														[html]="helpCentreWidgetItem.helpCentreComplaints.motorCommissionComplaintsShowMoreText">
													</bb-content-html>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="complaints-generic-header margin-top-20" test_id="complaintsGenericHeaderText">
								<bb-content-html
									[html]="helpCentreWidgetItem.helpCentreComplaints.complaintsGenericHeaderText"></bb-content-html>
							</div>
							<div class="complaints-header"
								*ngIf="!accountDetail.in_arrears && isComplaintFeatureEnabled">
								<div class="title-header margin-top-10" test_id="complaintsHeaderTitle">
									<div class="complaints-header-text">
										<bb-content-html
											[html]="helpCentreWidgetItem.helpCentreComplaints.complaintsHeaderText"></bb-content-html>
									</div>
								</div>
								<div class="content-center margin-bottom">
									<button id="makeAComplaintButton" class="wss-button button-width-padding"
										test_id="makeAComplaintButtonText" (click)="onMakeComplaintClick()">
										{{helpCentreWidgetItem.helpCentreComplaints.makeAComplaintButton}}
									</button>
								</div>
								<div class="margin-top-20">
									<div class="title-header" test_id="firstHeaderTitle">
										<h3><strong>
												<bb-content-html
													[html]="helpCentreWidgetItem.helpCentreComplaints.firstHeaderTitle"></bb-content-html></strong>
										</h3>
									</div>
								</div>
							</div>

							<div class="margin-top-30">
								<div class="help-centre-support container-row margin-top-30">
									<!-- Dynamic accordion -->
									<bb-common-expansion-panel
										[accordionContent]="helpCentreWidgetItem.helpCentreComplaints.complaintsAccordionContent"
										[testIDText]="'helpCentreComplaintsTabAccordion'"></bb-common-expansion-panel>
								</div>
							</div>
							<div test_id="thirdContent" class="mt-40px">
								<bb-content-html
									[html]="helpCentreWidgetItem.helpCentreComplaints.thirdContent"></bb-content-html>
							</div>
							<div test_id="finalContent" class="mt-40px">
								<bb-content-html
									[html]="helpCentreWidgetItem.helpCentreComplaints.finalContent"></bb-content-html>
							</div>
							<div class="our-services-wrapper">
								<div class="our-services-text mt-20px">
									<bb-info-alert
										[infoContent]="helpCentreWidgetItem.helpCentreComplaints.secondContent"></bb-info-alert>
								</div>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
			<!-- New Complaints page ends here-->
		</div>
	</div>
</div>