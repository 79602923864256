import { Injectable } from '@angular/core';
import { HttpService } from '@wss/service/http.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DatastoreService } from '@wss/service/datastore.service';
import { MessageService } from '@wss/service/message.service';
import * as moment from 'moment';
import { curmMemoText, proposalStatus } from '@wss/config/wss-app-constants';

@Injectable({
  providedIn: 'root'
})
export class BalloonRefinanceGetdetailsService {

  constructor(private httpService: HttpService,
    private datatstore: DatastoreService,
    private msgService: MessageService) { }

  quoteData: any = {
    refinanceAmount: 5000,
    totalAmount: 5295,
    apr: "5.9%",
    monthlyPayment: 0,
    noOfInstallments: 0
  }

  setQuoteData(data: any) {
    this.quoteData = data;
  }

  getQuoteData() {
    return this.quoteData;
  }

  getUserDetails(): Observable<any> {
    return this.httpService.getUserDetails()
    .pipe(
      map((res: any) => {
        return res
      },
      catchError((err: any) => {
        return err
      })
      )
    )
  }

  getVehicleById(id: string): Observable<any> {
    return this.httpService.getVehicleById(id)
    .pipe(
      map((res:any) => {
        return res
      },
      catchError((err: any) => {
        return err
      }))
    )
  }

  getAddress(data: any): Observable<any> {
    return this.httpService.addressSearch(data);
  }

  postAdditionalAddressDetails(updateAddress: any) {
    return this.httpService.postAdditionalAddress(updateAddress)
    .pipe(
      map((res: any) => {
        this.datatstore.setAddressDetails(res);
        return res;
      },
      catchError((error: any) => {
        this.msgService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  }

  getAdditionalAddressDetails() {
    return this.httpService.getAdditionalAddress()
    .pipe(
      map((res: any) => {
        return res;
      },
      catchError((error: any) => {
        this.msgService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  } 

  caculateModagQuote(requestedRental: any, requestedTerm: any): Observable<any>{
    return this.httpService.caculateModagQuote(requestedRental, requestedTerm).
      pipe(map(data => {
      return data;
      }), catchError(error => {
        this.msgService.setServiceError(error.error && error.error.length? error.error[0]: error.error);
        return error;
      }));
  }

  getModagQuote(): Observable<any>{
    return this.httpService.getExistingModags().
      pipe(map(data => {
      return data;
      }), catchError(error => {
        this.msgService.setServiceError(error.error && error.error.length? error.error[0]: error.error);
        return error;
      }));
  }

  postModagDetails(data: any): Observable<any>{
    return this.httpService.submitModagQuote(data).
    pipe(map(data => {
      return data;
    }), catchError(error => {
        this.msgService.setServiceError(error.error && error.error.length? error.error[0]: error.error);
        return error;
    }));
  }

  isWithin14To6DaysOfExpiryDate(): boolean {
    let accountDetails = this.datatstore.getAccountDetails();
    let formattedFinalPaymentDate = this.datatstore.getFormattedDate(accountDetails.final_payment_date);
    let reformattedDate = new Date(formattedFinalPaymentDate);
    let finalPaymentDate = moment(reformattedDate, "DD/MM/YYYY");
    let differenceInDays = finalPaymentDate.diff(moment(), 'days');
    return differenceInDays > 6 && differenceInDays < 14 ? true : false;
  }

  createCustomerComments(accountDetails: any, creditCheckData: any) {
    const payload = {
      comments: [
        { Remark: accountDetails.account_number + " " + curmMemoText.webservBR },
        { Remark: curmMemoText.proposalNo + creditCheckData?.proposal?.decision?.proposal_number },
        { Remark: curmMemoText.created + creditCheckData?.proposal?.decision?.last_updated.split(" ")[0]}
      ]
    }
    return payload
  }

  curmCommentsBuilder(accountDetails: any, decisionData: any ): any {
    let payload = { 
      customer_id: this.datatstore.getCustomerId(),
      comments: [
        { Remark : accountDetails.account_number + " " + curmMemoText.webservBR },
        { Remark : curmMemoText.date + decisionData?.last_updated }
      ]
    }
    let curmMemoDecisionText;
    if (decisionData?.decision_status === proposalStatus.accepted) {
      curmMemoDecisionText = curmMemoText.lendingApproved;
    } else if (decisionData?.decision_status === proposalStatus.referred) {
      curmMemoDecisionText = curmMemoText.lendingReferred;
    } else if (decisionData?.decision_status === proposalStatus.declined) {
      curmMemoDecisionText = curmMemoText.lendingDeclined;
    } else {
      curmMemoDecisionText = ''
    }
    payload.comments.splice(1, 0, { Remark : curmMemoDecisionText });
    return payload;
  }
  
  getRouting(res: any): string {
    if(res.decision_status === proposalStatus.accepted) {
      return '/balloon-refinance-getdetails/accept';
    } else if(res.decision_status === proposalStatus.declined) {
      return '/balloon-refinance-getdetails/decline';
    } else if(res.decision_status === proposalStatus.referred) {
      return '/balloon-refinance-getdetails/referral';
    } else {
      return '/myaccount'
    }
  }

  updateProposalData(proposalData: any, creditCheckData: any, decisionData: any) {
    let updatedProposal = {...proposalData};
    updatedProposal.proposal_id = creditCheckData.proposal.proposal_id;
    updatedProposal.palisCustomerId = creditCheckData.palis_customer_id;
    updatedProposal.palis_proposal_number = creditCheckData?.proposal?.decision.proposal_number;
    updatedProposal.proposal_status = creditCheckData?.proposal?.decision?.decision_status === proposalStatus.incomplete ? proposalStatus.incomplete : decisionData?.decision_status;
    return updatedProposal;
  }

}
